import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Avatar, Box, CardActions, Checkbox, GlobalStyles, Grid, Paper, Stack, Typography, createTheme, styled } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import { truncateSync } from 'fs';
import type { ButtonProps, DialogProps } from "@mui/material";
import { blue } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';
import { ArrowBackIos, ArrowForward, ArrowForwardIosOutlined } from '@mui/icons-material';



const theme = createTheme({
  spacing: 4,
});


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AgreeDialog() {
  const [open, setOpen] = React.useState(true);
  const [inEnglish, setInEnglish] = React.useState(true);
  const [inSinhala, setInSinhala] = React.useState(false);
  const [inTamil, setInTamil] = React.useState(false);
  const [agree1, setAgree1] = React.useState(false);
  const [agree2, setAgree2] = React.useState(false);
  const [agree3, setAgree3] = React.useState(false);
  const [agree4, setAgree4] = React.useState(false);
  const [agree5, setAgree5] = React.useState(false);
  const [agree6, setAgree6] = React.useState(false);
  const [enableContinue, setEnableContinue] = React.useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    sessionStorage.setItem('agreed',"1");
    setOpen(false);
  };
  const handleCloseDisagree = () => {
    setOpen(false);
    navigate(`/`)
  };

  const BlueColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: '#2364be',
    '&:hover': {
      backgroundColor: '#2364be',
    },
    minWidth:300,
     
  }));
const handleSinhalaLanguageSelect=() =>{
   setInSinhala(true);
   setInEnglish(false);
   setInTamil(false);
}

const handleTamilLanguageSelect=() =>{
   setInSinhala(false);
   setInEnglish(false);
   setInTamil(true);
}
const handleEnglishLanguageSelect=() =>{
   setInSinhala(false);
   setInEnglish(true);
   setInTamil(false);
}


const handleagree1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
  setAgree1(event.target.checked);
  if(event.target.checked && agree2 && agree3 && agree4 && agree5 && agree6){
    setEnableContinue(true);
  }else{
    setEnableContinue(false);
  }
    
};
const handleagree2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
  setAgree2(event.target.checked);
  if(agree1 && event.target.checked && agree3 && agree4 && agree5 && agree6){
    setEnableContinue(true);
  }else{
    setEnableContinue(false);
  }
};
const handleagree3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
  setAgree3(event.target.checked);
  if(agree1 && agree2 && event.target.checked && agree4 && agree5 && agree6){
    setEnableContinue(true);
  }else{
    setEnableContinue(false);
  }
};
const handleagree4Change = (event: React.ChangeEvent<HTMLInputElement>) => {
  setAgree4(event.target.checked);
  if(agree1 && agree2 && agree3 && event.target.checked && agree5 && agree6){
    setEnableContinue(true);
  }else{
    setEnableContinue(false);
  }
};
const handleagree5Change = (event: React.ChangeEvent<HTMLInputElement>) => {
  setAgree5(event.target.checked);
  if(agree1 && agree2 && agree3 && agree4 && event.target.checked && agree6){
    setEnableContinue(true);
  }else{
    setEnableContinue(false);
  }
};
const handleagree6Change = (event: React.ChangeEvent<HTMLInputElement>) => {
  setAgree6(event.target.checked);
  if(agree1 && agree2 && agree3 && agree4 && agree5 && event.target.checked){
    setEnableContinue(true);
  }else{
    setEnableContinue(false);
  }
};

const handleDlgClose = (event:any, reason:any) => {
  if (reason && reason == "backdropClick") {
    
    return;
  }

  setOpen(false);
}

 

  return (

    <>
    
   
    
    <React.Fragment>

    

        <Dialog
         
          disableEscapeKeyDown
          onClose={handleDlgClose}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                height: "100%",
                minWidth: "90%",
                minHeight: "95%",
                maxWidth: "90%",  
              },
            },
            
          }}
        
        
          aria-describedby="alert-dialog-slide-description"
        >
   
          <DialogContent >
            <Grid container >
              
                 <Grid  item xs={12}  >   
                   <center>
                   <Typography
               
                      display="block"
                      align="center"
                      color="text.primary"
                      marginTop={"10px"}
                      marginBottom={"10px"}
             
                 sx={{fontSize: {lg: 20, md: 18,sm: 16,xs: 16},textDecoration: 'underline',fontWeight:"bold"}}
              >
                          Consent of the Applicant
                     </Typography>
                   </center>
                </Grid>
              
               
                 <Grid container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center"  
                    sx={{
                    width: "100vw",
                    overflowX: "hidden",
                    padding: { xs: "2px", md: "2px" },
                    marginBottom:1,
                    background: "#0e7ef5 ",
                    
                  }}  item xs={11}>           
                  <Typography  color="white" align="left" gutterBottom sx={{fontWeight:"bold",fontSize: {lg: 16, md: 14,sm: 12,xs: 12}}}>
                     Conditions
                  </Typography>

                </Grid>
                <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                 justifyContent="center"  
                 sx={{
                    width: "100vw",
                    overflowX: "hidden",
                    padding: { xs: "2px", md: "2px" },
                    marginBottom:1,
                    background: "#0e7ef5 ",
                  }} item xs={1}>
                  <Typography color="white" align="left" gutterBottom sx={{fontWeight:"bold",fontSize: {lg: 16, md: 14,sm: 12,xs: 12}}}>
                     Agreed 
                  </Typography>
                </Grid>
              

                <Grid  sx={{ display: 'flex',background: "hsl(0, 0%, 95%)" }} item xs={1}>
                    <Typography sx={{margintop: '0px',paddingtop: '0px',fontSize: {lg: 14, md: 12,sm: 10,xs: 10}}}     align="justify" paragraph>
                             1.
                    </Typography>                
                </Grid>

                <Grid    sx={{
                    width: "100vw",
                    overflowX: "hidden",
                    padding: { xs: "2px", md: "2px" },
                    margin: 0,
                    background: "hsl(0, 0%, 95%)",
                  }} item xs={10}>           
                   <Typography sx={{margin: 0,fontSize: {lg: 14, md: 12,sm: 10,xs: 10}}}     align="justify" paragraph>
                        අදාළ ගැසට් නිවේදනයේ 2.0 වගන්තියේ දක්වා ඇති මූලික සුදුසුකම් සම්පුර්ණ කර ඇත්තෙමි.
                    </Typography>
                    <Typography sx={{margin: 0,fontSize: {lg: 14, md: 12,sm: 10,xs: 10}}}     align="justify" paragraph>
                        வர்த்தமானி அறிவித்தலின் 2.0 பந்தியில் குறிப்பிடப்பட்டுள்ள அடிப்படை தகைமைகளை பூர்த்தி செய்துள்ளேன். 
                    </Typography>
                  <Typography sx={{margin: 0,fontSize: {lg: 14, md: 12,sm: 10,xs: 10}}}     align="justify" paragraph>
                         I have fulfilled the basic qualifications mentioned in Section 2.0 of the relevant Gazette Notification.
                  </Typography>
                </Grid>
                <Grid container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center"    sx={{ display: 'flex',background: "hsl(0, 0%, 95%)" }} item xs={1}>
                  <Checkbox sx={{margintop: '0px',paddingtop: '0px'}} checked={agree1}   onChange={handleagree1Change}  inputProps={{ 'aria-label': 'controlled' }} />
                </Grid>

                <Grid  sx={{ display: 'flex' }} item xs={1}>
                           <Typography sx={{margin: 0,fontSize: {lg: 14, md: 12,sm: 10,xs: 10}}}     align="justify" paragraph>
                             2.
                           </Typography>           
                </Grid>
                <Grid    sx={{
                    width: "100vw",
                    overflowX: "hidden",
                    padding: { xs: "2px", md: "2px" },
                    margin: 0,
                   
                  }} item xs={10}>           
                   <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}} align="justify" paragraph>
                       අදාළ ගැසට් නිවේදනයේ 3.0 වගන්තියේ දක්වා ඇති මූලික අධ්‍යාපන සුදුසුකම් සම්පුර්ණ කර ඇත්තෙමි.
                    </Typography>
                    <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                       வர்த்தமானி அறிவித்தலின் 3.0 பந்தியில் குறிப்பிடப்பட்டுள்ள அடிப்படைக் கல்வித் தகைமைகளை பூர்த்தி செய்துள்ளேன்.
                    </Typography>
                  <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                  I have fulfilled the basic educational qualifications mentioned in Section 3.0 of the relevant Gazette Notification.
                  </Typography>
                </Grid>
                <Grid container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center"    sx={{ display: 'flex' }} item xs={1}>
                  <Checkbox sx={{margintop: '0px',paddingtop: '0px'}} checked={agree2}   onChange={handleagree2Change}  inputProps={{ 'aria-label': 'controlled' }} />
                </Grid>



                <Grid  sx={{ display: 'flex',background: "hsl(0, 0%, 95%)" }} item xs={1}>
                     <Typography sx={{margin: 0,fontSize: {lg: 14, md: 12,sm: 10,xs: 10}}}     align="justify" paragraph>
                             3.
                       </Typography>            
                </Grid>
                <Grid    sx={{
                    width: "100vw",
                    overflowX: "hidden",
                    padding: { xs: "2px", md: "2px" },
                    margin: 0,
                    background: "hsl(0, 0%, 95%)",
                  }} item xs={10}>           
                   <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                     අදාළ ගැසට් නිවේදනයේ 6.2 වගන්තියේ දක්වා ඇති පරිදි ස්ථිර පදිංචිය තහවුරු කළ හැකිය.
                    </Typography>
                    <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                    வர்த்தமானி அறிவித்தலின் 6.2 பந்தியில் குறிப்பிடப்பட்டுள்ள நிரந்த வதிவிடத்தை உறுதிப்படுத்த முடியும்.
                    </Typography>
                  <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                  Permanent residence can be confirmed as specified in Section 6.2 of the relevant Gazette Notification.
                  </Typography>
                </Grid>
                <Grid container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center"   sx={{ display: 'flex',background: "hsl(0, 0%, 95%)", }} item xs={1}>
                  <Checkbox sx={{margintop: '0px',paddingtop: '0px'}} checked={agree3}   onChange={handleagree3Change}  inputProps={{ 'aria-label': 'controlled' }} />
                </Grid>

                <Grid  sx={{ display: 'flex' }} item xs={1}>
                      <Typography sx={{margin: 0,fontSize: {lg: 14, md: 12,sm: 10,xs: 10}}}     align="justify" paragraph>
                             4.
                       </Typography>                
                </Grid>
                <Grid    sx={{
                    width: "100vw",
                    overflowX: "hidden",
                    padding: { xs: "2px", md: "2px" },
                    margin: 0,
                 
                  }} item xs={10}>           
                   <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                      අදාළ ගැසට් නිවේදනයේ 8.0 වගන්තිය යටතේ දක්වා ඇති පරිදි අයදුම් කිරීමට හෝ ඇතුලත් වීමට නුසුදුස්සෙකු නොවෙමි.
                    </Typography>
                    <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                    வர்த்தமானி அறிவித்தலின் 8.0 பந்தியில் குறிப்பிடப்பட்டுள்ளவாறு விண்ணப்பிக்க அல்லது அனுமதி பெற்றுக்கொள்ள தகைமையற்றவர் அல்ல.
                    </Typography>
                  <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                  I am not ineligible to apply or be admitted as mentioned in clauses under the section 8.0 of the relevant Gazette Notification.
                  </Typography>
                </Grid>
                <Grid container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center"   sx={{ display: 'flex' }} item xs={1}>
                  <Checkbox sx={{margintop: '0px',paddingtop: '0px'}} checked={agree4}   onChange={handleagree4Change}  inputProps={{ 'aria-label': 'controlled' }} />
                </Grid>

                <Grid  sx={{ display: 'flex',background: "hsl(0, 0%, 95%)" }} item xs={1}>
                      <Typography sx={{margin: 0,fontSize: {lg: 14, md: 12,sm: 10,xs: 10}}}     align="justify" paragraph>
                             5.
                       </Typography>                 
                </Grid>
                <Grid    sx={{
                    width: "100vw",
                    overflowX: "hidden",
                    padding: { xs: "2px", md: "2px" },
                    margin: 0,
                    background: "hsl(0, 0%, 95%)",
                  }} item xs={10}>           
                   <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                   විශ්වවිද්‍යාල ප්‍රවේශය සඳහා විශ්වවිද්‍යාල ප්‍රතිපාදන කොමිෂන් සභාවේ මාර්ගගතව ලියාපදිංචි වී සිටීම ද විද්‍යාපීඨ අයදුම් කිරීමට නුසුදුස්සෙක් වන බව දනිමි.
                    </Typography>
                    <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                    பல்கலைக்கழக அனுமதிக்காக பல்கலைக்கழக மானியங்கள் ஆணைக்குழுவில் நிகழ்நிலை மூலமாக பதிவுசெய்திருப்பதும் கல்வியியற் கல்லூரிக்கு விண்ணப்பிப்பதற்கு என்னை தகுதியற்றவராக்கும் என்பதை அறிந்துள்ளேன்.
                    </Typography>
                  <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                  I am aware that being registered online with the University Grants Commission for university admission also disqualifies me from applying to National Colleges of Education.
                  </Typography>
                </Grid>
                <Grid container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center"   sx={{ display: 'flex',background: "hsl(0, 0%, 95%)", }} item xs={1}>
                  <Checkbox sx={{margintop: '0px',paddingtop: '0px'}} checked={agree5}   onChange={handleagree5Change}  inputProps={{ 'aria-label': 'controlled' }} />
                </Grid>

                <Grid  sx={{ display: 'flex' }} item xs={1}>
                       <Typography sx={{margin: 0,fontSize: {lg: 14, md: 12,sm: 10,xs: 10}}}     align="justify" paragraph>
                             6.
                       </Typography>                   
                </Grid>
                <Grid    sx={{
                    width: "100vw",
                    overflowX: "hidden",
                    padding: { xs: "2px", md: "2px" },
                    margin: 0,
                    
                  }} item xs={10}>           
                   <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                   අදාළ ගැසට් නිවේදනයේ සෙසු වගන්ති ද පැහැදිලිව අවබෝධ කර ගතිමි. අසත්‍ය තොරතුරු ඇතුලත් කිරීම විද්‍යාපීඨ අයදුම් කිරීමට නුසුදුස්සෙක් වන බව මම දනිමි.
                    </Typography>
                    <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                    வர்த்தமானி அறிவித்தலின் ஏனைய பந்திகளை தெளிவாக புரிந்துகொண்டேன். பொய்யான தகவல்களை பதிவிடுவது கல்வியியற் கல்லூரிக்கு விண்ணப்பிப்பதற்கு தகுதியற்றவராக்கும் என்பதை நான் அறிந்துள்ளேன்.
                    </Typography>
                  <Typography sx={{fontSize: {lg: 14, md: 12,sm: 10,xs: 10},margin: 0}}     align="justify" paragraph>
                  I also clearly understood the rest of the clauses of the relevant gazette notification. I understand that entering false information will disqualify me from applying to National Colleges of Education.
                  </Typography>
                </Grid>
                <Grid  container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center"   sx={{ display: 'flex' }} item xs={1}>
                  <Checkbox sx={{margintop: '0px',paddingtop: '0px'}} checked={agree6}   onChange={handleagree6Change}  inputProps={{ 'aria-label': 'controlled' }} />
                </Grid>

               
                
              </Grid>


          </DialogContent>
         
          <DialogActions>

          
 
            {/* <BlueColorButton onClick={handleCloseDisagree}>Close</BlueColorButton>
            <BlueColorButton onClick={handleClose} endIcon={<SendIcon />}  disabled={!enableContinue}   >Continue</BlueColorButton>
 */}          </DialogActions>
          
          <center>
    <Box
      sx={{
        maxWidth:'90%',
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'repeat(2, 1fr)',
        marginBottom:"10px"
      }}>

      <BlueColorButton sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            ".MuiButton-startIcon": { m: 0 }
          }
        })}  variant="contained" onClick={handleCloseDisagree} startIcon ={<ArrowBackIos/>}>
          Back
      </BlueColorButton>
      <BlueColorButton  sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            ".MuiButton-startIcon": { m: 0 }
          }
        })}  variant="contained" onClick={handleClose} disabled={!enableContinue} endIcon={< ArrowForwardIosOutlined />}>
        Continue
      </BlueColorButton>
    </Box>
 </center>

        </Dialog>

        

      </React.Fragment></>
  );
}