import React, { useEffect } from 'react';
import { AppBar, Box, Button, ButtonProps, Card, CardActions, CardContent, CardMedia, Checkbox, Container, Divider, FormControlLabel,   Grid,   Paper,   Stack, Toolbar, Typography, styled } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles'; 
import Header from './Header';
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import withWidth from "@material-ui/core/withWidth";
import { useNavigate } from "react-router-dom";
import { blue } from '@mui/material/colors';
import IconBreadcrumbs from './BreadCrumb';
 
import   "./Information.module.css";
 


const theme = createTheme({
  spacing: 4,
});

export default function Information() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  
  const navigate = useNavigate();
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: 'serif',
        textTransform: 'none',
        fontSize: "2rem",
      },
    },
  });

  function navigationToAL(){
    navigate("/AL");
  }

  function navigationToHome(){
    navigate("/");
  }

 

  const BlueColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: '#2364be',
    '&:hover': {
      backgroundColor: '#2364be',
    },
    minWidth:300,
     
  }));
  const defaultTheme = createTheme();
 

  const classes = {
    root: {
      flexGrow: 1
    },
    paper: {
      padding: 20,
      textAlign: "center",
      color: "blue",
      fontFamily: "Roboto"
    }
  };

  return (
    <>
    
      <Header></Header>
      <IconBreadcrumbs currentPage={"INFORMATION"}></IconBreadcrumbs>

      <Typography
              sx={{textDecoration: 'underline',fontWeight:"bold",fontSize: {lg: 22, md: 18,sm: 16,xs: 16} }}
            
              display="block"
              align="center"
              color="text.primary"
              marginTop={"20px"}
              marginBottom={"20px"}
             
              >
                 Instructions to Applicants 
             </Typography>

      <div style={classes.root}>
          <Grid container spacing={3}> 
            <Grid    item xs={12} sm={4}>
                <Paper   elevation={0} />
                <Typography sx={{fontSize: {lg: 16, md: 14,sm: 14,xs: 14} }}
                style={{ marginLeft: "20px", marginRight:"20px" }}  gutterBottom align="justify" paragraph  > 1.	2021 හෝ 2022 වසරවල අ.පො.ස (උ/පෙළ) විභාගය සමත් සිසුහු අදාළ විෂය ධාරාවේ ලැයිස්තුගත කර ඇති අදාළ පාඨමාලා සඳහා අයදුම් කිරීමට සුදුසුකම් ලබති.  </Typography>
              
                <Typography sx={{fontSize: {lg: 16, md: 14,sm: 14,xs: 14} }}
                style={{ marginLeft: "20px", marginRight:"20px" }} gutterBottom align="justify" paragraph   >2.	4.5 සහ 4.8 ගැසට් වගන්තියට අනුව, 2017 සිට 2020 දක්වා අ.පො.ස (උ/පෙළ) විභාගය සමත් පැවිදි අයදුම්කරුවන්ට පමණක් බුද්ධ ධර්මය සහ කතෝලික/ක්‍රිස්තියානි ධර්මය පාඨමාලා සඳහා අයදුම් කළ හැකිය. </Typography>
            
                <Typography sx={{fontSize: {lg: 16, md: 14,sm: 14,xs: 14} }}
                style={{ marginLeft: "20px", marginRight:"20px" }} gutterBottom align="justify" paragraph   >3.	අදාළ පාඨමාලා යටතේ ගැසට් නිවේදනයේ දක්වා ඇති සෑම විස්තරයක්ම කියවා අවබෝධ කර ගැනීම සහ ඒ අනුව මාර්ගගත අයදුම්පත සම්පූර්ණ කිරීම අයදුම්කරුගේ වගකීම වේ.</Typography>
        
                <Typography sx={{fontSize: {lg: 16, md: 14,sm: 14,xs: 14} }}
                style={{ marginLeft: "20px", marginRight:"20px" }} gutterBottom align="justify" paragraph   >4.	නිවැරදි ලෙස සම්පුර්ණ නොකරන ලද අයදුම්පත් සහ මාර්ගගතව ඉදිරිපත් නොකරන අයදුම්පත් සම්බන්ධයෙන් වන කිසිදු ඉල්ලීමක් සලකා බලනු නොලැබේ.</Typography>
        
              <Paper />
            </Grid>
            <Grid item xs={12} sm={4}>
               <Paper elevation={0} />
                 <Typography style={{ marginLeft: "20px", marginRight:"20px" }}  sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}   gutterBottom align="justify" paragraph  > 1. 2021 அல்லது 2022 ஆம் ஆண்டுகளில் க.பொ.த (உ/த) பரீட்சையில் சித்தியடைந்த    மாணவர்கள், சம்பந்தப்பட்ட பாடப் பிரிவில் பட்டியலிடப்பட்டுள்ள பொருத்தமான பாடநெறிகளுக்கு விண்ணப்பிக்கத் தகுதியுடையவர்கள்.</Typography>
                 <Typography style={{ marginLeft: "20px", marginRight:"20px" }} sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}  gutterBottom align="justify" paragraph  >2. வர்த்தமானி பிரிவு 4.5 மற்றும் 4.8 இன் படி, 2017 முதல் 2020 வரை க.பொ.த (உ/த) பரீட்சையில் சித்தியடைந்த மதகுரு விண்ணப்பதாரர்கள் மாத்திரமே பௌத்தம் மற்றும் கத்தோலிக்க/கிறிஸ்தவ பாடநெறிகளுக்கு விண்ணப்பிக்க முடியும். </Typography>
                 <Typography  style={{ marginLeft: "20px", marginRight:"20px" }} sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}  gutterBottom align="justify" paragraph  >3. அந்தந்தப் பாடப்பிரிவுகளின் கீழ் வர்த்தமானி அறிவிப்பில் வழங்கப்பட்டுள்ள ஒவ்வொரு விவரத்தையும் படித்துப் புரிந்துகொண்டு அதற்கேற்ப ஆன்லைன் நிகழ்நிலை விண்ணப்பத்தைப் பூர்த்தி செய்வது விண்ணப்பதாரரின் பொறுப்பாகும்.</Typography>
                 <Typography style={{ marginLeft: "20px", marginRight:"20px" }} sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}  gutterBottom align="justify" paragraph  >4. சரியாக பூர்த்தி செய்யப்படாத விண்ணப்பங்கள் மற்றும் ஆன்லைனில் நிகழ்நிலை சமர்ப்பிக்கப்படாத விண்ணப்பங்கள் பற்றிய கோரிக்கைகள் பரிசீலிக்கப்படாது.</Typography>
               <Paper />
            </Grid>
            <Grid item xs={12} sm={4}>
               <Paper elevation={0} />
                <Typography  style={{ marginLeft: "20px", marginRight:"20px" }} sx={{fontSize: {lg: 16, md: 14,sm: 14,xs: 14} }}  gutterBottom align="justify" paragraph  >1.	Students who have passed the G.C.E (A/L) examination in the year 2021 or 2022 are eligible to apply for the courses listed in the relevant subject stream. </Typography>
                <Typography style={{ marginLeft: "20px", marginRight:"20px" }} sx={{fontSize: {lg: 16, md: 14,sm: 14,xs: 14} }}  gutterBottom align="justify" paragraph  >2.	According to the gazette section 4.5 and 4.8, only clergy applicants who have passed the G.C.E (A/L) examination from 2017 to 2020 can apply for Buddhism and Catholic/ Christianity courses. </Typography>
                <Typography style={{ marginLeft: "20px", marginRight:"20px" }} sx={{fontSize: {lg: 16, md: 14,sm: 14,xs: 14} }}  gutterBottom align="justify" paragraph  >3.	It is the responsibility of the applicant to read and understand all the instructions provided in the gazette notification under the respective courses and complete the online application accordingly. </Typography>
                <Typography style={{ marginLeft: "20px", marginRight:"20px" }} sx={{fontSize: {lg: 16, md: 14,sm: 14,xs: 14} }}  gutterBottom align="justify" paragraph  >4.	Requests regarding incorrectly filled applications and applications that are not submitted online will not be considered. </Typography>
              <Paper />
            </Grid>

          </Grid>
    </div>

       


 


    <Grid container   marginBottom={'15px'}
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center" >
      <BlueColorButton  sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            ".MuiButton-startIcon": { m: 0 }
          }
         })}  variant="contained" onClick={navigationToAL} endIcon={<SendIcon />}>
         Continue
      </BlueColorButton>
      </Grid>

    

    </>
 

  )
}