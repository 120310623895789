import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { Box, Button, ButtonProps, styled } from '@mui/material';
import { useNavigate } from "react-router-dom";
import {useEffect, useState} from "react";
import { blue } from '@mui/material/colors';
import { ArrowBackIos, KeyboardBackspace } from '@mui/icons-material';


function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}


 
export default function IconBreadcrumbs(props:any) {

    const navigate = useNavigate();
    const [showAl, setShowAl] = React.useState(false);
    const [showOl, setShowOl] = React.useState(false);
    const [showPersonal, setShowPersonal] = React.useState(false);
    const [showCourses, setShowCourses] = React.useState(false);


    useEffect(() => {
        if(props.currentPage==='OL' || props.currentPage==='PERSONAL' || props.currentPage==='COURSES' || props.currentPage==='SUMMARY' ){
            setShowAl(true);
        } 

        if(props.currentPage==='PERSONAL' || props.currentPage==='COURSES' || props.currentPage==='SUMMARY' ){
            setShowOl(true);
        } 


        

        if(props.currentPage==='COURSES' || props.currentPage==='SUMMARY' ){
            setShowPersonal(true);
        } 

        if(props.currentPage==='SUMMARY' ){
            setShowCourses(true);
        } 

        
      }, []);

   

    
    function goToHome(){
        navigate("/");
    }

    function goToHomeAl(){
        navigate("/AL");
    }

 

    function goToHomeOl(){
        navigate("/OL");
    }

    function goToHomePersonal(){
        navigate("/personnalInfo");
    }
    function goToHomeCourses(){
        navigate("/courses");
    }


    function navigateToPage(){

        if(props.currentPage==='INFORMATION'){
            navigate("/");
        }else{
            if(props.currentPage==='AL'){
                navigate("/info");
            }else{
                if(props.currentPage==='OL'){
                    navigate("/AL");
                }else{
                    if(props.currentPage==='PERSONNAL'){
                        navigate("/OL");
                    } else{
                        if(props.currentPage==='COURSES'){
                            navigate("/personnalInfo");
                        }else{
                            if(props.currentPage==='SUMMARY'){
                                navigate("/courses");
                            }  

                            
                        }    
                    }   


                }
            }
        }

        

       
    }


    const BlueColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText(blue[600]),
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor:  '#f80e07',
        },
      }));
  return (
    
    <>
    
    
    <Box sx={{ m: '2rem' }} />
       <Box
                  sx={{
                    minWidth:'150px',
                    maxWidth:'5%',
                    display: 'grid',
                    gap: 1,
                  }}>


                        <Link   onClick={navigateToPage}  underline="hover">
                          <Typography sx={{ display: "flex", alignItems: "center" }}>
                               <KeyboardBackspace/>
                                Back
                           </Typography>
                        </Link>

                 {/*  <BlueColorButton sx={(theme) => ({
                            [theme.breakpoints.down("sm")]: {
                                ".MuiButton-startIcon": { m: 0 }
                            }
                            })}  variant="contained" onClick={navigateToPage} startIcon ={<ArrowBackIos/>}>
                                    <Typography sx={{fontSize: {lg: 16, md: 14,sm: 13,xs: 13} }}> 
                                        Back
                                    </Typography>   
                  </BlueColorButton> */}
                  
         </Box> 
          
    </>

  );
}