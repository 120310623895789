import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Button, { ButtonProps } from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
/* import Link from '@mui/material/Link'; */
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AgreeDialog from './common/Agree';
import Header from './common/Header';
import { Download, Margin, Pix } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, styled, keyframes } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { blue, green, purple, yellow } from '@mui/material/colors';
import { useEffect } from 'react';
import { orange } from '@material-ui/core/colors';
import api from './common/ApiInterceptor';


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
     
      {/* <Link color="inherit" href="https://mui.com/">
         https://nie.lk/ncoe
      </Link>{' '} */}
     
    </Typography>
  );
}


const theme = createTheme({
  spacing: 4,
});




const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SusAlbum() {

  const [showDataEntry, setshowDataEntry] = React.useState(false);
  const [showDownload, setshowDownload] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [examindex, setExamindex] = React.useState('');
  const [examnic, setExamnic] = React.useState('');
  const [applicationNo, setApplicationNo] = React.useState('');
  const navigate = useNavigate();
  const [showDownloadError, setShowDownloadError] = React.useState(false);
  const [closingDate, setClosingDate] = React.useState<string>('');

  const apiUrl = process.env.REACT_APP_API_URL;
  let tokenRequest = {
    "username": process.env.REACT_APP_TOKEN_USERNAME,
  }

  useEffect(() => {
    const closingDate = process.env.REACT_APP_CLOSING_DATE;
    setClosingDate(closingDate as string);
    console.log('closingDate',closingDate);
  }, []);



  const handleDataEntryDialogClose = () => {
    setshowDataEntry(false);    
  
  };
  
  const handleDataEntryDialogOpen = () => {
    setshowDataEntry(true);    
  
  };
 
  
  const handleDownloadDialogOpen = () => {
     
    setshowDownload(true);    
  
  };

  const editApplication = async () => {
   
      
      if(!examnic || !examindex){
        setshowDataEntry(false);  
        setShowError(true);     
        setErrorMsg("Please Enter NIC Number and A/L Index Number");
      }else{
             try {   

              let alrequest = {              
                "examIndexNo": examindex.trim(),
                "nic": examnic.trim(),
                "applicationNo" : applicationNo
              }
              const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
              sessionStorage.removeItem("token");
              sessionStorage.setItem("token",jwtToken.data);
              const response = await api.post(apiUrl + '/getApplication', alrequest);
              if(response.data){
              
                if(response.data.editExpired){
                  if(response.data.editExpired==="Y"){
                    setshowDataEntry(false);  
                    setShowError(true);     
                    setErrorMsg("Application edit allowed period has already expired !");
                    return;
                  }
                }


                if(!response.data.applicationNo){
                  setshowDataEntry(false);  
                  setShowError(true);     
                  setErrorMsg("Application Not Found");
                }else{
                  sessionStorage.setItem("EDIT_MODE","Y");
                  sessionStorage.setItem("OL_TYPE", 'OL');
                  sessionStorage.setItem("AL_YEAR",response.data.alYear);
                  sessionStorage.setItem("AL_INDEX", response.data.alIndexNo);
                  sessionStorage.setItem("AL_NIC",response.data.nic);
                  sessionStorage.setItem("AL_STREAM",response.data.alStream);
                  sessionStorage.setItem("APPLICATION_NO",response.data.applicationNo);
 
                  
                  sessionStorage.setItem("OL_ATTEMP1_YEAR",response.data.ol_year_one);
                  sessionStorage.setItem("OL_ATTEMP1_INDEX",response.data.ol_year_one_index);
               
                  sessionStorage.setItem("OL_ATTEMP2_YEAR",response.data.ol_year_two);
                  sessionStorage.setItem("OL_ATTEMP2_INDEX",response.data.ol_year_two_index);
               
                  sessionStorage.setItem("OL_ATTEMP3_YEAR",response.data.ol_year_three);
                  sessionStorage.setItem("OL_ATTEMP3_INDEX",response.data.ol_year_three_index);
               
                  sessionStorage.setItem("PERSONAL_INFO",JSON.stringify(response.data.personalInfoDTO));
                  sessionStorage.setItem("COURSES",JSON.stringify(response.data.coursesInfoDTO));   
                  


                navigate("/AL");

                }

              }else{
                setshowDataEntry(false);  
                setShowError(true);     
                setErrorMsg("Application Not Found");
              }


             } catch (error: unknown) {
              setShowError(true);
              setErrorMsg('Network Error Occured(E0002');         
            }

           

      }
    

      


  
  };

  const handleErrorDialogClose = () => {
    setShowError(false);    
    setshowDataEntry(true);      
 };

 const handleDownloadErrorDialogClose = () => {
  setShowDownloadError(false);
  setshowDownload(true);      
};


const handleDownloadDialogClose = () => {
  setShowDownloadError(false);
  setshowDownload(false); 
  
 
};




 const generateReport = async () => {    
  try {
     
    const alIndexNo=examindex;
    const nic=examnic;

    const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
        sessionStorage.removeItem("token");
        sessionStorage.setItem("token",jwtToken.data);

    const response = await api({
    url: `${apiUrl + "/downloadReport"}`,  
    method: 'POST',
    data: {  
      alIndexNo,
      nic
    },
    responseType: 'blob', // important
   });

 
   if(response.data.size===0){
      setshowDownload(false);   
      setShowDownloadError(true);
      setErrorMsg('Application Not Found..!');
      return;
   }
    var blob = new Blob([response.data], {type: "application/pdf"});
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "NCOEApplication.pdf";
    link.click();


      } catch (error) {
        // Handle any errors that occurred during the request
        console.error(error);
         
      }
    };

    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: purple[200],
      '&:hover': {
        backgroundColor: purple[300],
      },
      minWidth:300
    }));

    const SinhalaColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
      color: theme.palette.getContrastText(blue[600]),
      backgroundColor: '#2364be',
      '&:hover': {
        backgroundColor: '#2364be',
      },
      minWidth:300,
       
    }));


    const ColorTamilButton = styled(Button)<ButtonProps>(({ theme }) => ({
      color: theme.palette.getContrastText(blue[500]),
      backgroundColor: blue[500],
      '&:hover': {
        backgroundColor: blue[700],
      },
      minWidth:300
    }));

    const TamilColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
      color: theme.palette.getContrastText(orange[600]),
      backgroundColor: orange[600],
      '&:hover': {
        backgroundColor: orange[700],
      },
      minWidth:300
    }));



    const ColorEnglishButton = styled(Button)<ButtonProps>(({ theme }) => ({
      color: theme.palette.getContrastText(green[500]),
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
      minWidth:300
    }));



    const onButtonClickSinhala = () => { 
      fetch("/NcoeWebApp/assets/doc/Gazette-S.pdf").then((response) => {
          response.blob().then((blob) => { 
              const fileURL =window.URL.createObjectURL(blob);
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = "Gazette-Sinhala.pdf";
              alink.click();
          });
      });
    };

    const onButtonClickTamil = () => { 
      fetch("/NcoeWebApp/assets/doc/Gazette-T.pdf").then((response) => {
          response.blob().then((blob) => { 
              const fileURL =window.URL.createObjectURL(blob);
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = "Gazette-Sinhala.pdf";
              alink.click();
          });
      });
    };

    const onButtonClickEnglish = () => { 
      fetch("/NcoeWebApp/assets/doc/Gazette-E.pdf").then((response) => {
          response.blob().then((blob) => { 
              const fileURL =window.URL.createObjectURL(blob);
              let alink = document.createElement("a");
              alink.href = fileURL;
              alink.download = "Gazette-Sinhala.pdf";
              alink.click();
          });
      });
    };

   function initializeSession(){
    const agreed=sessionStorage.getItem('agreed')
    sessionStorage.clear();
    if(agreed==='1'){
         sessionStorage.setItem("agreed","1");
    }
   }


   const blink = keyframes`
      from { opacity: 0; }
      to { opacity: 1; }
 `  ;
 
 const BlinkedBox = styled('div')({
    color: 'red',
    width: 330,
    height: 30,
    animation: `${blink} 2s linear infinite`,
 });

  

  return (

    <>


<Dialog open={showDownloadError}   keepMounted
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                minWidth: "500px",  
              },
            },
          }}
          
          >
          <DialogTitle style={{ backgroundColor: 'red', color: 'white' }}> Error</DialogTitle>
           <DialogContent dividers>
              <p > {errorMsg} </p>
           </DialogContent> 
        
          <DialogActions>
              <Button variant="contained" autoFocus onClick={handleDownloadErrorDialogClose}>
                    Ok
              </Button>        
      </DialogActions>
</Dialog>

<Dialog open={showError}   keepMounted
         sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
               
            },
          },
        }}
          
          >
          <DialogTitle style={{ backgroundColor: 'red', color: 'white' }}> Error</DialogTitle>
           <DialogContent dividers>
             <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13}}} > {errorMsg}</Typography>
           </DialogContent> 
        
          <DialogActions>
              <Button variant="contained" autoFocus onClick={handleErrorDialogClose}>
              <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13}}} > Ok</Typography>
              </Button>        
      </DialogActions>
</Dialog>

<Dialog open={showDataEntry}   maxWidth="lg"    keepMounted fullWidth >
          <DialogTitle style={{ backgroundColor: 'blue', color: 'white' }}>  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13}}} >Seach Your Application</Typography></DialogTitle>
           <DialogContent dividers>
           <Grid container spacing={1}>

              <Grid item xs={12} sm={4}>
                  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13}}} >Please Enter Application Number</Typography>
                  <TextField  value={applicationNo} id="standard-basic"  variant="standard"  
                       onChange={(event) => {
                         setApplicationNo(event.target.value);
                       }}
                       inputProps={{maxLength: 7}} 
                       /> 
              </Grid>
              <Grid item xs={12} sm={4}>
                  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13}}} >Please Enter G.C.E (A/L) Index Number</Typography>
                  <TextField value={examindex} id="standard-basic"   variant="standard" 
                    onChange={(event) => {
                      setExamindex(event.target.value);
                   }}
                   onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }}}
                    inputProps={{maxLength: 7}} 
                   />
              </Grid>

              <Grid item xs={12} sm={4}>
                  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13}}} >Please Enter NIC Number</Typography>
                  <TextField value={examnic} id="standard-basic"  variant="standard" 
                    onChange={(event) => {
                      setExamnic(event.target.value);
                   }}
                   inputProps={{maxLength: 12}} 
                   />
              </Grid>
              
               

              
            </Grid>
           </DialogContent> 
        
          <DialogActions>
              <Button autoFocus size="small" variant="contained" onClick={handleDataEntryDialogClose}>
              <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13}}} > Close</Typography>
              </Button>
              <Button autoFocus onClick={editApplication} size="small" variant="contained" endIcon={<SendIcon />}>
              <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13}}} > Edit Application</Typography>
              </Button>        
      </DialogActions>
      </Dialog>


      <Dialog open={showDownload}   keepMounted fullWidth
          maxWidth='sm'>
          <DialogTitle style={{ backgroundColor: 'blue', color: 'white' }}> Seach Your Application</DialogTitle>
           <DialogContent dividers>
           <Grid container spacing={2}>
              <Grid item xs={6}>
                 <TextField id="standard-basic" label="Please Enter NIC Number" variant="standard" 
                 onChange={(event) => {
                  setExamnic(event.target.value);
                }}  
                 />
              </Grid>
              <Grid item xs={6}>
                 <TextField id="standard-basic" label="Please Enter A/L Index Number" variant="standard" 
                  onChange={(event) => {
                    setExamindex(event.target.value);
              }}  
                 />
              </Grid>
               
            </Grid>            
           
             
           </DialogContent> 
        
          <DialogActions>
             <Button autoFocus size="small" variant="contained" onClick={handleDownloadDialogClose}>
                   Close
              </Button>

              <Button autoFocus onClick={generateReport}>
                    Download Application
              </Button>        
      </DialogActions>
      </Dialog>

   
    
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header></Header>
     
     
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 2,
            pb: 2,
          }}
        >
          <Container maxWidth="lg">
            <Typography
              variant="h5"
              display="block"
              align="center"
              color="text.primary"
              >
                 Online Application for Admission of Student Teachers to National Colleges of 
             </Typography>
             <Typography
              variant="h5"
              display="block"
              align="center"
              color="text.primary"
              gutterBottom>
                       Education 2023 (2024)
             </Typography>
             
     
            <center>
            <BlinkedBox>
                 <Typography     align="center" gutterBottom  >
                      {closingDate}
                  </Typography>
            </BlinkedBox>
           </center>
          </Container>
        </Box>


        <Container sx={{ py: 1 }} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={4}>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                raised
                sx={{
                  maxWidth: 380,
                  minHeight:320,
                  margin: "0 auto",
                  padding: "0.1em",
                  borderRadius: "5px" ,
                  display: "flex",
                  flexDirection: "column",
                }}

           
                
              >
                

              <CardMedia
                  
                  title={"titleasdasdsada"}
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />

                <CardContent sx={{ flexGrow: 1 }}>
                <Typography align="center" gutterBottom variant="h6" component="h2">
                      Download Government Gazette
                  </Typography>
                  <Typography align="justify"  >
                     Download the Government Gazette no 2376 dated 15/03/2024 for admission of student teachers to National Colleges of Education 2023 (2024).
                  </Typography>
                 
                </CardContent>
                <CardActions disableSpacing sx={{ mt: "auto" }} >
                  <Grid item xs={12} sm={12} md={12}>
                    <center>  
                       
                       <Box  m={1} pt={1}>       
                         <SinhalaColorButton onClick={onButtonClickSinhala} variant="contained">Download Gazette in Sinhala</SinhalaColorButton>
                       </Box>
                       <Box m={1} pt={0}>
                         <SinhalaColorButton onClick={onButtonClickTamil} variant="contained">Download Gazette in Tamil</SinhalaColorButton>
                       </Box>
                       <Box >
                          <SinhalaColorButton onClick={onButtonClickEnglish} variant="contained">Download Gazette in English</SinhalaColorButton> 
 
                       </Box>
                     </center>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
            <Card
                raised
                sx={{
                  maxWidth: 380,
                  minHeight:345,
                  margin: "0 auto",
                  padding: "0.1em",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >

               <CardMedia
                  
                  title={"titleasdasdsada"}
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />

                
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography align="center" gutterBottom variant="h6" component="h2">
                     Submit Online Application
                  </Typography>
                  <Typography align="justify">
                     It is compulsory that every applicant should submit application through the online system. A PDF file can be downloaded after submitting the online application. If you are selected for the interview, you have to submit the printed application to the interview board.
                  </Typography>
                </CardContent>
                
                {/* <Link to="/AL"  onClick={initializeSession} style={{ textDecoration: 'none' }}> */}
                <Link to="/info"  onClick={initializeSession} style={{ textDecoration: 'none' }}>
                
                <CardActions disableSpacing sx={{ mt: "auto" }}>
                  <Grid item xs={12} sm={12} md={12}>
                   
                  </Grid>
                </CardActions>
                </Link> 
              </Card>
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
            <Card
                raised
                sx={{
                  maxWidth: 380,
                  minHeight:345,
                  margin: "0 auto",
                  padding: "0.1em",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column", 
                }}
              >

              <CardMedia
                       
                  
                  
                  title={"titleasdasdsada"}
                  sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />

               
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography align="center" gutterBottom variant="h6" component="h2">
                    Edit Submitted Application
                  </Typography>
                  <Typography align="justify">
                     You can edit the submitted application within 07 days after submission. A PDF file with the edited version can be downloaded after editing the submitted online application.
                  </Typography>
                </CardContent>

                <CardActions disableSpacing sx={{ mt: "auto" }} >
                  <Grid item xs={12} sm={12} md={12}>
                    <center>
                      <Box m={1} pt={1}>
                         <SinhalaColorButton sx={{width:'100%'}}   onClick={handleDataEntryDialogOpen} variant="contained">Edit the Submitted Application</SinhalaColorButton>
 
                      </Box>
                      <Box m={1}>
                           <SinhalaColorButton sx={{width:'100%'}} onClick={handleDownloadDialogOpen} variant="contained">Download the Submitted Application</SinhalaColorButton>

                      </Box>
                    </center>
                  </Grid>
                </CardActions>
                
                

              </Card>
            </Grid>


          </Grid>
        </Container>
      </main>

      
      <Box sx={{paddingTop:2 }}></Box>

      <Box   sx={{marginTop: "auto",p: 1,  bgcolor: '#f4f6f6'}} >
        <Typography align="center">
           <Box  fontSize={14} fontWeight='fontWeightMedium' display='inline'>NCOE Branch Direct contact 0112784818 / 0112784816
             (Please call between 9.00 a.m. to 4.00 p.m. on week days)
           </Box>       
        </Typography>       
        <Typography align="center">
           <Box  fontSize={14} fontWeight='fontWeightMedium' display='inline'>Copyright @ 2024 – Ministry of Education – All Rights Reserved</Box>
        </Typography>
           
        <Grid>      

        </Grid>

      </Box>

     
        <Grid container
          marginTop={'10px'}
            spacing={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '10vh' }}>
            <Grid item xs={12}>
              <Typography sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'bold' }} >
                  Powered by Informatics Institute of Technology (IIT) 

              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'bold' }} >
                  <img     src="/NcoeWebApp/assets/images/IIT.png" alt="my image" /> 

              </Typography>
            </Grid>
        </Grid>
       


           
    

      



      <Box sx={{paddingTop:1 }}></Box>
     
    </ThemeProvider></>
  );
}