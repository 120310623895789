import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, ButtonProps, Checkbox, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, IconButton, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, Stack, styled, SvgIcon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField  } from "@mui/material";
import React from "react";
import './../App.css';
import './Courses.css';
import Card from '@mui/joy/Card';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios, { AxiosError } from "axios";
import {useEffect, useState} from "react";
import { DataGrid, GridColDef, GridRowSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import Header from "../common/Header";
import SendIcon from '@mui/icons-material/Send';
import IconBreadcrumbs from "../common/BreadCrumb";
import { blue } from "@mui/material/colors";
import { ArrowBackIos, ArrowDownward, ArrowUpward, Delete, WidthFull } from "@mui/icons-material";
import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import OpenInNewSharpIcon from '@mui/icons-material/OpenInNewSharp';
import api from "../common/ApiInterceptor";

const theme = createTheme();



theme.typography.h3 = {
  fontSize: '1.3rem',
  color:'black',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.3rem',
  },
};

theme.typography.h5 = {
    fontSize: '1rem',
    color:'black',
    '@media (min-width:600px)': {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  };



export default function Courses() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  

  interface Courses{ 
    coursePreference1:string;
    coursePreference2:string;
    coursePreference3:string;    
    applySripada:string;
    estateChild:string,
    course1Certificates:any[];
    course2Certificates:any[];
    course3Certificates:any[];     
    course1SportCertificates:any[];
    course2SportCertificates:any[];
    course3SportCertificates:any[];
    course4SportCertificates:any[];
    course5SportCertificates:any[];
    course6SportCertificates:any[];
    course7SportCertificates:any[];
    course8SportCertificates:any[];
    course9SportCertificates:any[];
    gitYear:string; 
    gitIndex:string; 
    
  }

  

  const [checked, setChecked] = React.useState([0]);
    const [applySripada, setApplySripada] = React.useState('');
    

    const [estateChild, seteEstateChild] = React.useState('');
    const [cousepref1, setCousepref1] = React.useState('');
    const [cousepref2, setCousepref2] = React.useState('');
    const [cousepref3, setCousepref3] = React.useState('');
   

    const [allCourses, setAllCourses] = React.useState('');
    const [courses, setCourses] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [showAdditionalCourses, setShowAdditionalCourses] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [showGit, setShowGit] = React.useState(false);
    const [gitExamYear, setGitExamYear] =React.useState('');
    const [gitExamIndex, setGitExamIndex] =React.useState('');

    const [gitExamYeartmp, setGitExamYeartmp] =React.useState('');
    const [gitExamIndextmp, setGitExamIndextmp] =React.useState('');
    
    
   
    const [showSpecialRequirementTab1, setshowSpecialRequirementTab1] = React.useState(false);
    const [tableData1, setTableData1] = useState([])
    const [selectedRows1, setSelectedRows1] = useState([])

    const [showSpecialRequirementTab2, setshowSpecialRequirementTab2] = React.useState(false);
    const [tableData2, setTableData2] = useState([])
    const [selectedRows2, setSelectedRows2] = useState([])

    const [showSpecialRequirementTab3, setshowSpecialRequirementTab3] = React.useState(false);
    const [tableData3, setTableData3] = useState([])
    const [selectedRows3, setSelectedRows3] = useState([])


    const [showSpecialRequirementTab4, setshowSpecialRequirementTab4] = React.useState(false);
    const [tableData4, setTableData4] = useState([])
    const [selectedRows4, setSelectedRows4] = useState([])

    const [showSpecialRequirementTab5, setshowSpecialRequirementTab5] = React.useState(false);
    const [tableData5, setTableData5] = useState([])
    const [selectedRows5, setSelectedRows5] = useState([])

    const [showSpecialRequirementTab6, setshowSpecialRequirementTab6] = React.useState(false);
    const [tableData6, setTableData6] = useState([])
    const [selectedRows6, setSelectedRows6] = useState([])

    const [showSportAccordian, setShowSportAccordian] = React.useState(false);
    const [showSportAccordian2, setShowSportAccordian2] = React.useState(false);
    const [showSportAccordian3, setShowSportAccordian3] = React.useState(false);
   
    
    const [sporttableData1, setSporttableData1] = useState([])
    const [sportSselectedRows1, setSportSselectedRows1] = useState([])

    const [sporttableData2, setSporttableData2] = useState([])
    const [sportSselectedRows2, setSportSselectedRows2] = useState([])

    const [sporttableData3, setSporttableData3] = useState([])
    const [sportSselectedRows3, setSportSselectedRows3] = useState([])


    const [sporttableData4, setSporttableData4] = useState([])
    const [sportSselectedRows4, setSportSselectedRows4] = useState([])

    const [sporttableData5, setSporttableData5] = useState([])
    const [sportSselectedRows5, setSportSselectedRows5] = useState([])

    const [sporttableData6, setSporttableData6] = useState([])
    const [sportSselectedRows6, setSportSselectedRows6] = useState([])

    const [sporttableData7, setSporttableData7] = useState([])
    const [sportSselectedRows7, setSportSselectedRows7] = useState([])

    const [sporttableData8, setSporttableData8] = useState([])
    const [sportSselectedRows8, setSportSselectedRows8] = useState([])

    const [sporttableData9, setSporttableData9] = useState([])
    const [sportSselectedRows9, setSportSselectedRows9] = useState([])
    const navigate = useNavigate();
    const [execute, setexecute] = React.useState(false);


    const [autoSelectedExamRows1, setAutoSelectedExamRows1] = useState<string[]>([])
    const [autoSelectedExamRows2, setAutoSelectedExamRows2] = useState<string[]>([])
    const [autoSelectedExamRows3, setAutoSelectedExamRows3] = useState<string[]>([])


    const [autoSelectedRows1, setAutoSelectedRows1] = useState<string[]>([])
    const [autoSelectedRows2, setAutoSelectedRows2] = useState<string[]>([])
    const [autoSelectedRows3, setAutoSelectedRows3] = useState<string[]>([])
    const [autoSelectedRows4, setAutoSelectedRows4] = useState<string[]>([])
    const [autoSelectedRows5, setAutoSelectedRows5] = useState<string[]>([])
    const [autoSelectedRows6, setAutoSelectedRows6] = useState<string[]>([])
    const [autoSelectedRows7, setAutoSelectedRows7] = useState<string[]>([])
    const [autoSelectedRows8, setAutoSelectedRows8] = useState<string[]>([])
    const [autoSelectedRows9, setAutoSelectedRows9] = useState<string[]>([])
    const [showRemoveGit, setShowRemoveGit] = React.useState(false);

     
    const apiUrl = process.env.REACT_APP_API_URL;

    let tokenRequest = {
      "username": process.env.REACT_APP_TOKEN_USERNAME,
    }


    useEffect(() => {

    

      const savedObject: string = sessionStorage.getItem("COURSES")!;

     

        if(savedObject){
          const courses: Courses  =JSON.parse(savedObject);   
          console.log('Saved Object ',courses);
          if(courses){
            if(courses.applySripada){
              setApplySripada(courses.applySripada);
            }
           
            setGitExamIndex(courses.gitIndex);
            setGitExamYear(courses.gitYear);
            setGitExamIndextmp(courses.gitIndex);
            setGitExamYeartmp(courses.gitYear);
            


            if(courses.estateChild){
              seteEstateChild(courses.estateChild);
            }

            if(courses.coursePreference1){
            
              setCousepref1(courses.coursePreference1);
              if(courses.course1Certificates){
                setSelectedRows1(courses.course1Certificates as any);
                const arr :string[]= [];
                courses.course1Certificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))        
                     
                setAutoSelectedExamRows1(arr);
              }


              fetchSpecialExamForCourse1(courses.coursePreference1);
              
              if(courses.course1SportCertificates){
                setSportSselectedRows1(courses.course1SportCertificates as any);     
                const arr :string[]= [];
                courses.course1SportCertificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))                       
                setAutoSelectedRows1(arr);

              }
              if(courses.course2SportCertificates){
                setSportSselectedRows2(courses.course2SportCertificates as any);     
                const arr :string[]= [];
                courses.course2SportCertificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))                       
                setAutoSelectedRows2(arr);

              }
              if(courses.course3SportCertificates){
                setSportSselectedRows3(courses.course3SportCertificates as any);
                const arr :string[]= [];
                courses.course3SportCertificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))                       
                setAutoSelectedRows3(arr);
              }
            }

            if(courses.coursePreference2){
              setCousepref2(courses.coursePreference2);
              if(courses.course2Certificates){
                setSelectedRows2(courses.course2Certificates as any);
              
                const arr :string[]= [];
                courses.course2Certificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))        
                     
                setAutoSelectedExamRows2(arr);

              }
              fetchSpecialExamForCourse2(courses.coursePreference2);
             
              if(courses.course4SportCertificates){
                setSportSselectedRows4(courses.course4SportCertificates as any); 
                const arr :string[]= [];
                courses.course4SportCertificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))                       
                setAutoSelectedRows4(arr);              
              }
              if(courses.course5SportCertificates){
                setSportSselectedRows5(courses.course5SportCertificates as any);  
                const arr :string[]= [];
                courses.course5SportCertificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))                       
                setAutoSelectedRows5(arr);                 
              }
              if(courses.course6SportCertificates){
                setSportSselectedRows6(courses.course6SportCertificates as any);
                const arr :string[]= [];
                courses.course6SportCertificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))                       
                setAutoSelectedRows6(arr); 
              }
            }



            if(courses.coursePreference3){
              setCousepref3(courses.coursePreference3);
              if(courses.course3Certificates){
                setSelectedRows3(courses.course3Certificates as any);
                const arr :string[]= [];
                courses.course3Certificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))        
                     
                setAutoSelectedExamRows3(arr);
              }

              fetchSpecialExamForCourse3(courses.coursePreference3);

              if(courses.course7SportCertificates){
                setSportSselectedRows7(courses.course7SportCertificates as any); 
                const arr :string[]= [];
                courses.course7SportCertificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))                       
                setAutoSelectedRows7(arr);
              }

              if(courses.course8SportCertificates){
                setSportSselectedRows8(courses.course8SportCertificates as any);    
                const arr :string[]= [];
                courses.course8SportCertificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))                       
                setAutoSelectedRows8(arr);
                

              }
              if(courses.course9SportCertificates){
                setSportSselectedRows9(courses.course9SportCertificates as any);
                const arr :string[]= [];
                courses.course9SportCertificates.forEach(((element:any)=> {
                  arr.push(element.id);
                }))                       
                setAutoSelectedRows9(arr);
              }  
              
            }
          } 
        } 
    },[]);






  const handleYearChange = (event: SelectChangeEvent) => {
      setGitExamYeartmp(event.target.value as string);
      if(event.target.value==='2019' || event.target.value==='2020' || 
          event.target.value==='2021' || event.target.value==='2022' || event.target.value==='2023'){
          setGitExamIndextmp("Result Pending");
      }else{
        setGitExamIndextmp('');
      }   
  };

   
    const handleSriPadaChange = (event: SelectChangeEvent) => {
        setApplySripada(event.target.value as string);
    };

    const handleEstateChildChange = (event: SelectChangeEvent) => {
        seteEstateChild(event.target.value as string);    
        setApplySripada('');
    };
 

    const BlueColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
      color: theme.palette.getContrastText(blue[600]),
      backgroundColor: '#2364be',
      '&:hover': {
        backgroundColor: '#2364be',
      },
      minWidth:300,
       
    }));
    
     

    useEffect(() => {
        if(!loading){
            fetchAllCourses();
        }
        
      }, []);
    
     const fetchAllCourses = async () => {
        setLoading(true);
        try {          
  
          let stream = {              
             "code": sessionStorage.getItem('AL_STREAM') as string, 
             "alIndexNo"   : sessionStorage.getItem('AL_INDEX') as string,  
             "alYear"   : sessionStorage.getItem('AL_YEAR') as string,   
             "medium"   : sessionStorage.getItem('AL_MEDIUM') as string 
          }

          const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
            sessionStorage.removeItem("token");
            sessionStorage.setItem("token",jwtToken.data);
          const response = await api.post(apiUrl + "/getAllCoursesForStream",stream);
          setCourses(response.data);           
          setLoading(false);
        } catch (error) {
          // Handle any errors that occurred during the request
          console.error(error);
          setLoading(false);
           
        }
      };


      const  fetchSpecialExamForCourse1  = async (courceId:string) => {
         
        try {
          setShowSportAccordian(false);
          setshowSpecialRequirementTab1(false);
          setTableData1([]); 
        
          
            if(courceId==='28' || courceId==='29' || courceId==='30' ){
                    setShowSportAccordian(true);

                    const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
                    sessionStorage.removeItem("token");
                    sessionStorage.setItem("token",jwtToken.data);

                    const response = await api.get(apiUrl + "/getAthleticsSports");
                  
                    if(response.data!=null && response.data.length > 0){
                      response.data.sort((a: { orderColumn: number; },b: { orderColumn: number; }) => {
                        return a.orderColumn > b.orderColumn;
                      });
                        setshowSpecialRequirementTab1(true);
                     } 
                      setSporttableData1(response.data);  
                                  
                      const response2 = await api.get(apiUrl + "/getNonAthleticsSports");
                    
                    if(response2.data!=null && response2.data.length > 0){
                      response2.data.sort((a: { orderColumn: number; },b: { orderColumn: number; }) => {
                        return a.orderColumn > b.orderColumn;
                      });
                      setshowSpecialRequirementTab2(true);
                    }
                    setSporttableData2(response2.data);   
                    const response3 = await api.get(apiUrl + "/getOtherSports");
                    
                    if(response3.data!=null && response3.data.length > 0){
                      response3.data.sort((a: { orderColumn: number; },b: { orderColumn: number; }) => {
                        return a.orderColumn > b.orderColumn;
                      }); 
                      setshowSpecialRequirementTab3(true);
                    } 
                    setSporttableData3(response3.data); 
               
            }else{
              if(courceId){
                let alrequest = {              
                    "examIndexNo": courceId,               
                }

                const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
                    sessionStorage.removeItem("token");
                    sessionStorage.setItem("token",jwtToken.data);
                const response = await api.post(apiUrl + "/getSpecialCertificatesForCourse",alrequest);
                setTableData1(response.data); 
                if(response.data!=null && response.data.length > 0){
                    setshowSpecialRequirementTab1(true);
                }   
             }        

            }
            
           
            
        } catch (error) {         
          console.error(error);       
        }
      };


      

      const  fetchSpecialExamForCourse2  = async (courceId:string) => {
         
        try {

          setShowSportAccordian2(false);
          setshowSpecialRequirementTab1(false);
          setTableData2([]); 


          if(courceId==='28' || courceId==='29' || courceId==='30'){
                setShowSportAccordian2(true);

                const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
                sessionStorage.removeItem("token");
                sessionStorage.setItem("token",jwtToken.data);
                const response = await api.get(apiUrl + "/getAthleticsSports");
              
                if(response.data!=null && response.data.length > 0){
                  response.data.sort((a: { orderColumn: number; },b: { orderColumn: number; }) => {
                    return a.orderColumn > b.orderColumn;
                  });
                  setshowSpecialRequirementTab1(true);
                } 
                  setSporttableData4(response.data);              
                  const response2 = await api.get(apiUrl + "/getNonAthleticsSports");
                
                if(response2.data!=null && response2.data.length > 0){
                  response2.data.sort((a: { orderColumn: number; },b: { orderColumn: number; }) => {
                    return a.orderColumn > b.orderColumn;
                  });
                  setshowSpecialRequirementTab2(true);
                }
                setSporttableData5(response2.data);   
                const response3 = await api.get(apiUrl + "/getOtherSports");
                
                if(response3.data!=null && response3.data.length > 0){
                  response3.data.sort((a: { orderColumn: number; },b: { orderColumn: number; }) => {
                    return a.orderColumn > b.orderColumn;
                  }); 
                  setshowSpecialRequirementTab3(true);
                } 
                setSporttableData6(response3.data);    
          } else{
            if(courceId){
                    let alrequest = {              
                        "examIndexNo": courceId,               
                    }
                    const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
                    sessionStorage.removeItem("token");
                    sessionStorage.setItem("token",jwtToken.data);
                    const response = await api.post(apiUrl + "/getSpecialCertificatesForCourse",alrequest);
                    setTableData2(response.data); 
                    if(response.data!=null && response.data.length > 0){
                        setshowSpecialRequirementTab2(true);
                    }   
            }        
         }
           
            
        } catch (error) {         
          console.error(error);       
        }
      };

      const  fetchSpecialExamForCourse3  = async (courceId:string) => {
         
        try {


          setShowSportAccordian3(false);
          setshowSpecialRequirementTab1(false);
          setTableData3([]); 

          if(courceId==='28' || courceId==='29' || courceId==='30'){
            setShowSportAccordian3(true);
            const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
            sessionStorage.removeItem("token");
            sessionStorage.setItem("token",jwtToken.data);

            const response = await api.get(apiUrl + "/getAthleticsSports");
          
            if(response.data!=null && response.data.length > 0){
              response.data.sort((a: { orderColumn: number; },b: { orderColumn: number; }) => {
                return a.orderColumn > b.orderColumn;
              });
              setshowSpecialRequirementTab1(true);
            } 
              setSporttableData7(response.data);              
              const response2 = await api.get(apiUrl + "/getNonAthleticsSports");
            
            if(response2.data!=null && response2.data.length > 0){
              response2.data.sort((a: { orderColumn: number; },b: { orderColumn: number; }) => {
                return a.orderColumn > b.orderColumn;
              });
              setshowSpecialRequirementTab2(true);
            }
            setSporttableData8(response2.data);   
            const response3 = await api.get(apiUrl + "/getOtherSports");
            
            if(response3.data!=null && response3.data.length > 0){
              response3.data.sort((a: { orderColumn: number; },b: { orderColumn: number; }) => {
                return a.orderColumn > b.orderColumn;
              }); 
              setshowSpecialRequirementTab3(true);
            } 
            setSporttableData9(response3.data);   

          }else{
            if(courceId){
              let alrequest = {              
                  "examIndexNo": courceId,               
              }
              const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
              sessionStorage.removeItem("token");
              sessionStorage.setItem("token",jwtToken.data);
              const response = await api.post(apiUrl + "/getSpecialCertificatesForCourse",alrequest);
              setTableData3(response.data); 
              if(response.data!=null && response.data.length > 0){
                  setshowSpecialRequirementTab3(true);
              }   
            }        
          } 
           
            
        } catch (error) {         
          console.error(error);       
        }
      };


      const  fetchSpecialExamForCourse4  = async (courceId:string) => {
         
        try {
            if(courceId){
                let alrequest = {              
                    "examIndexNo": courceId,               
                }
                const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
                sessionStorage.removeItem("token");
                sessionStorage.setItem("token",jwtToken.data);
            const response = await api.post(apiUrl + "/getSpecialCertificatesForCourse",alrequest);
            setTableData4(response.data); 
            if(response.data!=null && response.data.length > 0){
                setshowSpecialRequirementTab4(true);
            }   
          }        
            
        } catch (error) {         
          console.error(error);       
        }
      };

      
      const  fetchSpecialExamForCourse5  = async (courceId:string) => {
         
        try {
            if(courceId){
                let alrequest = {              
                    "examIndexNo": courceId,               
                }
                const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
                sessionStorage.removeItem("token");
                sessionStorage.setItem("token",jwtToken.data);
            const response = await api.post(apiUrl + "/getSpecialCertificatesForCourse",alrequest);
            setTableData5(response.data); 
            if(response.data!=null && response.data.length > 0){
                setshowSpecialRequirementTab5(true);
            }   
          }        
            
        } catch (error) {         
          console.error(error);       
        }
      };

      const  fetchSpecialExamForCourse6  = async (courceId:string) => {
         
        try {
            if(courceId){
                let alrequest = {              
                    "examIndexNo": courceId,               
                }
                const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
                sessionStorage.removeItem("token");
                sessionStorage.setItem("token",jwtToken.data);
            const response = await api.post(apiUrl + "/getSpecialCertificatesForCourse",alrequest);
            setTableData6(response.data); 
            if(response.data!=null && response.data.length > 0){
                setshowSpecialRequirementTab6(true);
            }   
          }        
            
        } catch (error) {         
          console.error(error);       
        }
      };


    function removeSelecedCourse1(){
      if(cousepref1==='43'){
        setShowGit(false);
        setGitExamIndextmp('');
        setGitExamYeartmp('');
      }
      setCousepref1('');
      setTableData1([]);
      setSelectedRows1([]);
      setAutoSelectedExamRows1([]);
      setSportSselectedRows1([]);
      setAutoSelectedRows1([]);
      setSportSselectedRows2([]);
      setAutoSelectedRows2([]);
      setSportSselectedRows3([]);
      setAutoSelectedRows3([]);    
      setSporttableData1([]);
      setSporttableData2([]);
      setSporttableData3([]);
      setShowSportAccordian(false);

      setshowSpecialRequirementTab1(false);
      setshowSpecialRequirementTab2(false);
      setshowSpecialRequirementTab3(false);

    }


    function removeSelecedCourse2(){
      if(cousepref2==='43'){
        setShowGit(false);
        setGitExamIndextmp('');
        setGitExamYeartmp('');
      }
      setCousepref2('');
      setTableData2([]);
      setSelectedRows2([]);
      setAutoSelectedExamRows2([]);      
      setSporttableData4([]);
      setSportSselectedRows4([]);
      setAutoSelectedRows4([]);      
       setSporttableData5([]);
      setSportSselectedRows5([]);
      setAutoSelectedRows5([]);
      
      setSporttableData6([]);
      setSportSselectedRows6([]);
      setAutoSelectedRows6([]);
      setShowSportAccordian2(false);

      setshowSpecialRequirementTab1(false);
      setshowSpecialRequirementTab2(false);
      setshowSpecialRequirementTab3(false);
 
    }


    function removeSelecedCourse3(){
      if(cousepref3==='43'){
        setShowGit(false);
        setGitExamIndextmp('');
        setGitExamYeartmp('');
      }
      setCousepref3('');
      setTableData3([]);
      setSelectedRows3([]);
      setAutoSelectedExamRows3([]);      
      setSporttableData7([]);
      setSportSselectedRows7([]);
      setAutoSelectedRows7([]);      
      setSporttableData8([]);
      setSportSselectedRows8([]);
      setAutoSelectedRows8([]);
      
      setSporttableData9([]);
      setSportSselectedRows9([]);
      setAutoSelectedRows9([]);
      setShowSportAccordian3(false);

      setshowSpecialRequirementTab1(false);
      setshowSpecialRequirementTab2(false);
      setshowSpecialRequirementTab3(false);
 
    }


     const handleCourse1Change = (event: SelectChangeEvent) => {


        if(event.target.value===cousepref2 || event.target.value===cousepref3){
          setShowError(true);
          setErrorMsg("Course Already Selected");
          return;
        }
       
        if(event.target.value)  {
          if(event.target.value==='43'){           
            setShowGit(true);
            setGitExamIndextmp(gitExamIndex);
            setGitExamYeartmp(gitExamYear);

          }
        } 
        setCousepref1(event.target.value as string);
        fetchSpecialExamForCourse1(event.target.value);
      };
 
 
      function showGitPopup(){
        if(gitExamYear){
           setShowRemoveGit(true);
         }else{
          setShowRemoveGit(false);
         } 
           setShowGit(true);
      }



      const handleCourse2Change = (event: SelectChangeEvent) => {

        if(event.target.value===cousepref1 || event.target.value===cousepref3){
          setShowError(true);
          setErrorMsg("Course Already Selected");
          return;
        }

        setCousepref2(event.target.value as string);
        fetchSpecialExamForCourse2(event.target.value);

        if(event.target.value)  {
          if(event.target.value==='43'){           
            setShowGit(true);
            setGitExamIndextmp(gitExamIndex);
            setGitExamYeartmp(gitExamYear);
          } 
        } 

      };

      const handleCourse3Change = (event: SelectChangeEvent) => {
        if(event.target.value===cousepref1 || event.target.value===cousepref2){
          setShowError(true);
          setErrorMsg("Course Already Selected");
          return;
        }

        setCousepref3(event.target.value as string);
        fetchSpecialExamForCourse3(event.target.value);
        if(event.target.value)  {
          if(event.target.value==='43'){           
            setShowGit(true);
            setGitExamIndextmp(gitExamIndex);
            setGitExamYeartmp(gitExamYear);
          } 
        } 
      };

      
    const handleErrorDialogClose = () => {
        setShowError(false);        
     };

     const handleGitDialogRemoveResult = () => {
        setShowGit(false);   
        setGitExamIndex("");
        setGitExamYear(""); 
        setGitExamIndextmp("");
        setGitExamYeartmp(""); 
         
     };


     const handleGitDialogSubmit = () => {

      console.log('handleGitDialogSubmit ',gitExamIndextmp );
      setShowGit(false);    
      setGitExamIndex(gitExamIndextmp);
      setGitExamYear(gitExamYeartmp); 
     };

     const handleGitDialogClose = () => {
      setShowGit(false);      
      setGitExamIndextmp(gitExamIndex);
      setGitExamYeartmp(gitExamYear); 

     };


     
      


     const handleAlertDialogClose = () => {
        setShowAlert(false);     
        setShowError(false);
     };

      const columns: GridColDef[] = [       
        { field: 'description', headerName: 'Certificate', width: 500 },
      ];
     

      const handleExamDialogClose1 = () => {
        setshowSpecialRequirementTab1(false);     
        console.log(selectedRows1);
     };



     const showAddedCertificates1=()=>{
      setshowSpecialRequirementTab1(true);
     }


     const handleExamDialogClose2 = () => {
      setshowSpecialRequirementTab2(false);     
      console.log(selectedRows2);
   };



   const showAddedCertificates2=()=>{
    setshowSpecialRequirementTab2(true);
   }


   const handleExamDialogClose3 = () => {
    setshowSpecialRequirementTab3(false);     
    console.log(selectedRows3);
 };

 const showAddedCertificates3=()=>{
  setshowSpecialRequirementTab3(true);
 }

 const handleExamDialogClose4 = () => {
  setshowSpecialRequirementTab4(false);     
  console.log(selectedRows4);
};

 const showAddedCertificates4=()=>{
  setshowSpecialRequirementTab4(true);
 }


 const handleExamDialogClose5= () => {
  setshowSpecialRequirementTab5(false);     
  console.log(selectedRows5);
};

 const showAddedCertificates5=()=>{
  setshowSpecialRequirementTab5(true);
 }

 const handleExamDialogClose6= () => {
  setshowSpecialRequirementTab6(false);     
  console.log(selectedRows6);
};

 const showAddedCertificates6=()=>{
  setshowSpecialRequirementTab6(true);
 }


 function navigationToPersonalInfo(){
  navigate("/personnalInfo");
}
 

 function navigateFromCourses(){    
  
  

   if(!estateChild){
    setShowError(true);
    setErrorMsg("Please mention whether you are a Child of an Estate Worker");
    window.scrollTo(0, 0);
    return;
   }

   if(estateChild==='true'){
    if(!applySripada){
      setShowError(true);
      setErrorMsg("Please mention whether you would like to apply for Sri Pada National College of Education as well");
      return;
    }
   }    

    if(!cousepref1){
      setShowError(true);
      setErrorMsg("Please Enter the Course Preference 1");
    }else{     
         

      console.log('navifgate from Courses gitExamYear  ',gitExamYear);
      console.log('navifgate from Courses gitExamYeartmp  ',gitExamYeartmp);
      

          if( (cousepref1 && cousepref2) && cousepref1===cousepref2 || (cousepref1 && cousepref3) && cousepref1===cousepref3 || (cousepref2 && cousepref3)  && cousepref2===cousepref3){
             setShowError(true);
             setErrorMsg("Duplicate Courses Selected ");
             return;
          }           

          const selectedCourses: Courses = {
            coursePreference1: cousepref1,
            coursePreference2: cousepref2,
            coursePreference3: cousepref3,       
            applySripada: applySripada,
            estateChild:estateChild,
            course1Certificates: selectedRows1,
            course2Certificates: selectedRows2,
            course3Certificates: selectedRows3,       
            course1SportCertificates:sportSselectedRows1,
            course2SportCertificates:sportSselectedRows2,
            course3SportCertificates:sportSselectedRows3,
            course4SportCertificates:sportSselectedRows4,
            course5SportCertificates:sportSselectedRows5,
            course6SportCertificates:sportSselectedRows6,
            course7SportCertificates:sportSselectedRows7,
            course8SportCertificates:sportSselectedRows8,
            course9SportCertificates:sportSselectedRows9,
            gitYear:gitExamYear,
            gitIndex:gitExamIndex,
          }   
           
            sessionStorage.setItem("COURSES",JSON.stringify(selectedCourses));         
            navigate("/summary");
   
    
    }


      
      
 }
 const DemoPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: 80,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
}));



const handleToggle = (value: any) => () => {
  

  const currentIndex = checked.indexOf(value.id);
  const newChecked = [...checked];
  if (currentIndex === -1) {
    newChecked.push(value.id);
  } else {
    newChecked.splice(currentIndex, 1);
  }
  setChecked(newChecked);
  
};

const classes = {
  root: {
    flexGrow: 1
  },
  paper: {
    padding: 20,
    textAlign: "center",
    color: "blue",
    fontFamily: "Roboto"
  }
};


  return (

    <>
        <CssBaseline />
         <Header/>   
        <IconBreadcrumbs currentPage={"COURSES"}></IconBreadcrumbs>  




   <Dialog open={showGit}   keepMounted
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                  
              },
            },
          }}
          
          >
          <DialogTitle style={{ backgroundColor: 'blue', color: 'white' }}> <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} > GIT Examination Details </Typography></DialogTitle>
           <DialogContent dividers>
           <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               <Grid item xs={12}>
                     <Typography sx={{fontWeight:'bold',  fontSize: {lg: 14, md: 13,sm: 12,xs: 12} }}  style={{marginTop:'15px',color:'black'}} >
                            If you have done the GIT examination, Please Enter following Details( If you do not have the Index Number, Please enter the Exam Year )
                      </Typography>
                </Grid>

                <Grid item xs={12}>
                     <Typography sx={{fontWeight:'bold',  fontSize: {lg: 14, md: 13,sm: 12,xs: 12} }}  style={{marginTop:'15px',color:'black'}} >
                                Exam Year
                      </Typography>
                </Grid>
                
                <Grid item xs={12}>
                     <Typography style={{marginTop:'15px',color:'black'}} >
                          <FormControl fullWidth size="small">                  
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={gitExamYeartmp}
                                label="Git ExamYear"
                                onChange={handleYearChange}>
                                      <MenuItem value="">
                                      <em>Select</em>
                                      </MenuItem>
                                      <MenuItem value={'2023'}>2023</MenuItem>
                                      <MenuItem value={'2022'}>2022</MenuItem>
                                      <MenuItem value={'2021'}>2021</MenuItem>
                                      <MenuItem value={'2020'}>2020</MenuItem>
                                      <MenuItem value={'2019'}>2019</MenuItem>
                                      <MenuItem value={'2018'}>2018</MenuItem>
                                      <MenuItem value={'2017'}>2017</MenuItem> 
                                      <MenuItem value={'2016'}>2016</MenuItem> 
                                      <MenuItem value={'2015'}>2015</MenuItem> 
                              </Select>
                        </FormControl>
                      </Typography>
                </Grid>

               { gitExamIndextmp!='Result Pending' && <Grid item xs={12}>
                     <Typography sx={{fontWeight:'bold',  fontSize: {lg: 14, md: 13,sm: 12,xs: 12} }}  style={{marginTop:'15px',color:'black'}} >
                             Exam Index Number
                      </Typography>
                </Grid>}

                <Grid item xs={12}>
                       
                       <TextField 
                       disabled={gitExamIndextmp==='Result Pending'}
                       value={gitExamIndextmp}   
                       onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }}}
                         onChange={(event) => {
                            setGitExamIndextmp(event.target.value);                                
                         }                        
                        }  
                        
                           inputProps={{maxLength: 10 }}   
                           label="" type="text" size='small' color='primary' />

                </Grid>
                
              </Grid>

           </DialogContent> 
        
          <DialogActions>
              {showRemoveGit && 
              
              <Button      variant="contained" autoFocus onClick={handleGitDialogRemoveResult}>
                 <Typography   sx={{fontSize: {lg: 14, md: 13,sm: 12,xs: 12} }}    >
                    Remove Results
                 </Typography>      
              </Button>  }
               <Button variant="contained" autoFocus onClick={handleGitDialogClose}>
                    <Typography sx={{fontSize: {lg: 14, md: 13,sm: 12,xs: 12} }}   >
                        Close
                    </Typography>
              </Button> 

              <Button variant="contained" autoFocus onClick={handleGitDialogSubmit}>
                    <Typography sx={{fontSize: {lg: 14, md: 13,sm: 12,xs: 12} }}   >
                        Submit
                    </Typography>
              </Button> 

          </DialogActions>
      </Dialog>

      <Dialog open={showAlert}   keepMounted
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              minWidth: "500px",  
            },
          },
        }}
          
          >
          <DialogTitle style={{ backgroundColor: 'blue', color: 'white' }}> Alert</DialogTitle>
           <DialogContent dividers>
              <p > {errorMsg} </p>
           </DialogContent> 
        
          <DialogActions>
              <Button variant="contained" autoFocus onClick={handleAlertDialogClose}>
                    Ok
              </Button>        
      </DialogActions>
      </Dialog>




<Dialog open={showError}   keepMounted
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                
              },
            },
          }}
          
          >
          <DialogTitle  sx={{fontSize: {lg: 16, md: 14,sm: 14,xs: 14} }} style={{ backgroundColor: 'red', color: 'white' }}>
            
             Error
             
             </DialogTitle>
           <DialogContent sx={{fontSize: {lg: 16, md: 14,sm: 14,xs: 14} }} dividers>
            
               {errorMsg} 
         
           </DialogContent> 
        
          <DialogActions style={{justifyContent: 'center'}}>
            
              <Button  sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} variant="contained" autoFocus onClick={handleErrorDialogClose}>
                    Ok
              </Button>  
                
      </DialogActions>
      </Dialog>

      <Dialog open={showAlert}   keepMounted
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              minWidth: "500px",  
            },
          },
        }}
          
          >
          <DialogTitle style={{ backgroundColor: 'blue', color: 'white' }}> Alert</DialogTitle>
           <DialogContent sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} dividers>
               {errorMsg} 
           </DialogContent> 
        
          <DialogActions style={{justifyContent: 'center'}}>
              <Button sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} variant="contained" autoFocus onClick={handleAlertDialogClose}>
                    Ok
              </Button>        
      </DialogActions>
      </Dialog>


  
 
    <Box
            sx={{border: '0' }}
            justifyContent="center"
            alignItems="center"
         
            
            >
               <AppBar elevation={0}  style={{ background: '#D2DCF0' }}  sx={{marginTop:'1px',marginBottom:'40px'}} position="static">
                    <Box display='flex' flexGrow={1}>
                        <Typography  margin={'8px'} sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'500',color:'black' }}>7. Details for Sripada NCOE</Typography>
                    </Box>
                </AppBar>  
              <Card   sx={{border: '0' }} variant="outlined" color="primary" invertedColors >
              

              

              <Grid  sx={{border: '0' }} container>               
                        
                        
                         <Grid item xs={12}>   
                         <center>
                            <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }} style={{marginTop:'15px',color:'red'}} >
                            (* Read the conditions in <b>'6.3 Selection for Sripada National College of Education'</b> of the gazette notification carefully and fill this accordingly.)  
                            </Typography>
                            </center>
                          </Grid>
                           

                          <Grid item xs={12}>  
                            <Grid marginTop={'30px'} item xs={12}>   
                                <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }} style={{marginTop:'15px',color:'black'}} >
                                    Are you a child of an estate worker?
                                </Typography>
                              </Grid>
                            <Grid marginTop={'30px'}  item xs={6}>                          
                                    <FormControl style={{width: 400}} variant="standard"  >
                                      <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={estateChild}
                                        onChange={handleEstateChildChange}>                          
                                        <MenuItem value={'true'}>Yes</MenuItem>
                                        <MenuItem value={'false'}>No</MenuItem>
                                        </Select>
                                    </FormControl>
                            </Grid> 
                         </Grid>



                       
                         {estateChild ==='true' &&  
                         <Grid style={{marginTop:'15px'}} item xs={12}>   
                            <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }} style={{marginTop:'15px',color:'black'}}>
                               Do you wish to Apply for the Sripada National College of Education ?
                            </Typography>
                          </Grid>}
                         { estateChild ==='true' && <Grid marginTop={'30px'} item xs={6}>                          
                                <FormControl style={{width: 400}} variant="standard">                                   
                                    <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={applySripada}
                                    onChange={handleSriPadaChange}
                                     >                          
                                    <MenuItem value={'true'}>Yes</MenuItem>
                                    <MenuItem value={'false'}>No</MenuItem>
                                    </Select>
                                </FormControl>
                         </Grid>} 
                     </Grid>  




                     <AppBar elevation={0}   style={{ background: '#D2DCF0' }}  sx={{marginTop:'1px',marginBottom:'40px'}} position="static">
                    <Box display='flex' flexGrow={1}>
                        <Typography  margin={'8px'} sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'500',color:'black' }}>8. Select Your Courses</Typography>
                    </Box>
                </AppBar>  

                  {/*   <Box fontWeight='bold' display='inline'  
                            justifyContent="center"
                            alignItems="center">
                              <center>
                              <Typography style={{width: '100%',marginTop:'20px',backgroundColor: '#e5e7e9',paddingTop: "2vh",paddingBottom: "2vh",paddingLeft: "10px",paddingRight: "5px" }}  sx={{fontWeight:'bold',  fontSize: {lg: 18, md: 15,sm: 13,xs: 13} }}   gutterBottom align="justify"    >
                                  SELECT YOUR COURSES
                              </Typography>
                              </center>
                    </Box> */}


                    <Box fontWeight='bold' display='inline'  
                            justifyContent="center"
                            alignItems="center">
                              <center>

                              <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }} style={{width: '100%',color: 'red',marginTop:'10px',paddingTop: "2vh",paddingBottom: "4vh",paddingLeft: "10px",paddingRight: "5px" }}>
                                  NOTE : Please read the course requirements mentioned in the government gazette notification under each course ( Section 4.1 - 4.28)
                              </Typography>


                
                              </center>
                    </Box>






                    <Grid container rowSpacing={1} > 
                         <Grid item xs={12}>
                         <div style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                              }}>
                                  <SvgIcon component={SchoolSharpIcon} inheritViewBox > </SvgIcon>
                                  <Typography sx={{fontSize: {lg: 18, md: 16,sm: 15,xs: 15},marginLeft:'5px', color: "#00adb5",fontWeight:'bold' }}  >
                                   Course Preference 1
                                </Typography>
                          </div> 

                              
                             
                                <Grid>
                                <FormControl style={{width: '90%',marginTop:'20px'}} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                   
                                    <Select   onChange={handleCourse1Change} labelId="countrySelectLabel" id="countrySelect" value={cousepref1}>
                                        {courses.map(({code, courserName}, index) => (
                                            <MenuItem key={index} value={code}>
                                            {courserName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>                                
                                {cousepref1 ==='43' && <Button onClick={showGitPopup}   startIcon={<OpenInNewSharpIcon />}>
                                           <Typography sx={{fontSize: {lg: 14, md: 13,sm: 12,xs: 12},  }}  >
                                              Add/Edit (GIT) Exam Details
                                            </Typography>  
                                          </Button>}
                               {cousepref1 && <Button   onClick={removeSelecedCourse1}   startIcon={<Delete />}  >
                                  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13},color:'red',textTransform: 'none'  }}  >  
                                        Remove Course Preference 1
                                  </Typography>

                               </Button>}
                                </Grid>
                                {tableData1.length >0 && 
                                <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }} >
                                   If you have following certificates, Please Specify
                                </Typography>}

                                {tableData1.length >0 && <div style={{ width: '100%' }}>
                                      <DataGrid                                        
                                          getRowHeight={() => 'auto'}
                                          rows={tableData1}
                                          checkboxSelection
                                          hideFooterPagination
                                          hideFooterSelectedRowCount
                                          columnHeaderHeight={0}
                                          rowSelectionModel={autoSelectedExamRows1}                                          
                                          onRowSelectionModelChange={(ids) => {
                                            const selectedIDs = new Set(ids);
                                            const selectedRows = tableData1.filter((row: any) =>
                                              selectedIDs.has(row.id)
                                            );
                                            setSelectedRows1(selectedRows);
                                            const arr:string[]=[];
                                            selectedRows.forEach(((element:any)=> {
                                              arr.push(element.id);
                                            }))
                                            setAutoSelectedExamRows1(arr);
                                          }}        
                                          columns={[{field: 'description',flex: 1,minWidth: 150, headerName: ''}]}
                                          
                                      />
                                      </div>}

                                    {showSportAccordian &&  <div>
                                      <Accordion    >
                                        <AccordionSummary 
                                          expandIcon={<ArrowDownward />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            backgroundColor: "#3498db"
                                          
                                          }}
                                        >
                                        <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }} >Click here to Add Certificates in Athletics/Chess/Table Tennis/Gymnastics/Boxing/Weight Lifting/Cycling/Judo/Wushu/Tae-Kon-Do/Wrestling/Karate Do/Kabaddi</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {sporttableData1.length >0  &&
                                           <DataGrid sx={{ overflowX: 'scroll'  }}   
                                              getRowHeight={() => 'auto'}
                                                                      
                                              rows={sporttableData1}
                                              checkboxSelection
                                              hideFooterPagination
                                              hideFooterSelectedRowCount
                                              columnHeaderHeight={0}
                                              rowSelectionModel={autoSelectedRows1}
                                              onRowSelectionModelChange={(ids) => {
                                                const selectedIDs = new Set(ids);
                                                const selectedRows = sporttableData1.filter((row: any) =>
                                                  selectedIDs.has(row.id)
                                                );
                                                setSportSselectedRows1(selectedRows);
                                                const arr:string[]=[];
                                                selectedRows.forEach(((element:any)=> {
                                                  arr.push(element.id);
                                                }))
                                                setAutoSelectedRows1(arr);  

                                              }}
                                              columns={[
                                                {
                                                  field: 'description',
                                                  flex: 1,
                                                  minWidth: 150,
                                                  headerName: '' 
                                                                                              } 
                                              
                                              ]}
                                          
                                    
                                      />
                                     } 



                                        </AccordionDetails>
                                      </Accordion>



                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ArrowDownward />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            backgroundColor: "#5dade2"
                                          }}
                                        >
                                          <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}>Click here to Add Certificates in Vollyball/Netball/Football/Baseball/Cricket/Hockey/Rugby/Throw Ball/Basket Ball/Tennis/Carom/Hand Ball/Elle/Badminton/Aquatic sports/Physical Exercises</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {sporttableData2.length >0 && <div style={{ width: '100%' }}>
                                        <Box sx={{ height: 300  }}>
                                          <DataGrid
                                             getRowHeight={() => 'auto'}
                                              rows={sporttableData2}
                                              checkboxSelection
                                              hideFooterPagination
                                              hideFooterSelectedRowCount
                                              columnHeaderHeight={0}
                                              rowSelectionModel={autoSelectedRows2}
                                              onRowSelectionModelChange={(ids) => {
                                                const selectedIDs = new Set(ids);
                                                const selectedRows = sporttableData2.filter((row: any) =>
                                                  selectedIDs.has(row.id)
                                                );
                                                setSportSselectedRows2(selectedRows);
                                                const arr:string[]=[];
                                                selectedRows.forEach(((element:any)=> {
                                                  arr.push(element.id);
                                                }))
                                                setAutoSelectedRows2(arr);
                                              }}
                                             // columns={columns}    
                                              
                                              columns={[
                                                {
                                                  field: 'description',
                                                  flex: 1,
                                                  minWidth: 150,
                                                  headerName: '' 
                                                                                              } 
                                              
                                              ]}
                                          
                                      />
                                      </Box>
                                      </div>}
                                        </AccordionDetails>
                                      </Accordion>


                  


                                      
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ArrowDownward />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            backgroundColor: "#85c1e9"
                                          }}
                                        >
                                          <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}>Click here to Add Certificates in Other Sports</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {sporttableData3.length >0 && <div style={{ width: '100%' }}>
                                        <Box sx={{ height: 300  }}>
                                          <DataGrid
                                              getRowHeight={() => 'auto'}
                                              rows={sporttableData3}
                                              checkboxSelection
                                              hideFooterPagination
                                              hideFooterSelectedRowCount
                                              columnHeaderHeight={0}
                                              rowSelectionModel={autoSelectedRows3}
                                              onRowSelectionModelChange={(ids) => {
                                                const selectedIDs = new Set(ids);
                                                const selectedRows = sporttableData3.filter((row: any) =>
                                                  selectedIDs.has(row.id)
                                                );
                                                setSportSselectedRows3(selectedRows);
                                                const arr:string[]=[];
                                                selectedRows.forEach(((element:any)=> {
                                                  arr.push(element.id);
                                                }))
                                                setAutoSelectedRows3(arr);
                                              }}
                                             // columns={columns}    
                                              
                                              columns={[
                                                {
                                                  field: 'description',
                                                  flex: 1,
                                                  minWidth: 150,
                                                  headerName: '' 
                                                                                              } 
                                              
                                              ]}
                                          
                                      />
                                      </Box>
                                      </div>}
                                        </AccordionDetails>
                                      </Accordion>



                                      </div>}


                                     
                         </Grid> 

                         <Box sx={{ m: 2 }} />
                         <Grid marginTop={'10px'} item xs={12} sx={{ color: "#00adb5" }}>
                            <div style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                  }}>
                                      <SvgIcon component={SchoolSharpIcon} inheritViewBox > </SvgIcon>
                                      <Typography sx={{fontSize: {lg: 18, md: 16,sm: 15,xs: 15},marginLeft:'5px', color: "#00adb5",fontWeight:'bold' }}  >
                                      Course Preference 2
                                    </Typography>
                              </div> 
                                <Grid>
                                  <FormControl style={{width: '90%',marginTop:'20px'}} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                     
                                    <Select  onChange={handleCourse2Change} labelId="countrySelectLabel" id="countrySelect" value={cousepref2}>
                                        {courses.map(({code, courserName}, index) => (
                                            <MenuItem key={index} value={code}>
                                            {courserName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                  </FormControl>
                                  {cousepref2 ==='43' && <Button onClick={showGitPopup}   endIcon={<OpenInNewSharpIcon />}>
                                           <Typography sx={{fontSize: {lg: 14, md: 13,sm: 12,xs: 12},  }}  >
                                              Add/Edit (GIT) Exam Details
                                            </Typography>  
                                          </Button>}

                                {cousepref2 &&  <Button onClick={removeSelecedCourse2}   startIcon={<Delete />}  >
                                      <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13},color:'red',textTransform: 'none'  }}  >  
                                        Remove Course Preference 2
                                     </Typography>
                                   </Button>}

                                 </Grid>

                                {tableData2.length >0 && <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}>
                                   If you have following certificates, Please Specify
                                </Typography>}
                                {tableData2.length >0 &&  <div style={{width: '100%' }}>
                                    <DataGrid
                                        getRowHeight={() => 'auto'}
                                        rows={tableData2}
                                        checkboxSelection
                                        hideFooterPagination
                                        columnHeaderHeight={0}
                                        hideFooterSelectedRowCount
                                        rowSelectionModel={autoSelectedExamRows2}
                                        onRowSelectionModelChange={(ids) => {
                                          const selectedIDs = new Set(ids);
                                          const selectedRows = tableData2.filter((row: any) =>
                                            selectedIDs.has(row.id)
                                          );
                                          setSelectedRows2(selectedRows);
                                          const arr:string[]=[];
                                          selectedRows.forEach(((element:any)=> {
                                            arr.push(element.id);
                                          }))
                                          setAutoSelectedExamRows2(arr);
                                          
                                        }}
                                        columns={[{field: 'description',flex: 1,minWidth: 150, headerName: ''}]}
                                       
                                                        
                                    />
                                </div>}

                                {showSportAccordian2 &&  <div>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ArrowDownward />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            backgroundColor: "#3498db"
                                          }}
                                        >
                                          <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}>Click here to Add Certificates in Athletics/Chess/Table Tennis/Gymnastics/Boxing/Weight Lifting/Cycling/Judo/Wushu/Tae-Kon-Do/Wrestling/Karate Do/Kabaddi</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {sporttableData4.length >0 && <div style={{ width: '100%' }}>
                                        <Box sx={{ height: 300  }}>
                                          <DataGrid
                                             getRowHeight={() => 'auto'}
                                              rows={sporttableData4}
                                              checkboxSelection
                                              hideFooterPagination
                                              hideFooterSelectedRowCount
                                              columnHeaderHeight={0}
                                              rowSelectionModel={autoSelectedRows4}
                                              onRowSelectionModelChange={(ids) => {
                                                const selectedIDs = new Set(ids);
                                                const selectedRows = sporttableData4.filter((row: any) =>
                                                  selectedIDs.has(row.id)
                                                );
                                                setSportSselectedRows4(selectedRows);
                                                const arr:string[]=[];
                                                selectedRows.forEach(((element:any)=> {
                                                  arr.push(element.id);
                                                }))
                                                setAutoSelectedRows4(arr);
                                              }}
                                             // columns={columns}    
                                              
                                              columns={[
                                                {
                                                  field: 'description',
                                                  flex: 1,
                                                  minWidth: 150,
                                                  headerName: ''
                                                                                         } 
                                              
                                              ]}
                                          
                                      />
                                      </Box>
                                      </div>}
                                        </AccordionDetails>
                                      </Accordion>



                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ArrowDownward />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            backgroundColor: "#5dade2"
                                          }}
                                        >
                                          <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}>Click here to Add Certificates in Vollyball/Netball/Football/Baseball/Cricket/Hockey/Rugby/Throw Ball/Basket Ball/Tennis/Carom/Hand Ball/Elle/Badminton/Aquatic sports/Physical Exercises</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {sporttableData5.length >0 && <div style={{ width: '100%' }}>
                                        <Box sx={{ height: 300  }}>
                                          <DataGrid
                                             getRowHeight={() => 'auto'}
                                              rows={sporttableData5}
                                              checkboxSelection
                                              hideFooterPagination
                                              hideFooterSelectedRowCount
                                              columnHeaderHeight={0}
                                              rowSelectionModel={autoSelectedRows5}
                                              onRowSelectionModelChange={(ids) => {
                                                const selectedIDs = new Set(ids);
                                                const selectedRows = sporttableData5.filter((row: any) =>
                                                  selectedIDs.has(row.id)
                                                );
                                                setSportSselectedRows5(selectedRows);
                                                const arr:string[]=[];
                                                selectedRows.forEach(((element:any)=> {
                                                  arr.push(element.id);
                                                }))
                                                setAutoSelectedRows5(arr);
                                              }}
                                             // columns={columns}    
                                              
                                              columns={[
                                                {
                                                  field: 'description',
                                                  flex: 1,
                                                  minWidth: 150,
                                                  headerName: '' 
                                                                                              } 
                                              
                                              ]}
                                          
                                      />
                                      </Box>
                                      </div>}
                                        </AccordionDetails>
                                      </Accordion>


                  


                                      
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ArrowDownward />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            backgroundColor: "#85c1e9"
                                          }}
                                        >
                                          <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}>Click here to Add Certificates in Other Sports</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {sporttableData6.length >0 && <div style={{ width: '100%' }}>
                                        <Box sx={{ height: 300  }}>
                                          <DataGrid
                                             getRowHeight={() => 'auto'}
                                              rows={sporttableData6}
                                              checkboxSelection
                                              hideFooterPagination
                                              hideFooterSelectedRowCount
                                              columnHeaderHeight={0}
                                              rowSelectionModel={autoSelectedRows6}
                                              onRowSelectionModelChange={(ids) => {
                                                const selectedIDs = new Set(ids);
                                                const selectedRows = sporttableData6.filter((row: any) =>
                                                  selectedIDs.has(row.id)
                                                );
                                                setSportSselectedRows6(selectedRows);
                                                const arr:string[]=[];
                                                selectedRows.forEach(((element:any)=> {
                                                  arr.push(element.id);
                                                }))
                                                setAutoSelectedRows6(arr);

                                              }}
                                                 
                                              
                                              columns={[
                                                {
                                                  field: 'description',
                                                  flex: 1,
                                                  minWidth: 150,
                                                  headerName: '' 
                                                                                              } 
                                              
                                              ]}
                                          
                                      />
                                      </Box>
                                      </div>}
                                        </AccordionDetails>
                                      </Accordion>



                                      </div>}



                             
                         </Grid>   

                         <Box sx={{ m: 2 }} />

                         <Grid marginTop={'20px'} item xs={12}>
                              <div style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                  }}>
                                      <SvgIcon component={SchoolSharpIcon} inheritViewBox > </SvgIcon>
                                      <Typography sx={{fontSize: {lg: 18, md: 16,sm: 15,xs: 15},marginLeft:'5px', color: "#00adb5",fontWeight:'bold' }}  >
                                      Course Preference 3
                                    </Typography>
                              </div> 
                               <Grid>
                                <FormControl style={{width: '90%',marginTop:'10px'}} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                      <Select  onChange={handleCourse3Change} labelId="countrySelectLabel" id="countrySelect" value={cousepref3}>
                                          {courses.map(({code, courserName}, index) => (
                                              <MenuItem key={index} value={code}>
                                              {courserName}
                                              </MenuItem>
                                          ))}
                                      </Select>                                  
                                  </FormControl>
                                     
                                        {cousepref3 ==='43' && <Button onClick={showGitPopup}   endIcon={<OpenInNewSharpIcon />}>
                                           <Typography sx={{fontSize: {lg: 14, md: 13,sm: 12,xs: 12},  }}  >
                                              Add/Edit (GIT) Exam Details
                                            </Typography>  
                                          </Button>}
                                         {cousepref3 && <Button onClick={removeSelecedCourse3}   startIcon={<Delete />}  >
                                              <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13},color:'red',textTransform: 'none'  }}  >  
                                                Remove Course Preference 3
                                            </Typography>
                                          </Button>}
                                     
                                </Grid>                              
 
 
                                {tableData3.length >0 &&  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}>
                                   If you have following certificates, Please Specify
                                </Typography>}
                                {tableData3.length >0 && <div style={{width: '100%' }}>
                                  <DataGrid
                                      getRowHeight={() => 'auto'}
                                      rows={tableData3}
                                      checkboxSelection
                                      hideFooterPagination
                                      columnHeaderHeight={0}
                                      hideFooterSelectedRowCount
                                      rowSelectionModel={autoSelectedExamRows3}
                                      onRowSelectionModelChange={(ids) => {
                                        const selectedIDs = new Set(ids);
                                        const selectedRows = tableData3.filter((row: any) =>
                                          selectedIDs.has(row.id)
                                        );
                                        setSelectedRows3(selectedRows);
                                        const arr:string[]=[];
                                        selectedRows.forEach(((element:any)=> {
                                          arr.push(element.id);
                                        }))
                                        setAutoSelectedExamRows3(arr);
                                      }}
                                      columns={[
                                        {
                                          field: 'description',
                                          flex: 1,
                                          minWidth: 150,
                                          headerName: '' 
                                                                                      } 
                                      
                                      ]}                        
                                  />
                                  </div>}


                                  {showSportAccordian3 &&  <div>
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ArrowDownward />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            backgroundColor: "#3498db"
                                          }}
                                        >
                                          <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}>Click here to Add Certificates in Athletics/Chess/Table Tennis/Gymnastics/Boxing/Weight Lifting/Cycling/Judo/Wushu/Tae-Kon-Do/Wrestling/Karate Do/Kabaddi</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {sporttableData7.length >0 && <div style={{ width: '100%' }}>
                                        <Box sx={{ height: 300  }}>
                                          <DataGrid
                                              getRowHeight={() => 'auto'}
                                              rows={sporttableData7}
                                              checkboxSelection
                                              hideFooterPagination
                                              hideFooterSelectedRowCount
                                              columnHeaderHeight={0}
                                              rowSelectionModel={autoSelectedRows7}
                                              onRowSelectionModelChange={(ids) => {
                                                const selectedIDs = new Set(ids);
                                                const selectedRows = sporttableData7.filter((row: any) =>
                                                  selectedIDs.has(row.id)
                                                );
                                                setSportSselectedRows7(selectedRows);
                                                const arr:string[]=[];
                                                selectedRows.forEach(((element:any)=> {
                                                  arr.push(element.id);
                                                }))
                                                setAutoSelectedRows7(arr);
                                              }}
                                            
                                              
                                              columns={[
                                                {
                                                  field: 'description',
                                                  flex: 1,
                                                  minWidth: 150,
                                                  headerName: '' 
                                                                                              } 
                                              
                                              ]}
                                          
                                      />
                                      </Box>
                                      </div>}
                                        </AccordionDetails>
                                      </Accordion>



                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ArrowDownward />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            backgroundColor: "#5dade2"
                                          }}
                                        >
                                          <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}>Click here to Add Certificates in Vollyball/Netball/Football/Baseball/Cricket/Hockey/Rugby/Throw Ball/Basket Ball/Tennis/Carom/Hand Ball/Elle/Badminton/Aquatic sports/Physical Exercises</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {sporttableData8.length >0 && <div style={{ width: '100%' }}>
                                        <Box sx={{ height: 300  }}>
                                          <DataGrid
                                              getRowHeight={() => 'auto'}
                                              rows={sporttableData8}
                                              checkboxSelection
                                              hideFooterPagination
                                              hideFooterSelectedRowCount
                                              columnHeaderHeight={0}
                                              rowSelectionModel={autoSelectedRows8}
                                              onRowSelectionModelChange={(ids) => {
                                                const selectedIDs = new Set(ids);
                                                const selectedRows = sporttableData8.filter((row: any) =>
                                                  selectedIDs.has(row.id)
                                                );
                                                setSportSselectedRows8(selectedRows);
                                                const arr:string[]=[];
                                                selectedRows.forEach(((element:any)=> {
                                                  arr.push(element.id);
                                                }))
                                                setAutoSelectedRows8(arr);

                                              }}
                                            
                                              
                                              columns={[
                                                {
                                                  field: 'description',
                                                  flex: 1,
                                                  minWidth: 150,
                                                  headerName: '' 
                                                                                              } 
                                              
                                              ]}
                                          
                                      />
                                      </Box>
                                      </div>}
                                        </AccordionDetails>
                                      </Accordion>


                  


                                      
                                      <Accordion>
                                        <AccordionSummary
                                          expandIcon={<ArrowDownward />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          sx={{
                                            backgroundColor: "#85c1e9"
                                          }}
                                        >
                                          <Typography sx={{fontSize: {lg: 15, md: 14,sm: 13,xs: 13} }}>Click here to Add Certificates in Other Sports</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        {sporttableData9.length >0 && <div style={{ width: '100%' }}>
                                        <Box sx={{ height: 300  }}>
                                          <DataGrid
                                              getRowHeight={() => 'auto'}
                                              rows={sporttableData9}
                                              checkboxSelection
                                              hideFooterPagination
                                              hideFooterSelectedRowCount
                                              columnHeaderHeight={0}
                                              rowSelectionModel={autoSelectedRows9}
                                              onRowSelectionModelChange={(ids) => {
                                               
                                                const selectedIDs = new Set(ids);
                                                const selectedRows = sporttableData9.filter((row: any) =>
                                                  selectedIDs.has(row.id)
                                                );
                                                setSportSselectedRows9(selectedRows);
                                               
                                                const arr:string[]=[];
                                                selectedRows.forEach(((element:any)=> {
                                                  arr.push(element.id);
                                                }))
                                                setAutoSelectedRows9(arr);

                                              }}
                                                 
                                              
                                              columns={[
                                                {
                                                  field: 'description',
                                                  flex: 1,
                                                  minWidth: 150,
                                                  headerName: '' 
                                                                                              } 
                                              
                                              ]}
                                          
                                      />
                                      </Box>
                                      </div>}
                                        </AccordionDetails>
                                      </Accordion>



                                      </div>}


                          </Grid> 
                    </Grid>

                   

                    <Grid container   marginBottom={'15px'}
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center" >
                    <BlueColorButton  sx={(theme) => ({
                        [theme.breakpoints.down("sm")]: {
                          minWidth: 32,
                          ".MuiButton-startIcon": { m: 0 }
                        }
                      })}  variant="contained" onClick={navigateFromCourses} endIcon={<SendIcon />}>
                      Continue
                    </BlueColorButton>
                    </Grid>


                  
                </Card>  

                
   </Box>
 

            




      </>



    
         

    
  )
}