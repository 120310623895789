import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, ButtonProps, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField  } from "@mui/material";
import React from "react";
import './../App.css';
 
import Card from '@mui/joy/Card';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios, { AxiosError } from "axios";
import {useEffect, useState} from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import Header from "../common/Header";
import Download from '@mui/icons-material/Download';
import IconBreadcrumbs from "../common/BreadCrumb";
import { blue } from "@mui/material/colors";
import { ArrowBackIos, Save } from "@mui/icons-material";
import api from "../common/ApiInterceptor";
 
 



export default function Summary() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

   
   const apiUrl = process.env.REACT_APP_API_URL;
   let tokenRequest = {
    "username": process.env.REACT_APP_TOKEN_USERNAME,
  }


  
    const personalInfo = sessionStorage.getItem("PERSONAL_INFO")
    const courses = sessionStorage.getItem("COURSES")



    const [alYear, setalYear] = React.useState('');
    const [alIndexNumber, setalIndexNumber] = React.useState('');
    const [alNICNumber, setalNICNumber] = React.useState('');
    const [olAttempt1Year, setolAttempt1Year] = React.useState('');
    const [olAttempt1Index, setolAttempt1Index] = React.useState('');
    const [olAttempt2Year, setolAttempt2Year] = React.useState('');
    const [olAttempt2Index, setolAttempt2Index] = React.useState('');
    const [olAttempt3Year, setolAttempt3Year] = React.useState('');
    const [olAttempt3Index, setolAttempt3Index] = React.useState('');
    const [olType, setolType] = React.useState('');
    const [personnalData, setpersonnalData] = useState(personalInfo ? JSON.parse(personalInfo): "");
    const [genderString, setgenderString] = React.useState('');
    const [titleString, settitleString] = React.useState('');
    const [raceString, setraceString] = React.useState('');
    const [courseData, setcourseData] = useState(courses ? JSON.parse(courses): "");
    const [coursesList, setcoursesList] = React.useState([]);
    const [course1String, secourse1String] = React.useState('');
    const [course2String, secourse2String] = React.useState('');
    const [course3String, secourse3String] = React.useState('');


    const [course4String, secourse4String] = React.useState('');
    const [course5String, secourse5String] = React.useState('');
    const [course6String, secourse6String] = React.useState('');
    const [showSriPada, setshowSriPada] = React.useState(false);
    const [showAlert, setshowAlert] = React.useState(false);
    const [alertMsg, setalertMsg] = React.useState('');
    const [applicationNo, setapplicationNo] = React.useState('');
    const [showError, setshowError] = React.useState(false);
    const navigate = useNavigate();
    const [gitExamYear, setGitExamYear] =React.useState('');
    const [gitExamIndex, setGitExamIndex] =React.useState('');

    const [disableSubmit, setDisableSubmit] = React.useState(false);

    const classes = {
      root: {
        flexGrow: 1
      },
      paper: {
        padding: 20,
        textAlign: "center",
        color: "blue",
        fontFamily: "Roboto"
      }
    };
    
    useEffect(() => {

        setalIndexNumber(sessionStorage.getItem('AL_INDEX') as string);
        setalYear(sessionStorage.getItem('AL_YEAR') as string);
        setalNICNumber(sessionStorage.getItem('AL_NIC') as string);
        setolAttempt1Year(sessionStorage.getItem('OL_ATTEMP1_YEAR') as string);
        setolAttempt1Index(sessionStorage.getItem('OL_ATTEMP1_INDEX') as string);
        setolAttempt2Year(sessionStorage.getItem('OL_ATTEMP2_YEAR') as string);
        setolAttempt2Index(sessionStorage.getItem('OL_ATTEMP2_INDEX') as string);
        setolAttempt3Year(sessionStorage.getItem('OL_ATTEMP3_YEAR') as string);
        setolAttempt3Index(sessionStorage.getItem('OL_ATTEMP3_INDEX') as string);
        setolType(sessionStorage.getItem('OL_TYPE') as string);


        if(personnalData.gender==="M"){
           setgenderString("Male");
        }else{
          setgenderString("Female");
        }
        if(personnalData.title==="MR"){
          settitleString("Mr");
        }else{
          if(personnalData.title==="MS"){
            settitleString("Miss");
          }else{
            settitleString("Rev.");
          }  
        }  
        if(personnalData.race==="SI"){
          setraceString("Sinhala");
        }else{
          if(personnalData.race==="ST"){
            setraceString("Sri Lankan Tamil");
          }else{
            if(personnalData.race==="SM"){
              setraceString("Tamil of Indian Origin");
            }else{
              if(personnalData.race==="MU"){
                setraceString("Muslim");
              }else{
                if(personnalData.race==="CA"){
                  setraceString("Catholic");
                }else{
                  if(personnalData.race==="CH"){
                    setraceString("Christian");
                  }else{
                    if(personnalData.race==="OT"){
                      setraceString("Other");
                    } 
                  } 
                }  
              }  
            }  
              
          }


        }
       
   
      
          setGitExamYear(courseData.gitYear);
          setGitExamIndex(courseData.gitIndex);
         
          
 


        fetchAllCourses();
       


 
        
    }, []);


    const fetchAllCourses = async () => {
     
      try {          
        const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
        sessionStorage.removeItem("token");
        sessionStorage.setItem("token",jwtToken.data);
        const response = await api.post(apiUrl + "/getAllCourses");
        setcoursesList(response.data);  
        setshowSriPada(true);
        response.data.forEach( (element:any) => {
          
         if(element.code===courseData.coursePreference1){           
           secourse1String(element.courserName);
         }
         if(element.code===courseData.coursePreference2){          
           secourse2String(element.courserName);
         }
         if(element.code===courseData.coursePreference3){          
          secourse3String(element.courserName);
         }

       
        
         if(courseData.coursePreference4 && element.code===courseData.coursePreference4){  
            setshowSriPada(true);        
            secourse4String(element.courserName);
         }
         if(courseData.coursePreference5 && element.code===courseData.coursePreference5){      
          setshowSriPada(true);        
          secourse5String(element.courserName);
         }
         if(courseData.coursePreference6 && element.code===courseData.coursePreference6){    
          setshowSriPada(true);          
          secourse6String(element.courserName);
         }

          
      });
        
       
       
      } catch (error) {
        // Handle any errors that occurred during the request
        console.error(error);
       
         
      }
    };


    const submitApplication = async () => {
      try {      
        setDisableSubmit(true);
        const submitrequest = {
          "personalInfo": personnalData,
          "coursesInfo":courseData,
           "alYear":alYear,
           "alIndexNumber": alIndexNumber,
           "alNICNumber":alNICNumber,
           "olAttempt1Year":olAttempt1Year,
           "olAttempt1Index":olAttempt1Index,
           "olAttempt2Year":olAttempt2Year,
           "olAttempt2Index":olAttempt2Index,
           "olAttempt3Year":olAttempt3Year,
           "olAttempt3Index":olAttempt3Index,
           "olType":olType,
           "editMode":sessionStorage.getItem("EDIT_MODE"),
           "applicationNo":sessionStorage.getItem("APPLICATION_NO")


        }
        const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
        sessionStorage.removeItem("token");
        sessionStorage.setItem("token",jwtToken.data);
        const response = await api.post(apiUrl + '/submitApplication', submitrequest);
         console.log('response.data',response.data);
         const returnedValue:string=response.data;

        if(response.data){ 
              setDisableSubmit(false);
              setapplicationNo(response.data);
              const editMode=sessionStorage.getItem("EDIT_MODE");
              if(editMode){
                setalertMsg("Application Successfully Edited. Your Application Reference Number is ");
                sessionStorage.clear();
              }else{
                setalertMsg("Application Successfully Submitted. Your Application Reference Number is ");
                sessionStorage.clear();
              }           
              setshowAlert(true);
         
        }else{
          setDisableSubmit(false);
          setalertMsg("Unable to Submit Application Error (2)");
          setshowError(true);
        }
 
      } catch (error) { 
        setDisableSubmit(false);   
         setalertMsg("Unable to Submit Application Error (100)");
          setshowError(true);
      }

    }

    const handleAlertDialogClose = () => {
      setshowAlert(false);  
      navigate("/");   
    
    };

    const handleErrorDialogClose = () => {
      setshowError(false);     
    
    };

    const downloadApplication = () => {
      setshowError(false);     
      generateReport();
    };


    const generateReport = async () => {
    
      try {
        
        const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
        sessionStorage.removeItem("token");
        sessionStorage.setItem("token",jwtToken.data); 
          const response = await api({
            url: `${apiUrl + "/downloadReport"}`,  
            method: 'POST',
            data: {  
              applicationNo
            },
            responseType: 'blob', // important
        });
    
   

        var blob = new Blob([response.data], {type: "application/pdf"});
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download =applicationNo.concat('.pdf');
        link.click();


      } catch (error) {
        // Handle any errors that occurred during the request
        console.error(error);
         
      }
    };

    const BlueColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
      color: theme.palette.getContrastText(blue[600]),
      backgroundColor: '#2364be',
      '&:hover': {
        backgroundColor: '#2364be',
      },
      marginTop:'20px',
      marginBottom:'10px',
      minWidth:'200px',
       
    }));
 
    function navigationToCourses(){
      navigate("/courses");
    }

  return (

    <>
 

<Dialog open={showError}   keepMounted
          maxWidth='xl'>
          <DialogTitle style={{ backgroundColor: 'red', color: 'white' }}> Error</DialogTitle>
           <DialogContent dividers>
           <Typography>
               {alertMsg} 
            </Typography>
           </DialogContent> 
        
          <DialogActions>
              <Button autoFocus onClick={handleErrorDialogClose}>
                    Ok
              </Button>        
      </DialogActions>
      </Dialog>



<Dialog open={showAlert}   keepMounted
          maxWidth='xl'>
          <DialogTitle style={{ backgroundColor: 'green', color: 'white' }}> Success</DialogTitle>
           <DialogContent dividers>
            <Typography>
               {alertMsg} 
            </Typography>
            <center>
               <Typography sx={{
                  color: "blue",
                  fontWeight: 'bold',
                  lineHeight: '24px',
                  fontSize: '24px',
                  marginTop:5,
               }} >{applicationNo}</Typography>
            </center>
           
             
           </DialogContent> 
        
          <DialogActions>

             <Button variant="contained" startIcon={<Download />} autoFocus onClick={downloadApplication}>
                    Download Application
              </Button>   

              <Button autoFocus onClick={handleAlertDialogClose}>
                    Ok
              </Button>        
      </DialogActions>
      </Dialog>


<Header></Header>
 
<IconBreadcrumbs currentPage={"SUMMARY"}></IconBreadcrumbs> 
<Box sx={{ m: 5 }} />
  <Container >

{/*   <Box fontWeight='bold' display='inline'    
                            justifyContent="center"
                            alignItems="center">
                               <Grid container sx={{
                                  backgroundColor: (theme) => theme.palette.mode === "light"
                                      ? "#f5f5f5"
                                      : "#0f0f0f",
                              }}
                                  
                                  spacing={0}
                                  direction="column"
                                  alignItems="center"
                                  justifyContent="center" >
                                          <Typography style={{marginTop:'20px',paddingTop: "2vh",paddingBottom: "2vh",paddingLeft: "10px",paddingRight: "5px" }} 
                                            sx={{fontWeight:'bold',  fontSize: {lg: 18, md: 15,sm: 13,xs: 13} }}   gutterBottom align="justify"    >
                                              
                                              REVIEW YOUR DETAILS BEFORE SUBMISSION
                                              
                                          </Typography>
                             </Grid>


                               
    </Box> */}

    <AppBar elevation={0} style={{ background: '#D2DCF0' }}  sx={{marginTop:'1px',marginBottom:'40px'}} position="static">
                    <Box display='flex' flexGrow={1}>
                        <Typography  margin={'8px'} sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'500',color:'black' }}>9. Review Your Details Before Submission</Typography>
                    </Box>
                </AppBar>  
                

   

      <Grid  marginTop={'20px'} container >

      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          
      <Grid item xs={12} sm={12} md={3} >
            <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>
                NIC No 
             </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} >
               <TextField  sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} id="standard-basic" variant="standard" value={alNICNumber}/>
            </Grid>
           
            <Grid item xs={12} sm={12} md={3} >
             
            </Grid>
            <Grid item xs={12} sm={12} md={2} >
             
             </Grid>

            <Grid item xs={12} sm={12} md={3} >
             <Typography  sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >
                 G.C.E. (A/L) Year 
             </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} >
               <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} id="standard-basic" variant="standard" value={alYear}/>
            </Grid>
            <Grid item xs={12} sm={12} md={2} >
            <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>
                G.C.E. (A/L) Index No 
             </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} >
               <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} id="standard-basic" variant="standard" value={alIndexNumber}/>
            </Grid>




            

           <Grid item xs={12} sm={12} md={3} >
           {olType==='OL' &&  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >
                 G.C.E. (O/L) ( First Attempt ) Year 
             </Typography>}
             {olType==='PIRIVEN' &&  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>
                 Piriven Exam ( First Attempt ) Year 
             </Typography>}
            </Grid> 

            <Grid item xs={12} sm={12} md={2} >
               <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} id="standard-basic" variant="standard" value={olAttempt1Year}/>
            </Grid> 
            

            <Grid item xs={12} sm={12} md={2} >
             <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>
                 Index Number
             </Typography>
            </Grid> 

             <Grid item xs={12} sm={12} md={3} >
              <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} id="standard-basic" variant="standard" value={olAttempt1Index}/>
            </Grid> 

            
            <Grid item xs={12} sm={12} md={3} >
            {olType==='OL' &&  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>
                 G.C.E. (O/L) ( Second Attempt ) Year 
             </Typography>}
             {olType==='PIRIVEN' &&  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>
                 Piriven Exam ( Second Attempt ) Year 
             </Typography>}

            </Grid>

             <Grid item xs={12} sm={12} md={2} >
              <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} id="standard-basic" variant="standard" value={olAttempt2Year}/>
            </Grid> 

             <Grid item xs={12} sm={12} md={2} >
                <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>
                   Index Number
                </Typography>
            </Grid> 

            <Grid item xs={12} sm={12} md={3} >
              <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} id="standard-basic" variant="standard" value={olAttempt2Index}/>
            </Grid> 

           
            <Grid item xs={3} sm={3} md={3} >
            {olType==='OL' &&  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>
             G.C.E. (O/L) ( Third Attempt ) Year 
             </Typography>}
             {olType==='PIRIVEN' &&  <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>
              Piriven Exam ( Third Attempt ) Year 
             </Typography>}

            </Grid>

            <Grid item xs={12} sm={12} md={2} >
              <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} id="standard-basic" variant="standard" value={olAttempt3Year}/>
            </Grid> 

             <Grid item xs={12} sm={12} md={2} >
             <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>
                 Index Number
             </Typography>
            </Grid> 

            <Grid item xs={12} sm={12} md={3} >
              <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} id="standard-basic" variant="standard" value={olAttempt3Index}/>
            </Grid> 

            <Grid item xs={12} sm={12} md={3} >
               <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >  Name in Full </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} >
              <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.fullname} />
            </Grid> 

             <Grid item xs={12} sm={12} md={2} >
               
             </Grid> 

            <Grid item xs={3} sm={3} md={3} >
               <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>  Name with Initials </Typography>
            </Grid>
          
           

            <Grid item xs={6} sm={6} md={6} >
              <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.nameWithInitials} />
            </Grid> 

              <Grid item xs={2} sm={2} md={2} >
               
               </Grid> 
  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}> Date of Birth (mm/dd/yyyy) </Typography>
              </Grid>
            
             
  
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.dob} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} > </Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}> Gender </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={genderString} />
              </Grid> 



              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}> Title </Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField  sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={titleString} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >  Race </Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField  sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={raceString} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}> Post Box </Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.addressStreet} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Street</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.addressBuilding} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>City</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.addressCity} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Postal Code</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.postalDescription} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Permanant Residance District</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.district} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Father/Mother/Guardian NIC Number</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.guardianNic} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >No of years living in above district</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.livingYears} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Mobile No</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.mobileNo} />
              </Grid> 
              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }}>Land Phone Number</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.landNumber} />
              </Grid> 
              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Email Address</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={personnalData.email} />
              </Grid> 


              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Child of a State Worker</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} > 
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={courseData.estateChild==='true'? 'Yes' :'No'} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Applied to Sri Pada NCOE</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={courseData.applySripada==='true'? 'Yes' :'No'} />
              </Grid> 


              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Course Preference 1</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={course1String} />
              </Grid> 



              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Course Preference 2</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={course2String} />
              </Grid> 

              <Grid item xs={2} sm={2} md={2} ></Grid>  
              <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >Course Preference 3</Typography>
              </Grid>            
              <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={course3String} />
              </Grid> 


              { gitExamYear && <Grid item xs={2} sm={2} md={2} ></Grid>  }
              { gitExamYear && <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >GIT Exam Year</Typography>
              </Grid>  }           
              { gitExamYear &&  <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={gitExamYear} />
              </Grid> }   

             {gitExamIndex && <Grid item xs={2} sm={2} md={2} ></Grid>  }
             {gitExamIndex && <Grid item xs={3} sm={3} md={3} >
                 <Typography sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >GIT Exam Index Number</Typography>
              </Grid>  }          
              {gitExamIndex &&  <Grid item xs={6} sm={6} md={6} >
                <TextField sx={{fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} fullWidth id="standard-basic" variant="standard" value={gitExamIndex} />
              </Grid>}

      </Grid> 

      
      </Grid>

    

    <Grid container   marginBottom={'15px'}
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center" >
      <BlueColorButton  disabled={disableSubmit}  sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            ".MuiButton-startIcon": { m: 0 }
          }
         })}  variant="contained" onClick={submitApplication}  >
         Submit
      </BlueColorButton>
      </Grid>





</Container>
      </>



    
         

    
  )
}