import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Grid, Card, CardContent, Box  } from '@mui/material';
import Link from '@mui/material/Link';
 
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
 
import Home from './Home';
import SusAlbum from './Album';
import AdvanceLevel from './Al/AdvanceLevel';
import AgreeDialog from './common/Agree';
import OrdinaryLevel from './Ol/OrdinaryLevel';
import PersonalInformation from './PersonalInfo/PersonalInformation';
import { useNavigate } from "react-router-dom";
import Courses from './Courses/Courses';
import Summary from './Summary/Summary';
import Information from './common/Information';


const drawerWidth=0;


function App() {


  const urlTamilStyleObj = {
    fontSize: "90%",     
    color: "red", 
    fontWeight:"600"
  }

  const urlSinStyleObj = {
    fontSize: "110%",     
    color: "red", 
    fontWeight:"600"
  }


  const urlStyleObj = {       
    color: "red", 
    fontWeight:"600"
  }



  return (
  
      <>

    <HashRouter>
      <Routes>

      <Route
          path="/"
          element={<SusAlbum/>}
        />

       

        <Route
          path="/AL"
          element={<AdvanceLevel/>}
        />

         <Route
          path="/OL"
          element={<OrdinaryLevel/>}
        />

         <Route
          path="/info"
          element={<Information/>}
        />

        <Route
          path="/agree"
          element={<AgreeDialog/>}
        />

      <Route
          path="/personnalInfo"
          element={<PersonalInformation/>}
        />

       <Route
          path="/courses"
          element={<Courses/>}
        />

        <Route
          path="/summary"
          element={<Summary/>}
        /> 



      </Routes>
         
   </HashRouter>

  
  </>
   
  
  );
}

export default App;
