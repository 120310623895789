import { ArrowBackIos, Copyright } from "@mui/icons-material";
import { AppBar, Avatar, Box, Button, ButtonProps, Card, CardActions, CardContent, CardMedia, Checkbox, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, InputBase, InputLabel, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, makeStyles, MenuItem, Paper, Select, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,   Toolbar, Typography } from "@mui/material";
import React from "react";
import './Al.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import AgreeDialog from "../common/Agree";
import Header from "../common/Header";
import { blue, purple } from "@mui/material/colors";
import axios, { AxiosError } from "axios";
import ALInstructions from "./ALInstrctions";
import { useNavigate } from "react-router-dom";
import  { useState, useEffect } from 'react';
import IconBreadcrumbs from "../common/BreadCrumb";
import SendIcon from '@mui/icons-material/Send';
import api from "../common/ApiInterceptor";

export default function AdvanceLevel() {


  


    const [examYear, setExamYear] = React.useState<string>('');
    const [examindex, setExamindex] = React.useState('');
    const [examnic, setExamnic] = React.useState('');
    const [examstream, setExamstream] = React.useState('');
    const [Alrows, setAlrows] = React.useState<any[]>([]);
    const [AlDisplayrows, setAlDisplayrows] = React.useState<any[]>([]);
    const [showError, setShowError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [seachPressed, setSeachPressed] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const navigate = useNavigate();
    const [execute1, setexecute1] = React.useState(false);
    const [showNextButtons, setShowNextButtons] = React.useState(false);
    const [searchDone, setSearchDone] = React.useState(false);
    const [alSubjectrows, setAlSubjectrows] = React.useState<any[]>([]);
    const [streamDesc, setStreamDesc] = React.useState('');
    const [alMedium, setAlMedium] = React.useState('');
    

  
    const apiUrl = process.env.REACT_APP_API_URL;
    let tokenRequest = {
      "username": process.env.REACT_APP_TOKEN_USERNAME,
    }

    const handleYearChange = (event: SelectChangeEvent) => {
        setExamYear(event.target.value as string);
        setSearchDone(false);
        if(event.target.value==='2017' || event.target.value==='2018' || event.target.value==='2019'|| event.target.value==='2020'){             
          setShowAlert(true);
          setErrorMsg('Your (A/L) Year is valid to apply for the courses of Buddhism,catholicism/ christianity Only (clauses 4.5 and 4.8)');
        }        
    };


     
    useEffect(() => {
        const alIndex=sessionStorage.getItem("AL_INDEX");
        if(alIndex){
          setExamindex(alIndex);
          setExamYear(sessionStorage.getItem("AL_YEAR") as string);
          setExamnic(sessionStorage.getItem("AL_NIC") as string);       
          setexecute1(true);
        }
    },[]);


 
   


    useEffect(() => {
      if( execute1){
        serchAlResult();      
       // setexecute1(false);
      }   
    }, [ execute1]);



   
    interface result{
      attempt:string

    }

    const classes = {
      root: {
        flexGrow: 1
      },
      paper: {
        padding: 20,
        textAlign: "center",
        color: "blue",
        fontFamily: "Roboto"
      }
    };


    function createAlData(
        code: string,
        description: string,
       
      ) {
        return { code, description};
      }

    
      const BlueColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText(blue[600]),
        backgroundColor: '#2364be',
        '&:hover': {
          backgroundColor: '#2364be',
        },
        minWidth:300,
         
      }));
     

      const serchAlResult = async () => {



        let validform:boolean=true;
        
        if (examYear.trim().length === 0 ) {     
          validform=false;    
          setShowError(true);
          setErrorMsg('Please Select A/L Examination Year');
          
        } else{
          if (examindex.trim().length === 0) {  
            validform=false;           
            setShowError(true);
            setErrorMsg('Please Enter A/L Examination Index Number');
            
          } else{
            if (examnic.trim().length === 0 ) {      
              validform=false;       
              setShowError(true);
              setErrorMsg('Please Enter Your NIC Number');
            
            } 
          }
        }

       
      

        if(validform){
          
           const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
           sessionStorage.removeItem("token");
           sessionStorage.setItem("token",jwtToken.data);

            let alrequest = {
              "examYear": examYear.trim(),
              "examIndexNo": examindex.trim(),
              "nic": examnic.trim()
            }
          
            try {  
              const editMode=sessionStorage.getItem("EDIT_MODE");
              const alreadyExist =await  api.post(apiUrl + '/validateApplicant', alrequest);
              if(alreadyExist.data==1 && !editMode){                 
                 
                     setShowError(true);
                     setErrorMsg('You have already Submitted an Application');  
               
              }else{
                if(alreadyExist.data==2){
                    setShowError(true);
                    setErrorMsg('An Error Occured(E0001)');  
                }else{
                  try {     
                    
                    const response = await api.post(apiUrl + '/AlResult', alrequest);
                    if(response.data.length==0){
                      setShowError(true);
                      setErrorMsg('G.C.E (A/L) Results Not Found. Please check your Examination Index Number and the NIC Number');
                      return;
                    }
                    const responsepass = await api.post(apiUrl + '/isAlPassed', alrequest);
                    if(responsepass.data){
                      if(responsepass.data.length!=0){
                          var passed=responsepass.data;
                          if(!passed){
                            setShowError(true);
                            setErrorMsg('You do not have Minimum G.C.E (A/L) Results to Apply to the Teachers Colleges');
                            return;
                          }
                      }
                    }
                    const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
                    sessionStorage.removeItem("token");
                    sessionStorage.setItem("token",jwtToken.data);
                    const subjectresponse = await axios.post(apiUrl + '/AlSubjectResult', alrequest);
                    if(subjectresponse.data){
                      if(subjectresponse.data.length==0){
                        setShowError(true);
                        setErrorMsg('G.C.E (A/L) Subject Results Not Found.');
                        return;
                      }
                    }

                    response.data.forEach( (element:any) => {
                          if(element.code==='Stream'){
                              setExamstream(element.description);
                          }else{
                            AlDisplayrows.push(element);
                          }   
                          if(element.code==='Medium'){
                            setAlMedium(element.description);
                          }
                    });

                    
                    setAlSubjectrows(subjectresponse.data);
                    

               

                  

                    setAlrows(response.data);
                    



                    setSearchDone(true);
                    setShowNextButtons(true);
                    
                  } catch (error: unknown) {
                    setShowError(true);
                    setErrorMsg('Network Error Occured(E0002');         
                  }
                } 
              }  
            } catch (error: unknown) {
              setShowError(true);
              setErrorMsg('Network Error Occured(E0003)');         
            }

           } 
           setSeachPressed(true);
     };
     

     function navigationToHome(){
      navigate("/");
    }

 

     const defaultTheme = createTheme();


     const handleErrorDialogClose = () => {
        setShowError(false);
        setSeachPressed(false);
     };

     const handleAlertDialogClose = () => {
      setShowAlert(false);     
      setShowError(false);
   };
   
     function handleNavigation(){
      navigate("/OL");
     }


     function navigationForOL(){

      if (examYear.trim().length === 0 ) {     
       
        setShowError(true);
        setErrorMsg('Please Select A/L Examination Year');
        return;
      } else{
        if (examindex.trim().length === 0) {  
              
          setShowError(true);
          setErrorMsg('Please Enter A/L Examination Index Number');
          return;
        } else{
          if (examnic.trim().length === 0 ) {      
              
            setShowError(true);
            setErrorMsg('Please Enter Your NIC Number');
            return;
          } 
        }
      }

      if(!searchDone){
        setShowError(true);
        setErrorMsg('Please Search G.C.E (A/L) Results');
        return;
      }
        sessionStorage.removeItem("OL_TYPE");
        sessionStorage.setItem("OL_TYPE", 'OL');
        sessionStorage.setItem("AL_YEAR", examYear.trim());
        sessionStorage.setItem("AL_INDEX", examindex.trim());
        sessionStorage.setItem("AL_NIC",examnic.trim());
        sessionStorage.setItem("AL_STREAM",examstream);
        sessionStorage.setItem("AL_MEDIUM",alMedium);

      
        navigate("/OL");
     }
     function navigationForPiriven(){

      if (examYear.trim().length === 0 ) {     
       
        setShowError(true);
        setErrorMsg('Please Select A/L Examination Year');
        return;
      } else{
        if (examindex.trim().length === 0) {  
              
          setShowError(true);
          setErrorMsg('Please Enter A/L Examination Index Number');
          return;
        } else{
          if (examnic.trim().length === 0 ) {      
              
            setShowError(true);
            setErrorMsg('Please Enter Your NIC Number');
            return;
          } 
        }
      }
       sessionStorage.removeItem("OL_TYPE");
      sessionStorage.setItem("OL_TYPE", 'PIRIVEN');
      sessionStorage.setItem("AL_YEAR", examYear.trim());
      sessionStorage.setItem("AL_INDEX", examindex.trim());
      sessionStorage.setItem("AL_NIC",examnic.trim());
      sessionStorage.setItem("AL_STREAM",examstream);
      sessionStorage.setItem("AL_MEDIUM",alMedium);
      navigate("/OL");
   }

  


  const GreyColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#736e5f',
    '&:hover': {
      backgroundColor: '#736e5f',
    },
  }));

  function clearALResult(){
    sessionStorage.removeItem("OL_TYPE");
    sessionStorage.removeItem("AL_YEAR");
    sessionStorage.removeItem("AL_INDEX");
    sessionStorage.removeItem("AL_NIC");
    sessionStorage.removeItem("AL_STREAM");
    sessionStorage.removeItem("AL_MEDIUM");

    setExamYear('');
    setExamindex('');
    setExamnic('');
    setExamstream('');
    setAlrows([]);
    setAlDisplayrows([]);
    setAlSubjectrows([]);
    setSearchDone(false);
    setShowNextButtons(false);
    setSeachPressed(false);
  }
    
  var agreed = sessionStorage.getItem('agreed'); 
  agreed="1";
 

 
   

     return (
       <>


      <Dialog open={showError}   keepMounted
          
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                   
              },
            },
          }}
          >
          <DialogTitle style={{ backgroundColor: 'red', color: 'white' }}> Error</DialogTitle>
           <DialogContent sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} dividers>
               {errorMsg} 
           </DialogContent> 
        
          <DialogActions  style={{justifyContent: 'center'}}>
              <Button sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} variant="contained" autoFocus onClick={handleErrorDialogClose}>
                    Ok
              </Button>        
      </DialogActions>
      </Dialog>


      <Dialog open={showAlert}   keepMounted
        
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                 
              },
            },
          }}
          >
          <DialogTitle style={{ backgroundColor: 'blue', color: 'white' }}> Alert</DialogTitle>
           <DialogContent sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} dividers>
              {errorMsg}  
           </DialogContent> 
        
          <DialogActions style={{justifyContent: 'center'}}>
              <Button sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} variant="contained" autoFocus onClick={handleAlertDialogClose}>
                    Ok
              </Button>        
      </DialogActions>
      </Dialog>


 
    {sessionStorage.getItem('agreed')!='1' &&
       <AgreeDialog></AgreeDialog>
     }      
       
       <ThemeProvider theme={defaultTheme}>
      
      

         <CssBaseline />
         <Header/>       
         <IconBreadcrumbs currentPage={"AL"}></IconBreadcrumbs>




     <main>
     <center>

      <div style={classes.root}>

      <AppBar elevation={0} style={{ background: '#D2DCF0' }}  sx={{marginTop:'20px',marginBottom:'40px'}} position="static">
         <Box display='flex' flexGrow={1}>
             <Typography  margin={'8px'} sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'500',color:'black' }}>1. Insert your G.C.E (A/L) Examination year, index number and NIC number correctly.</Typography>
        </Box>
      </AppBar>   

 
      <Grid  container spacing={5}>        
 
        
          <Grid item xs={12} sm={12} lg={3}> 
                     
              <Grid item xs={12}>

              

                      <Grid item xs={12}  >
                           <Typography  sx={{fontWeight: 'bold',fontSize:"14px"}}>G.C.E (A/L) Exam Year</Typography>
                      </Grid>
                      <Grid item xs={6}  >
                        <FormControl sx={{ width: "100%" }}   size="small"> 
                                  <Select
                                    style={{minWidth: 100}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={examYear}
                                    label="examYear"
                                    
                                    onChange={handleYearChange}>
                                          <MenuItem value="">
                                          <em>Select</em>
                                          </MenuItem>
                                          <MenuItem value={'2022'}>2022</MenuItem>
                                          <MenuItem value={'2021'}>2021</MenuItem>
                                          <MenuItem value={'2020'}>2020</MenuItem>
                                          <MenuItem value={'2019'}>2019</MenuItem>
                                          <MenuItem value={'2018'}>2018</MenuItem>
                                          <MenuItem value={'2017'}>2017</MenuItem>
                                  </Select>
                            </FormControl>
                       </Grid>

             </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={3}>
             <Grid item xs={12}>
                <Grid item xs={12}>
                   <Typography sx={{fontWeight: 'bold',fontSize:"14px"}}>G.C.E (A/L) Index Number</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField  value={examindex}   
                         onChange={(event) => {
                                 setExamindex(event.target.value);
                                 setSearchDone(false);
                         }}  
                         onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }}}
                         required inputProps={{maxLength: 7 ,style: {textTransform: "uppercase"}}}   label="" type="text" size='small' color='primary' />
                   </Grid>
                </Grid>
        </Grid>
        
        <Grid item xs={12} sm={12} lg={3}>
             <Grid item xs={12}>
                <Grid item xs={6}>
                    <Typography sx={{fontWeight: 'bold',fontSize:"14px"}}>NIC Number</Typography>
                </Grid>

                    <TextField  value={examnic} 
                       onChange={(event) => {
                        setExamnic(event.target.value);
                        setSearchDone(false);
                      }} 
                    inputProps={{ maxLength: 12 ,style:{fontSize: '1rem',textTransform: "uppercase"}}} required   InputLabelProps={{style: {fontSize: '1rem'}}}  sx={{fontSize: '1rem'}} label="" type="text" size='small' color='primary' />
                </Grid>
        </Grid>

        <Grid item xs={12} sm={12} lg={3}>
  
              <Grid marginTop={'20px'} item xs={12} >
                <Button style={{backgroundColor: '#2364be'}} onClick={serchAlResult} variant="contained">Search A/L Result</Button>
                <GreyColorButton style={{marginLeft:'10px'}} onClick={clearALResult} variant="contained">Clear</GreyColorButton>
              </Grid>
              
                     
        </Grid>
      </Grid>
    </div>

     
   </center>
         </main>
       
         {seachPressed && <div style={{ marginTop: "40px"}}>
           <Grid sx={{ border: 0 }}  container spacing={3}>
               <Grid item xs>
               
               </Grid>
               <Grid item xs={8}>
                    <TableContainer   component={Paper}>
                        <Table  sx={{ minWidth: 250,marginBottom:'1px'}} aria-label="simple table">
                        <TableBody>
                           {AlDisplayrows.map((row) => (
                             <TableRow  key={row.code}  sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                               <TableCell style={{backgroundColor:'#f0f0f1'}} align="left">{row.code ==='Language Stream' ? 'Stream' : row.code}</TableCell>
                               <TableCell align="left">{row.description}</TableCell>
                               
                             </TableRow>
                           ))}
                        </TableBody>

                        <TableBody  >


                        <TableCell rowSpan={8} style={{backgroundColor:'#f0f0f1',fontWeight:"bold"}}>Results</TableCell>
                        <TableCell style={{backgroundColor:'#f0f0f1',fontWeight:"bold"}}  align="left">Subject</TableCell>
                        <TableCell style={{backgroundColor:'#f0f0f1',fontWeight:"bold"}} align="left">Grade</TableCell>

                         
                           {alSubjectrows.map((row) => (
                             <TableRow  key={row.code}  sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                               <TableCell align="left">{row.code}</TableCell>
                               <TableCell align="left">{row.description}</TableCell>
                             </TableRow>
                           ))}
                        </TableBody>
                        </Table>
                    </TableContainer> 
                    </Grid>
                      <Grid item xs>
                  
                    </Grid>
                  </Grid>
              </div>}
          
                <Grid container spacing={0} direction="column" alignItems="center" sx={{ m: 2 }} justifyContent="center">   
                   
               </Grid>
            
              <Grid container spacing={0} direction="column" alignItems="center" sx={{ m: 2 }} justifyContent="center">
               
               
              {showNextButtons && <Stack direction="row" spacing={2}>
                      <BlueColorButton   sx={(theme) => ({
                        [theme.breakpoints.down("sm")]: {
                          minWidth: 32,
                      
                          ".MuiButton-startIcon": { m: 0 }
                        }
                  })}  onClick={navigationForOL} variant="contained">
                         <Typography sx={{fontSize: {lg: 16, md: 14,sm: 13,xs: 13} }}>
                            Apply with G.C.E (O/L) Exam Results
                            </Typography>
                       </BlueColorButton>
                  <BlueColorButton    sx={(theme) => ({
                      [theme.breakpoints.down("sm")]: {
                        minWidth: 32,
                        ".MuiButton-startIcon": { m: 0 }
                      }
                    })}  onClick={navigationForPiriven}  variant="contained">
                        <Typography sx={{fontSize: {lg: 16, md: 14,sm: 13,xs: 13} }}> 
                            Apply with Mulika Pirivena Exam Results
                        </Typography>
                  </BlueColorButton>
                </Stack>}
                
              </Grid>
 
        

       </ThemeProvider></>
       
    );
 
}

function componentDidMount() {
  throw new Error("Function not implemented.");
}
