import axios from 'axios';

const api = axios.create({  
   baseURL:process.env.REACT_APP_API_URL 
});

// Add an interceptor for all requests
api.interceptors.request.use(async config => {
   // Retrieve the access token from React state or a state management system

  // const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', "ncoeWeb2024iit");
    const accessToken = sessionStorage.getItem("token");
  
   
   // Add the access token to the Authorization header
   config.headers.Authorization = `Bearer ${accessToken}`;

   return config;
});

export default api;