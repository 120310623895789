import React from 'react';
import { AppBar, Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Toolbar, Typography } from "@mui/material";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Link } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles'; 
import './../App.css';
export default function Header() {

  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: 'serif',
        textTransform: 'none',
        fontSize: "2rem",
      },
    },
  });

  return (
    
    <AppBar style={{ background: 'rgb(60, 110, 200)' }}    position="relative">
    <Toolbar>
    <Grid container spacing={5}>
      
      
      <Grid sx={{marginTop:'20px'}}  item xs={12}>
        <center>
           <Grid item xs>
               <img    src="/NcoeWebApp/assets/images/sri-lanka-logo.png" alt="my image" />  
            {/*  <img        src="/assets/images/sri-lanka-logo.png" alt="my image" />    */}

           </Grid>


          <Box m={0.5} > 
          <ThemeProvider theme={theme}>       
              <Typography  sx={{fontSize: {lg: 30, md: 25,sm: 18,xs: 18} }}      color="inherit"  >
                    National Colleges of Education (NCOE) Sri Lanka
              </Typography>
           </ThemeProvider>  
           </Box>
           </center> 
      </Grid>
       
    </Grid>

      




    </Toolbar>

  </AppBar>
    
 

  )
}