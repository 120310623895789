import { ArrowBackIos, ArrowForwardIosOutlined, Copyright } from "@mui/icons-material";
import { AppBar, Avatar, Box, Button, ButtonProps, Card, CardActions, CardContent, CardMedia, Checkbox, Container, CssBaseline, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, InputBase, InputLabel, Link, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,   Toolbar, Typography } from "@mui/material";
import React from "react";
import './Ol.css';
import {useEffect, useState} from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import AgreeDialog from "../common/Agree";
import Header from "../common/Header";
import { blue, purple } from "@mui/material/colors";
import axios, { AxiosError } from "axios";
import ALInstructions from "./OLInstrctions";
import { Session } from "inspector";
import { useNavigate } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import { validateHeaderName } from "http";
import IconBreadcrumbs from "../common/BreadCrumb";
import api from "../common/ApiInterceptor";
export default function OrdinaryLevel() {

   
 
    const [showError, setShowError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [seachPressed, setSeachPressed] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [attemp1Year, setAttemp1Year] = React.useState<string>('');
    const [attemp2Year, setAttemp2Year] = React.useState<string>('');
    const [attemp3Year, setAttemp3Year] = React.useState<string>('');
    const [Ol1rows, setOl1rows] = React.useState<any[]>([]);
    const [Ol2rows, setOl2rows] = React.useState<any[]>([]);
    const [Ol3rows, setOl3rows] = React.useState<any[]>([]);
    const [examindex1, setExamindex1] = React.useState('');
    const [examindex2, setExamindex2] = React.useState('');
    const [examindex3, setExamindex3] = React.useState('');
    const navigate = useNavigate();
    const [olType, setolType] = React.useState('');
    const [execute1, setexecute1] = React.useState(false);
    const [execute2, setexecute2] = React.useState(false);
    const [execute3, setexecute3] = React.useState(false);
    const [showContinueButton, setShowContinueButton] = React.useState(false);
    const [searchDone, setSearchDone] = React.useState(false);



    //const apiUrl: string ="http://localhost:8080/NcoeServices";
    const apiUrl = process.env.REACT_APP_API_URL;
    let tokenRequest = {
      "username": process.env.REACT_APP_TOKEN_USERNAME,
    }
    

    useEffect(() => {
     

      
      const olIndex1=sessionStorage.getItem("OL_ATTEMP1_YEAR");
      const olIndex2=sessionStorage.getItem("OL_ATTEMP2_YEAR");
      const olIndex3=sessionStorage.getItem("OL_ATTEMP3_YEAR");
      const theme = createTheme({
        spacing: 4,
      });
      

      if(olIndex1 || olIndex2 || olIndex3){
        
        const val1=sessionStorage.getItem("OL_ATTEMP1_INDEX") as string;
            
        if(val1 && val1!=null){
          console.log('if(val1 && val1!=null)');
          setAttemp1Year(sessionStorage.getItem("OL_ATTEMP1_YEAR") as string);
          setExamindex1(sessionStorage.getItem("OL_ATTEMP1_INDEX") as string);
          setexecute1(true);
        } 

        const val2=sessionStorage.getItem("OL_ATTEMP2_INDEX") as string;
        if(val2 && val2!==null){         
          setAttemp2Year(sessionStorage.getItem("OL_ATTEMP2_YEAR") as string);
          setExamindex2(sessionStorage.getItem("OL_ATTEMP2_INDEX") as string);
          setexecute2(true);
        }
      
        const val3=sessionStorage.getItem("OL_ATTEMP3_INDEX") as string;
        if(val3 && val3!=null){
          setAttemp3Year(sessionStorage.getItem("OL_ATTEMP3_YEAR") as string);
          setExamindex3(sessionStorage.getItem("OL_ATTEMP3_INDEX") as string);
          setexecute3(true);
        }
       

       }
  },[]);




  const BlueColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: '#2364be',
    '&:hover': {
      backgroundColor: '#2364be',
    },
    minWidth:300,
     
  }));
  

  function navigationToAL(){
    navigate("/AL");
  }
  
  useEffect(() => {
    if( execute1){
      if(examindex1){
        
         searchFirstShyResult();  
      
      }
      //setexecute1(false);
    }   
  }, [ execute1]);

  useEffect(() => {
    if( execute2){

      
      if(examindex2){
       
        searchSecondShyResult();   
      }
        
  
    }   
  }, [ execute2]);

  useEffect(() => {
    if( execute3){
      if(examindex3){
        searchThirdShyResult();      
      } 
     
    //  setexecute3(false);
    }   
  }, [ execute3]);





      const handleAttemp1YearChange = (event: SelectChangeEvent) => {
          setAttemp1Year(event.target.value as string);
          setSearchDone(false);
          setOl1rows([]);
      };
      const handleAttemp2YearChange = (event: SelectChangeEvent) => {
        setAttemp2Year(event.target.value as string);
        setSearchDone(false);
        setOl2rows([]);
      };
      const handleAttemp3YearChange = (event: SelectChangeEvent) => {
        setAttemp3Year(event.target.value as string);
        setSearchDone(false);
         setOl3rows([]);
      };

    interface result{
      attempt:string

    }


    function clearFirstShy(){
      setAttemp1Year('');
      setExamindex1('');
      setOl1rows([]);
      sessionStorage.removeItem("OL_ATTEMP1_YEAR");
      sessionStorage.removeItem("OL_ATTEMP1_INDEX");
      setShowContinueButton(false);
      if(attemp2Year && examindex2){
        setShowContinueButton(true);
      }
      if(attemp3Year && examindex3){
        setShowContinueButton(true);
      }
     

    }

    function clearSecondShy(){
      setAttemp2Year('');
      setExamindex2('');
      setOl2rows([]);
      sessionStorage.removeItem("OL_ATTEMP2_YEAR");
      sessionStorage.removeItem("OL_ATTEMP2_INDEX");
      setShowContinueButton(false);
      if(attemp1Year && examindex1){
        setShowContinueButton(true);
      }
      if(attemp3Year && examindex3){
        setShowContinueButton(true);
      }
    }

    function clearThirdShy(){
      setAttemp3Year('');
      setExamindex3('');
      setOl3rows([]);
      sessionStorage.removeItem("OL_ATTEMP3_YEAR");
      sessionStorage.removeItem("OL_ATTEMP3_INDEX");
      setShowContinueButton(false);
      if(attemp2Year && examindex2){
        setShowContinueButton(true);
      }
      if(attemp1Year && examindex1){
        setShowContinueButton(true);
      }
    }




    function createAlData(
        code: string,
        description: string,
       
      ) {
        return { code, description};
      }

    
      useEffect(() => {
        setolType(sessionStorage.getItem('OL_TYPE') as string);
       }, []);

      const serchAlResult = async () => {

        let validform:boolean=true;

  

       
      

      if(validform){
           

          try {  

             
          } catch (error: unknown) {
            setShowError(true);
            setErrorMsg('Network Error Occured(E0003)');         
          }

    }
      

 


          setSeachPressed(true);



     };
     const defaultTheme = createTheme();


     const handleErrorDialogClose = () => {
        setShowError(false);
        setSeachPressed(false);
     };

     const handleAlertDialogClose = () => {
      setShowAlert(false);     
      setShowError(false);
   };
   
     
   const searchFirstShyResult = async () => {
    
  

    let olrequest = {
      "examYear": attemp1Year,
      "examIndexNo": examindex1,     

    }
    const examType=sessionStorage.getItem("OL_TYPE");
    if(attemp1Year){
        if(attemp1Year==attemp2Year){
          setAttemp1Year('');
          setExamindex1('');
          setShowError(true);
          setErrorMsg(' You have already entered the examination Year as your Second Attempt');
          return;
        }

        if(attemp1Year==attemp3Year){
          setAttemp1Year('');
          setExamindex1('');
          setShowError(true);
          setErrorMsg(' You have already entered the examination Year as your Third Attempt');
          return;
        }
      }
      if(examindex1){ 
        if(examindex1===examindex2){
          setAttemp1Year('');
          setExamindex1('');
          setShowError(true);
          setErrorMsg(' You have already entered the examination Index as your Second Attempt');
          return;
        }
        if(examindex1===examindex3){
          setAttemp1Year('');
          setExamindex1('');
          setShowError(true);
          setErrorMsg(' You have already entered the examination Index as your Third Attempt');
          return;
        }
      }


    if(examType==='OL'){
      try {     
        const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
        sessionStorage.removeItem("token");
        sessionStorage.setItem("token",jwtToken.data);
        const response = await api.post(apiUrl + '/OlResult', olrequest);
        if(response.data.length==0){
          setAttemp1Year('');
          setExamindex1('');
          setShowError(true);
        
          setErrorMsg('G.C.E (O/L) Results Not Found. Please check your Examination Index Number and the Year');
          return;
        }
        setShowContinueButton(true);
        setOl1rows(response.data);
        setSearchDone(true);
        
      } catch (error: unknown) {
        setAttemp1Year('');
        setExamindex1('');
        setShowError(true);
        setErrorMsg('Network Error Occured(E0002');         
      }
    }
    if(examType==='PIRIVEN'){
          try {     
            const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
            sessionStorage.removeItem("token");
            sessionStorage.setItem("token",jwtToken.data);
            const response = await api.post(apiUrl + '/PirivenaResult', olrequest);
            if(response.data.length==0){
              setAttemp1Year('');
              setExamindex1('');
              setShowError(true);
              setErrorMsg('Mulika Pirivena Results Not Found. Please check your Examination Index Number and the Year');
              return;
            }
            setShowContinueButton(true);
            setOl1rows(response.data);
            setSearchDone(true);
            
          } catch (error: unknown) {
            setAttemp1Year('');
            setExamindex1('');
            setShowError(true);
            setErrorMsg('Network Error Occured(E0002');         
          }
        }
 


      
};



const searchSecondShyResult = async () => {
      let olrequest = {
        "examYear": attemp2Year,
        "examIndexNo": examindex2,    

      }

      

    if(attemp2Year){
        if(attemp2Year==attemp1Year){
          setAttemp2Year('');
          setExamindex2('');
          setShowError(true);
          setErrorMsg(' You have already entered the examination Year as your First Attempt');
          return;
        }

        if(attemp2Year==attemp3Year){
          setAttemp2Year('');
          setExamindex2('');
          setShowError(true);
          setErrorMsg(' You have already entered the examination Year as your Third Attempt');
          return;
        }
      }
      if(examindex2){
        if(examindex2===examindex1){
          setAttemp2Year('');
          setExamindex2('');
          setShowError(true);
          setErrorMsg(' You have already entered the examination Index as your First Attempt');
          return;
        }
        if(examindex2===examindex3){
          setAttemp2Year('');
          setExamindex2('');
          setShowError(true);
          setErrorMsg(' You have already entered the examination Index as your Third Attempt');
          return;
        }
      }
      const examType=sessionStorage.getItem("OL_TYPE");
      if(examType==='OL'){
          try {     
            const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
            sessionStorage.removeItem("token");
            sessionStorage.setItem("token",jwtToken.data);
            const response = await api.post(apiUrl + '/OlResult', olrequest);
            if(response.data.length==0){
              setAttemp2Year('');
              setExamindex2('');
              setShowError(true);
              setErrorMsg('G.C.E (O/L) Results Not Found. Please check your Examination Index Number and the Year');
              return;
            }
            setShowContinueButton(true);
            setOl2rows(response.data);
            setSearchDone(true);
            
          } catch (error: unknown) {
            setAttemp2Year('');
            setExamindex2('');
            setShowError(true);
            setErrorMsg('Network Error Occured(E0002');         
          }
        }

        if(examType==='PIRIVEN'){
          try {     
            const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
            sessionStorage.removeItem("token");
            sessionStorage.setItem("token",jwtToken.data);
            const response = await api.post(apiUrl + '/PirivenaResult', olrequest);
            if(response.data.length==0){
              setAttemp2Year('');
              setExamindex2('');
              setShowError(true);
              setErrorMsg('Mulika Pirivena Results Not Found. Please check your Examination Index Number and the Year');
              return;
            }
            setShowContinueButton(true);
            setOl2rows(response.data);
            setSearchDone(true);
            
          } catch (error: unknown) {
            setAttemp2Year('');
            setExamindex2('');
            setShowError(true);
            setErrorMsg('Network Error Occured(E0002');         
          }
        }


  
};
const searchThirdShyResult = async () => {
      let olrequest = {
        "examYear": attemp3Year,
        "examIndexNo": examindex3,     

      }

      if(attemp3Year){
          if(attemp3Year==attemp1Year){
            setAttemp3Year('');
            setExamindex3('');
            setShowError(true);
            setErrorMsg(' You have already entered the examination Year as your First Attempt');
            return;
          }

          if(attemp3Year==attemp2Year){
            setAttemp3Year('');
            setExamindex3('');
            setShowError(true);
            setErrorMsg(' You have already entered the examination Year as your Second Attempt');
            return;
          }
      }
      if(examindex3) {
          if(examindex3===examindex1){
            setAttemp3Year('');
            setExamindex3('');
            setShowError(true);
            setErrorMsg(' You have already entered the examination Index as your First Attempt');
            return;
          }
          if(examindex3===examindex2){
            setAttemp3Year('');
            setExamindex3('');
            setShowError(true);
            setErrorMsg(' You have already entered the examination Index as your Second Attempt');
            return;
          }
        }

      const examType=sessionStorage.getItem("OL_TYPE");
      if(examType==='OL'){
        try {     
          const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
          sessionStorage.removeItem("token");
          sessionStorage.setItem("token",jwtToken.data);
          const response = await api.post(apiUrl + '/OlResult', olrequest);
          if(response.data.length==0){
            setAttemp3Year('');
            setExamindex3('');
            setShowError(true);
            setErrorMsg('G.C.E (O/L) Results Not Found. Please check your Examination Index Number and the Year');
            return;
          }
          setShowContinueButton(true);
          setOl3rows(response.data);
          setSearchDone(true);
          
        } catch (error: unknown) {
          setAttemp3Year('');
          setExamindex3('');
          setShowError(true);
          setErrorMsg('Network Error Occured(E0002)');         
        }
      }
      if(examType==='PIRIVEN'){
        try {     
          const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
          sessionStorage.removeItem("token");
          sessionStorage.setItem("token",jwtToken.data);
          const response = await api.post(apiUrl + '/PirivenaResult', olrequest);
          if(response.data.length==0){
            setAttemp3Year('');
            setExamindex3('');
            setShowError(true);
            setErrorMsg('Mulika Pirivena Results Not Found. Please check your Examination Index Number and the Year');
            return;
          }
          setShowContinueButton(true);
          setOl3rows(response.data);
          setSearchDone(true);
          
        } catch (error: unknown) {
          setAttemp3Year('');
          setExamindex3('');
          setShowError(true);
          setErrorMsg('Network Error Occured(E0002');         
        }
      }
      


};

function createOlData1(
  code: string,
  description: string,
 
) {
  return { code, description};
}
    


function navigateFromOL(){

  if(!searchDone){
    setShowError(true);
    setErrorMsg('Please Search G.C.E (O/L) Results');    
    return;
  }
   sessionStorage.setItem("OL_ATTEMP1_YEAR",attemp1Year);
   sessionStorage.setItem("OL_ATTEMP1_INDEX",examindex1);

   sessionStorage.setItem("OL_ATTEMP2_YEAR",attemp2Year);
   sessionStorage.setItem("OL_ATTEMP2_INDEX",examindex2);

   sessionStorage.setItem("OL_ATTEMP3_YEAR",attemp3Year);
   sessionStorage.setItem("OL_ATTEMP3_INDEX",examindex3);

   navigate("/personnalInfo");

}
const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#2364be',
  '&:hover': {
    backgroundColor:'#2364be',
  },
}));


const GreyColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#736e5f',
  '&:hover': {
    backgroundColor: '#736e5f',
  },
}));


     
     return (
       <>


      <Dialog open={showError}   keepMounted
           sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                
              },
            },
          }}
          
          >
          <DialogTitle style={{ backgroundColor: 'red', color: 'white' }}> Error</DialogTitle>
           <DialogContent sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} dividers>
               {errorMsg}  
           </DialogContent> 
        
          <DialogActions style={{justifyContent: 'center'}}>
              <Button  sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} variant="contained" autoFocus onClick={handleErrorDialogClose}>
                    Ok
              </Button>        
      </DialogActions>
      </Dialog>


      <Dialog open={showAlert}   keepMounted
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                
              },
            },
          }}
          
          >
          <DialogTitle style={{ backgroundColor: 'blue', color: 'white' }}> Alert</DialogTitle>
           <DialogContent sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} dividers>
                {errorMsg} 
           </DialogContent> 
        
          <DialogActions style={{justifyContent: 'center'}}>
              <Button sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} variant="contained" autoFocus onClick={handleAlertDialogClose}>
                    Ok
              </Button>        
      </DialogActions>
      </Dialog>

 

       
       <ThemeProvider theme={defaultTheme}>

      

         <CssBaseline />
         <Header/>  
         <IconBreadcrumbs currentPage={"OL"}></IconBreadcrumbs>

   
         <main>





         <AppBar elevation={0} style={{ background: '#D2DCF0' }}  sx={{marginTop:'1px',marginBottom:'40px'}} position="static">
              <Box display='flex' flexGrow={1}>
                  <Typography  margin={'8px'} sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'500',color:'black' }}>2. Insert your GCE (O/L) Examination Year, Index number and NIC number correctly.</Typography>
              </Box>
           </AppBar>  
           <Container sx={{ py: 5 }} maxWidth="lg">   
                   
             <Grid container spacing={1}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={4}  >
                   
                    <Grid container rowSpacing={1}   columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                       <Grid item xs={12}>
                          <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}   >2.1 First Attempt</Typography>
                        </Grid>

                        <Grid item xs={6}>
                        {olType==='OL' &&  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>G.C.E(O/L) Year</Typography>}
                        {olType==='PIRIVEN' &&  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>Pirivena Exam Year</Typography>}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>Index Number</Typography>
                        </Grid>
                        <Grid item xs={6}>
                              <FormControl fullWidth size="small">                  
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={attemp1Year}
                                    label="examYear"
                                    onChange={handleAttemp1YearChange}>
                                          <MenuItem value="">
                                          <em>Select</em>
                                          </MenuItem>
                                          <MenuItem value={2022}>2022</MenuItem>
                                          <MenuItem value={2021}>2021</MenuItem>
                                          <MenuItem value={2020}>2020</MenuItem>
                                          <MenuItem value={2019}>2019</MenuItem>
                                          <MenuItem value={2018}>2018</MenuItem>
                                          <MenuItem value={2017}>2017</MenuItem>
                                          <MenuItem value={2016}>2016</MenuItem>
                                          <MenuItem value={2015}>2015</MenuItem>
                                          <MenuItem value={2014}>2014</MenuItem>
                                          <MenuItem value={2013}>2013</MenuItem>
                                          <MenuItem value={2012}>2012</MenuItem>
                                          <MenuItem value={2011}>2011</MenuItem>
                                          <MenuItem value={2010}>2010</MenuItem>
                                          
                                  </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField  value={examindex1}   
                            onChange={(event) => {
                                    setExamindex1(event.target.value);
                                    setSearchDone(false);
                                    setOl1rows([]);
                            }} 
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }}} 
                            required inputProps={{maxLength: 10 ,style: {textTransform: "uppercase"}}}   label="" type="text" size='small' color='primary' />
                        </Grid>


                        

                        <Grid item xs={12}>
                          <Box display="flex" justifyContent="space-between">
                              <ColorButton style={{width:'70%'}} onClick={searchFirstShyResult} variant="contained">
                                
                                   <Typography  sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>
                                     Search
                                  </Typography>
                                </ColorButton>
                              <GreyColorButton style={{width:'20%'}} onClick={clearFirstShy} variant="contained">
                                 <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>Clear</Typography>
                                </GreyColorButton>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                     
                            <TableContainer sx={{ backgroundColor:'#f2f4f4' }}   component={Paper}>
                                <Table   sx={{ minWidth: 250 }} aria-label="simple table">                   
                                    <TableBody>
                                    {Ol1rows.map((row) => (
                                        <TableRow
                                        key={row.code}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 },...row.description==='Grade' &&  {background:'white'}  }}
                                        >
                                    
                                        <TableCell align="left">
                                      
                    
                                         <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12},...row.description==='Grade' &&  {fontWeight:'bold'}}}>
                                            {row.code}
                                         </Typography>

                                          
                                          </TableCell>
                                        <TableCell align="right">
                                           <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12},...row.description==='Grade' &&  {fontWeight:'bold'}}}>
                                           {row.description}
                                           </Typography>
                                        
                                          
                                          </TableCell>
                                        
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer> 
         
                        </Grid>

                      </Grid>    



                </Grid>


                <Grid item xs={4}  >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={12}>
                          <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>2.2 Second Attempt</Typography>
                        </Grid>

                        <Grid item xs={6}>
                        {olType==='OL' &&  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>G.C.E(O/L) Year</Typography>}
                        {olType==='PIRIVEN' &&  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>Pirivena Exam Year</Typography>}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>Index Number</Typography>
                        </Grid>
                        <Grid item xs={6}>
                              <FormControl fullWidth size="small">                  
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={attemp2Year}
                                    label="examYear"
                                    onChange={handleAttemp2YearChange}>
                                          <MenuItem value="">
                                          <em>Select</em>
                                          </MenuItem>
                                          <MenuItem value={2022}>2022</MenuItem>
                                          <MenuItem value={2021}>2021</MenuItem>
                                          <MenuItem value={2020}>2020</MenuItem>
                                          <MenuItem value={2019}>2019</MenuItem>
                                          <MenuItem value={2018}>2018</MenuItem>
                                          <MenuItem value={2017}>2017</MenuItem>
                                          <MenuItem value={2016}>2016</MenuItem>
                                          <MenuItem value={2015}>2015</MenuItem>
                                          <MenuItem value={2014}>2014</MenuItem>
                                          <MenuItem value={2013}>2013</MenuItem>
                                          <MenuItem value={2012}>2012</MenuItem>
                                          <MenuItem value={2011}>2011</MenuItem>
                                          <MenuItem value={2010}>2010</MenuItem>
                                  </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField  value={examindex2}   
                            onChange={(event) => {
                                    setExamindex2(event.target.value);
                                    setSearchDone(false);
                                    setOl2rows([]);
                            }}  
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }}}
                            required inputProps={{maxLength: 10 ,style: {textTransform: "uppercase"}}}   label="" type="text" size='small' color='primary' />
                        </Grid>
                        <Grid item xs={12}>
                              <Box display="flex" justifyContent="space-between">
                                  <ColorButton style={{width:'70%'}} onClick={searchSecondShyResult} variant="contained">
                                  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>
                                     Search
                                  </Typography>
                                    </ColorButton>
                                  <GreyColorButton style={{width:'20%'}} onClick={clearSecondShy} variant="contained">
                                  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>
                                     Clear
                                  </Typography>
                                    </GreyColorButton>
                              </Box>
                         </Grid>

                        <Grid item xs={12}>
                     
                     <TableContainer sx={{ backgroundColor:'#f2f4f4' }}   component={Paper}>
                         <Table sx={{ minWidth: 250 }} aria-label="simple table">                   
                             <TableBody>
                             {Ol2rows.map((row) => (
                                 <TableRow
                                 key={row.code}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 },...row.description==='Grade' &&  {background:'white'}  }}
                                 >
                                  
                             
                                 <TableCell align="left">
                                 
                                  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12},...row.description==='Grade' &&  {fontWeight:'bold'}}}>
                                     {row.code}
                                  </Typography>

                                  </TableCell>
                                 <TableCell align="right">
                                 
                                  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12},...row.description==='Grade' &&  {fontWeight:'bold'}}}>
                                  {row.description}
                                  </Typography>
                                  </TableCell>
                                 
                                 </TableRow>
                             ))}
                             </TableBody>
                         </Table>
                     </TableContainer> 
  
                 </Grid>

                      </Grid>                    
                </Grid>
                {olType==='OL' &&   <Grid item xs={4}  >
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                          <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>2.3 Third Attempt</Typography>
                        </Grid>
                        <Grid item xs={6}>
                        {olType==='OL' &&  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>G.C.E(O/L) Year</Typography>}
                        
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>Index Number</Typography>
                        </Grid>
                        <Grid item xs={6}>
                              <FormControl fullWidth size="small">                  
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={attemp3Year}
                                    label="examYear"
                                    onChange={handleAttemp3YearChange}>
                                          <MenuItem value="">
                                          <em>Select</em>
                                          </MenuItem>
                                          <MenuItem value={2022}>2022</MenuItem>
                                          <MenuItem value={2021}>2021</MenuItem>
                                          <MenuItem value={2020}>2020</MenuItem>
                                          <MenuItem value={2019}>2019</MenuItem>
                                          <MenuItem value={2018}>2018</MenuItem>
                                          <MenuItem value={2017}>2017</MenuItem>
                                          <MenuItem value={2016}>2016</MenuItem>
                                          <MenuItem value={2015}>2015</MenuItem>
                                          <MenuItem value={2014}>2014</MenuItem>
                                          <MenuItem value={2013}>2013</MenuItem>
                                          <MenuItem value={2012}>2012</MenuItem>
                                          <MenuItem value={2011}>2011</MenuItem>
                                          <MenuItem value={2010}>2010</MenuItem>
                                  </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField  value={examindex3}   
                            onChange={(event) => {
                                    setExamindex3(event.target.value);
                                    setSearchDone(false);
                                    setOl3rows([]);
                            }}  
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }}}
                            required inputProps={{maxLength: 10 ,style: {textTransform: "uppercase"}}}   label="" type="text" size='small' color='primary' />
                        </Grid>
                        <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between">
                                  <ColorButton style={{width:'70%'}} onClick={searchThirdShyResult} variant="contained">
                                  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>
                                     Search
                                  </Typography>
                                    </ColorButton>
                                  <GreyColorButton style={{width:'20%'}} onClick={clearThirdShy} variant="contained">
                                  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12,fontWeight: 'bold'} }}>
                                     Clear
                                  </Typography>
                                    </GreyColorButton>
                              </Box>

                          </Grid>
                        <Grid item xs={12}>
                     
                     <TableContainer sx={{ backgroundColor:'#f2f4f4' }}   component={Paper}>
                         <Table sx={{ minWidth: 250 }} aria-label="simple table">                   
                             <TableBody>
                             {Ol3rows.map((row) => (
                                 <TableRow
                                 key={row.code}
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 },...row.description==='Grade' &&  {background:'white'}  }}
                                 >
                             
                                 <TableCell align="left">
                                 <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12},...row.description==='Grade' &&  {fontWeight:'bold'}}}>
                                     {row.code}
                                  </Typography>
                                  
                                  </TableCell>
                                 <TableCell align="right">
                                  
                                  <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12},...row.description==='Grade' &&  {fontWeight:'bold'}}}>
                                     {row.description}
                                  </Typography>
                                  </TableCell>
                                 
                                 </TableRow>
                             ))}
                             </TableBody>
                         </Table>
                     </TableContainer> 
  
                 </Grid>
                      </Grid>                    
                </Grid>

                }
                 
              </Grid>
 
             </Grid>
           </Container>
         </main>




    
         <Grid container   marginBottom={'15px'}
                      spacing={0}
                      direction="column"
                      alignItems="center"
                       justifyContent="center" >
       { showContinueButton &&<BlueColorButton  sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            minWidth: 32,
            ".MuiButton-startIcon": { m: 0 }
          }
         })}  variant="contained" onClick={navigateFromOL} endIcon={<ArrowForwardIosOutlined />}>
         Continue
      </BlueColorButton>}
      </Grid>



         



        
       </ThemeProvider></>
       
    );
 
}

 
 
