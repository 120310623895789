import { AppBar, Autocomplete, Box, Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, Zoom, createTheme, styled } from "@mui/material";
import React from "react";
import './../App.css';
import CustomTextField from "../common/CustomTextField";
 import { makeStyles } from "@material-ui/core/styles";
import Card from '@mui/joy/Card';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import SendIcon from '@mui/icons-material/Send';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
 
import Header from "../common/Header";
import axios, { AxiosError } from "axios";
import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import IconBreadcrumbs from "../common/BreadCrumb";

import { DateField } from '@mui/x-date-pickers/DateField';
 
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Moment from 'react-moment'; 
import moment from 'moment'
import { blue } from "@mui/material/colors";
import { AccountCircle, ArrowBackIos, ArrowForwardIosOutlined } from "@mui/icons-material";
import EmailIcon from '@mui/icons-material/Email';
import api from "../common/ApiInterceptor";
export default function PersonalInformation() {

     const [fullName, setFullName] = React.useState('');
     const [nameWithInitials, setNameWithInitials] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [race, setRace] = React.useState('');
    const [street, setStreet] = React.useState('');
    const [aprtment, setAprtment] = React.useState('');
    const [city, setCity] = React.useState('');
    const [postalCode, setpostalCode] = React.useState('');
    const [district, setDistrict] = React.useState('');
    const [nicNo, setNicNo] = React.useState('');
    const [, setInputValue] = React.useState("");
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [dob, setDob] = React.useState('');
    const [aldob, setalDob] = React.useState('');
    const [guardianNicNo, setGuardianNicNo] = React.useState('');
    const [addressYearsLive, setAddressYearsLive] = React.useState(0);
    const [mobileNo, setMobileNo] = React.useState('');
    const [landNo, setLandNo] = React.useState('');
    const [emailAddress, setEmailAddress] = React.useState('');
    const [showError, setShowError] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const navigate = useNavigate();
    const [execute1, setexecute1] = React.useState(false);
    const [postalDesc, setPostalDesc] = React.useState('');
    const [disableDOB, setDisableDOB] = React.useState(true);

    const isLetters = (str:string) => /^[A-Za-z._ ]*$/.test(str);

   
    const apiUrl = process.env.REACT_APP_API_URL;
    let tokenRequest = {
        "username": process.env.REACT_APP_TOKEN_USERNAME,
      }
 

    useEffect(() => {
        
        const savedObject: string = sessionStorage.getItem("PERSONAL_INFO")!;
        if(savedObject){
             
            const personal: PersonalInformation  =JSON.parse(savedObject);    
            if(personal){
              
                if(personal.nameWithInitials){
                    setNameWithInitials(personal.nameWithInitials);
                }
                console.log('personal.dob',personal.dob);
                if(personal.dob){
                    
                    
                    if(personal.dob.length <10){
                        setDisableDOB(false);
                    }else{
                        setDisableDOB(true);
                    }
                    setDob(personal.dob);
                }
               
                if(personal.gender){
                    setGender(personal.gender);
                }
                
                if(personal.title){
                    setTitle(personal.title);
                }
             
                if(personal.race){
                    setRace(personal.race);
                }
                if(personal.addressStreet){
                    setStreet(personal.addressStreet);
                }
                 
                if(personal.addressBuilding){
                    setAprtment(personal.addressBuilding);
                }
                 if(personal.addressCity){
                    setCity(personal.addressCity);
                 }
                 if(personal.addressPostalCode){
                    setpostalCode(personal.addressPostalCode);
                 }
                if(personal.district){
                    setDistrict(personal.district);
                }
                 if(personal.guardianNic){
                    setGuardianNicNo(personal.guardianNic as string);
                 }
                 if(personal.livingYears){
                    setAddressYearsLive(personal.livingYears);
                 }
                 console.log(personal.mobileNo+"   "+personal.landNumber);
                 if(personal.mobileNo){
                    setMobileNo(personal.mobileNo);
                 }
                if(personal.landNumber){
                    setLandNo(personal.landNumber);
                }
                if(personal.email){
                    setEmailAddress(personal.email);
                }
             
            }
        }
       
    },[]);

    




     const handleGenderChange = (event: SelectChangeEvent) => {

       
        setGender(event.target.value as string);
      };

      const handleTitleChange = (event: SelectChangeEvent) => {
        setTitle(event.target.value as string);
        const age= getAge(dob);        
        if(event.target.value==='RV'){
            if(age > 32){
                setShowError(true);
                var str1 = new String( " Your Age ( " ); 
                var str2 = new String(age); 
                var str3 = new String( " ) Years is not Eligible to Apply for National Colleges of Education " ); 
                var str4 = str1.concat(str2.toString()).concat(str3.toString());
                setErrorMsg(str4);
                return
            }    
        }else{
           const alYear=sessionStorage.getItem("AL_YEAR") as String;
           if(alYear==='2020' || alYear==='2019'){
              setShowError(true);
              setErrorMsg("Only Clergy or those who bear priesthood are allowed to apply using G.C.E(AL/L) 2019 or 2020 results. Others should apply using 2021 or 2022 AL Results ");
              return
           }

            if(age > 27){
                setShowError(true);
                var str1 = new String( " Your Age ( " ); 
                var str2 = new String(age); 
                var str3 = new String( " ) Years is not Eligible to Apply for National Colleges of Education " ); 
                var str4 = str1.concat(str2.toString()).concat(str3.toString());
                setErrorMsg(str4);
                return
            }    
        }
      
      };

      const handleRaceChange = (event: SelectChangeEvent) => {
        setRace(event.target.value as string);
      };

      const handleDistrictChange = (event: SelectChangeEvent) => {
         setDistrict(event.target.value as string);
      };

      const handlePostalChange = (event: SelectChangeEvent) => {
        setpostalCode(event.target.value as string);
        options.forEach( (element:any) => {
           if(element.code===event.target.value){
            setPostalDesc(element.description.concat(" (").concat(element.code).concat(")"));
           }
        });

      

      };
           
 

    type district={
        code:string,
        name:string
    }

    interface PersonalInformation{ 
        fullname:string;
        nameWithInitials:string,
        nic:string,
        dob:string,
        gender:string,
        title:string,
        race:string,
        addressStreet:string,
        addressBuilding:string,
        addressCity:string,
        addressPostalCode:string,
        district:string,
        guardianNic:String,
        livingYears:number,
        mobileNo:string,
        landNumber:string,
        email:string,
        postalDescription:string;
      }

      const theme = createTheme({
        spacing: 4,
      });
      const BlueColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText(blue[600]),
        backgroundColor: '#2364be',
        '&:hover': {
          backgroundColor: '#2364be',
        },
        
         
      }));
   

      function navigationToOL(){
        navigate("/OL");
      }
 

    const districtList=[
        {
            code:"Ampara",
            name:"Ampara"
        },
        {
            code:"Anuradhapura",
            name:"Anuradhapura"
        },
        {
            code:"Badulla",
            name:"Badulla"
        },
        {
            code:"Batticaloa",
            name:"Batticaloa"
        },
        {
            code:"Colombo",
            name:"Colombo"
        },
        {
            code:"Galle",
            name:"Galle"
        },
        {
            code:"Gampaha",
            name:"Gampaha"
        },
        {
            code:"Hambantota",
            name:"Hambantota"
        },
        {
            code:"Jaffna",
            name:"Jaffna"
        },
        {
            code:"Kalutara",
            name:"Kalutara"
        },
        {
            code:"Kandy",
            name:"Kandy"
        },
        {
            code:"Kegalla",
            name:"Kegalla"
        },
        {
            code:"Kilinochchi",
            name:"Kilinochchi"
        },
        {
            code:"Kurunegala",
            name:"Kurunegala"
        },
        {
            code:"Mannar",
            name:"Mannar"
        },
        {
            code:"Matale",
            name:"Matale"
        },
        {
            code:"Matara",
            name:"Matara"
        },
        {
            code:"Moneragala",
            name:"Moneragala"
        },
        {
            code:"Mullaitivu",
            name:"Mullaitivu"
        },
        {
            code:"Nuwara Eliya",
            name:"Nuwara Eliya"
        },
        {
            code:"Polonnaruwa",
            name:"Polonnaruwa"
        },
        {
            code:"Puttalam",
            name:"Puttalam"
        },
        {
            code:"Ratnapura",
            name:"Ratnapura"
        },
        {
            code:"Trincomalee",
            name:"Trincomalee"
        },
        {
            code:"Vavuniya",
            name:"Vavuniya"
        },

    ]

 

   

    useEffect(() => {
        if(!loading){
            fetchPostalData();
        }
        
      }, []);
    
 
      useEffect(() => {
        if(!loading){
            fetchApplicantData();
        }
        
      }, []);

      useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

      const fetchPostalData = async () => {
        setLoading(true);
        try {
            const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
            sessionStorage.removeItem("token");
            sessionStorage.setItem("token",jwtToken.data);
          const response = await api.get(apiUrl + "/getPostalCodes");
         response.data.sort((a: { description: string; },b: { description: string; }) => {
           return a.description.localeCompare(b.description);
          });

        


          setOptions(response.data);
          setLoading(false);

        } catch (error) {
          // Handle any errors that occurred during the request
          console.error(error);
          setLoading(false);
        }
      };


      const fetchApplicantData = async () => {
        setLoading(true);
        try {
          // Make the GET request

          const alrequest = {
            "examYear": sessionStorage.getItem('AL_YEAR'),
            "examIndexNo":sessionStorage.getItem('AL_INDEX'),
            "nic":sessionStorage.getItem('AL_NIC')
          }
          
          const jwtToken =await  api.post(process.env.REACT_APP_API_URL + '/getToken', tokenRequest);
          sessionStorage.removeItem("token");
          sessionStorage.setItem("token",jwtToken.data); 
          const response = await api.post(apiUrl + "/getApplicantInfo", alrequest );
          setFullName(response.data.fullName);
          setNicNo(sessionStorage.getItem('AL_NIC') as string);
          setDob(response.data.dob);
          const savedObject: string = sessionStorage.getItem("PERSONAL_INFO")!;
          if(savedObject){
            const personal: PersonalInformation  =JSON.parse(savedObject);    

            if(personal){ 
                if(personal.dob){
                    setDob(personal.dob);
                }
            }
         }


         setalDob(response.data.dob);
        
         if(response.data.dob.length < 10){
            setDisableDOB(false);
        }else{
            setDisableDOB(true);
        }


          setLoading(false);

        } catch (error) {
          // Handle any errors that occurred during the request
          console.error(error);
          setLoading(false);
        }
      };



      
      

      function navigateFromPersonalInfo(){
        let nic=sessionStorage.getItem('AL_NIC') || '';

        if(!emailAddress) {
            setShowError(true);
            setErrorMsg('Please Enter a Valid Email Address');
            return;
        } else{
            if(isEmpty(emailAddress)){
                if(!isValidEmail(emailAddress)){            
                    setShowError(true);
                    setErrorMsg('Invalid Email Address');
                    return;            
               }
            }else{
                if(!isValidEmail(emailAddress)){            
                    setShowError(true);
                    setErrorMsg('Invalid Email Address');
                    return;            
               }
            }
          
        }

      
        if(!mobileNo) {
            setShowError(true);
            setErrorMsg('Please Enter Mobile No');
            return;
        }else{
            if(isEmpty(mobileNo)){
                setShowError(true);
                    setErrorMsg('Invalid Mobile No');
                    return;
            }else{
                if(mobileNo.trim().length != 10){
                    setShowError(true);
                    setErrorMsg('Invalid Mobile No');
                    return;
                }
            }   
            
           
        } 


        if(landNo) {
            if(landNo.trim().length != 10){
                setShowError(true);
                setErrorMsg('Invalid Land Contact No');
                return;
            }else{
                if(isEmpty(landNo)){
                    setShowError(true);
                    setErrorMsg('Invalid Land Contact No');
                    return;
                }   
            }
        } 



        if(!district) {
            setShowError(true);
            setErrorMsg('Please Enter Permanant Residance District');
            return;
        }else{
            if(isEmpty(district)){
                setShowError(true);
                setErrorMsg('Please Enter Permanant Residance District');
                return;
            }   
        } 
        if(!postalCode) {
            setShowError(true);
            setErrorMsg('Please Enter Postal Code');
            return;
        }else{
            if(isEmpty(postalCode)){
                setShowError(true);
                setErrorMsg('Please Enter Postal Code');
                return;
            }   
        }

        if(!city) {
            setShowError(true);
            setErrorMsg('Please Enter City');
            return;
        }else{
            if(isEmpty(city)){
                setShowError(true);
                setErrorMsg('Please Enter City');
                return;
            }    
        } 

        if(!aprtment) {
            setShowError(true);
            setErrorMsg('Please Enter Street');
            return;
        }else{
            if(isEmpty(aprtment)){
                setShowError(true);
                setErrorMsg('Please Enter Street');
                return;
            }    
        } 
        if(!street) {
            setShowError(true);
            setErrorMsg('Please Enter Postal Code');
            return;
        }else{
            if(isEmpty(street)){
                setShowError(true);
                setErrorMsg('Please Enter Postal Code');
                return;
            }    
        } 

        if(!race) {
            setShowError(true);
            setErrorMsg('Please Select Race');
            return;
        }else{
            if(isEmpty(race)){
                setShowError(true);
                setErrorMsg('Please Select Race');
                return;
            }   
        }  
        if(!title) {
            setShowError(true);
            setErrorMsg('Please Select Title');
            return;
        }else{
            if(isEmpty(title)){
                setShowError(true);
                setErrorMsg('Please Select Title');
                return;
            }   
        } 

        if(!gender) {
            setShowError(true);
            setErrorMsg('Please Select Gender');
            return;
        }else{
            if(isEmpty(gender)){
                setShowError(true);
                setErrorMsg('Please Select Gender');
                return;
            }   
        }  
        if(!dob) {
            setShowError(true);
            setErrorMsg('Please Enter DOB');
            return;
        }else{
            if(isEmpty(dob)){
                setShowError(true);
                setErrorMsg('Please Enter DOB');
                return;
            }
        } 



        if(guardianNicNo){
            if(guardianNicNo.trim().length ===10 || guardianNicNo.trim().length ===12){
                
            }else{
                setShowError(true);
                setErrorMsg('Invalid Father / Mother / Guardian NIC Number ');
                return;
            }
        }/* else{
            setShowError(true);
            setErrorMsg('Please Enter Father / Mother / Guardian NIC Number ');
            return;
        } */

        if(!nameWithInitials) {
            setShowError(true);
            setErrorMsg('Please Enter Name with Initials');
            return;
        }else{
            if(isEmpty(nameWithInitials)){
                setShowError(true);
                setErrorMsg('Please Enter Name with Initials');
                return;
            }   
        }


        if(!fullName) {
            setShowError(true);
            setErrorMsg('Please Enter Full Name');
            return;
        }else{
            if(isEmpty(fullName)){
                setShowError(true);
                setErrorMsg('Please Enter Full Name');
                return;
            }   
        }

           
        const age= getAge(dob);        
        if(title==='RV'){
            if(age > 32){
                setShowError(true);
                var str1 = new String( " Your Age ( " ); 
                var str2 = new String(age); 
                var str3 = new String( " ) Years is not Eligible to Apply for National Colleges of Education " ); 
                var str4 = str1.concat(str2.toString()).concat(str3.toString());
                setErrorMsg(str4);
                return
            }    
        }else{
            const alYear=sessionStorage.getItem("AL_YEAR") as String;
            if(alYear==='2020' || alYear==='2019'){
               setShowError(true);
               setErrorMsg("Only Clergy or those who bear priesthood are allowed to apply using G.C.E(AL/L) 2019 or 2020 results. Others should apply using 2021 or 2022 AL Results ");
               return
            }
            if(age > 27){
                setShowError(true);
                var str1 = new String( " Your Age ( " ); 
                var str2 = new String(age); 
                var str3 = new String( " ) Years is not Eligible to Apply for National Colleges of Education " ); 
                var str4 = str1.concat(str2.toString()).concat(str3.toString());
                setErrorMsg(str4);
                return
            }    
        }


      
        function isEmpty(str:any) {
            return !str.trim().length;
        }
         

         
       
       
       
        
        
       
       
       

       
        const personal: PersonalInformation = {
            fullname:fullName,
            nameWithInitials:nameWithInitials?.toUpperCase(),
            dob:dob,
            gender:gender,
            title:title,
            race:race,
            addressStreet:street?.toUpperCase(),
            addressBuilding:aprtment?.toUpperCase(),
            addressCity:city?.toUpperCase(),
            addressPostalCode:postalCode,
            district:district,
            guardianNic:guardianNicNo?.toUpperCase(),
            livingYears:addressYearsLive,
            mobileNo:mobileNo,
            landNumber:landNo,
            email:emailAddress,
            nic:nic,
            postalDescription:postalDesc

        };

        sessionStorage.setItem("PERSONAL_INFO",JSON.stringify(personal));
      
       
     
        navigate("/courses");
     
     } 

     const handleErrorDialogClose = () => {
        setShowError(false);        
     };

     function isValidDate(dateString:string) {
        return !isNaN(Date.parse(dateString));
      }

     function IsValidDate(pText:string) {
        try{
            let dateArray = pText.split("/");
            let newDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
            if(!isValidDate(newDate)){
                setShowError(true);
                setErrorMsg('Invalid Date Of Birth . Format should be dd/mm/yyyy');
                setDob(aldob);
                return;
            }


        } catch (error) {
            setShowError(true);
            setErrorMsg('Invalid Date Of Birth . Format should be dd/mm/yyyy');
            setDob(aldob);
            return;
        } 
    }


    function ageFromDateOfBirthday(dateOfBirth: any): number {
        return moment().diff(new Date(dateOfBirth), 'years');
    }

    function   getAge(dateOfBirth: any){
        let dateArray = dateOfBirth.split("/");
        let newDate = `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
        console.log(ageFromDateOfBirthday(newDate));
        return ageFromDateOfBirthday(newDate);
    }


    function isValidEmail(search:string) 
    {
        var  serchfind:boolean;
        var EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

        const regexp = new RegExp(EMAIL_REGEXP);
        serchfind = regexp.test(search);
        console.log(serchfind)
        
         return serchfind
    }

    const classes = {
        root: {
          flexGrow: 1
        },
        paper: {
          padding: 20,
          textAlign: "center",
          color: "blue",
          fontFamily: "Roboto"
        }
      };



      const checkFullNameSpecialChar =(e:any)=>{
        if(!/[a-zA-Z-\s]/.test(e.key)){
         e.preventDefault();
        }
       };

       const checkNameWithInitialsSpecialChar =(e:any)=>{
        if(!/[a-zA-Z-\s.]/.test(e.key)){
         e.preventDefault();
        }
       };


  return (

    <>


    <Dialog open={showError}   keepMounted
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
              
              },
            },
          }}
          
          >
          <DialogTitle style={{ backgroundColor: 'red', color: 'white' }}> Error</DialogTitle>
           <DialogContent sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} dividers>
               {errorMsg}  
           </DialogContent> 
        
          <DialogActions style={{justifyContent: 'center'}}>
              <Button sx={{fontSize: {lg: 16, md: 13,sm: 12,xs: 12} }} variant="contained" autoFocus onClick={handleErrorDialogClose}>
                    Ok
              </Button>        
      </DialogActions>
      </Dialog>

     <Header/>     
     <IconBreadcrumbs currentPage={"PERSONNAL"}></IconBreadcrumbs>
     
     <div style={classes.root}>
      <Grid container spacing={2}>
        
            <Grid marginTop={'20px'} item xs={12}>

            <AppBar elevation={0} style={{ background: '#D2DCF0' }}  sx={{marginTop:'1px',marginBottom:'40px'}} position="static">
              <Box display='flex' flexGrow={1}>
                  <Typography  margin={'8px'} sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'500',color:'black' }}>3. Personal Information</Typography>
              </Box>
           </AppBar>  

            
            </Grid>


            <Grid marginTop={'10px'} item xs={12}>
                <Grid item xs={12} sm={12}>
                    <Typography  sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                    3.1 Name in Full
                    </Typography>
                </Grid>   
                <Grid item xs={12} sm={8}>
                        <form   noValidate autoComplete="off">
                            <TextField    fullWidth   value={fullName}  inputProps={{ maxLength: 200 ,style:{textTransform: "uppercase",fontSize:'14px'}}} required   id="standard-basic"   variant="standard" 
                                    onChange={(e) => { 
                                        setFullName(e.target.value); 
                                    }}
                                    onKeyDown={(e)=>{
                                        checkFullNameSpecialChar(e)
                                    }}

                            />
                        </form>  
                </Grid>   
            </Grid>
            <Grid  item xs={12}>
                <Grid item xs={12} sm={12}>
                    <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                    3.2 Name with Initials
                    </Typography>
                </Grid>   
                <Grid item xs={12} sm={8}>
                <form   noValidate autoComplete="off">
                    <TextField   fullWidth   value={nameWithInitials}  inputProps={{ maxLength: 200 ,style:{textTransform: "uppercase",fontSize:'14px'}}} required   id="standard-basic"   variant="standard" 
                            onChange={(e) => { 
                                setNameWithInitials(e.target.value); 
                            }} 
                            onKeyDown={(e)=>{
                                checkNameWithInitialsSpecialChar(e)
                            }}
                    />
                </form>   
                </Grid>   
            </Grid>
      
            <Grid item xs={6} sm={3}>
                    <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                            3.3 NIC Number
                        </Typography>
                        <form   noValidate autoComplete="off">
                            <TextField   fullWidth   value={nicNo}  inputProps={{ maxLength: 200 ,style:{textTransform: "uppercase",fontSize:'14px'}}} required   id="standard-basic"   variant="standard" 
                            />
                        </form>  
            </Grid>
            <Grid item xs={6} sm={3}>            
            </Grid>
            <Grid item xs={6} sm={3}>
                <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                        3.4 Date of Birth (mm/dd/yyyy)
                </Typography>
                <form   noValidate autoComplete="off">
                     <TextField disabled={disableDOB}   fullWidth   value={dob}  inputProps={{ maxLength: 200 ,style:{marginTop:'10px',textTransform: "uppercase",fontSize:'14px'}}} required   id="standard-basic"   variant="standard" 
                      onChange={(e) => { 

                        if(e.target.value){
                            let monthval=e.target.value.substring(0,2);
                            if(Number(monthval) > 12){
                                setShowError(true);
                                setErrorMsg("Invalid Month. Should be 01-12. Date Format is month/date/Year");                  
                            }
                        }

                        setDob(e.target.value); 
                    }}
                     />
                </form>  
            </Grid>

            <Grid item xs={6} sm={3}>            
            </Grid>

            <Grid item xs={6} sm={3}>
                       <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                           3.5 Gender
                        </Typography>
                        <FormControl   variant="standard" fullWidth>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={gender}
                                    onChange={handleGenderChange}
                                    label="Gender">                          
                                    <MenuItem value={'M'}>Male</MenuItem>
                                    <MenuItem value={'F'}>Female</MenuItem>
                                    </Select>
                        </FormControl>
            </Grid>

            
            <Grid item xs={6} sm={3}>            
            </Grid>
            <Grid item xs={6} sm={3}>
                <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                   3.6 Title
                </Typography>
                  <FormControl fullWidth variant="standard" sx={{   minWidth: 120 }}>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={title}
                            onChange={handleTitleChange}
                            label="Title">                          
                            <MenuItem value={'RV'}>Rev.</MenuItem>
                            <MenuItem value={'MR'}>Mr.</MenuItem>
                            <MenuItem value={'MS'}>Miss.</MenuItem>
                        </Select>
                  </FormControl>  
            </Grid>

            <Grid  item xs={12}>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                       3.7 Race
                    </Typography>
                </Grid>   
                <Grid item xs={12} sm={6}>
                <FormControl  fullWidth variant="standard" sx={{  minWidth: 120 }}>
                             
                            <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={race}
                            onChange={handleRaceChange}
                            label="Race">                          
                            <MenuItem value={'SI'}>Sinhala</MenuItem>
                            <MenuItem value={'ST'}>Sri Lankan Tamil</MenuItem>
                            <MenuItem value={'SM'}>Tamil of Indian Origin</MenuItem>
                            <MenuItem value={'MU'}>Muslim</MenuItem>
                            <MenuItem value={'CA'}>Catholic</MenuItem>
                            <MenuItem value={'CH'}>Christian</MenuItem>
                            <MenuItem value={'OT'}>Other</MenuItem>
                            </Select>
                        </FormControl>
                </Grid>   
            </Grid>


            <Grid marginTop={'10px'} item xs={12}>
               <AppBar elevation={0}  style={{ background: '#D2DCF0' }}  sx={{marginTop:'1px',marginBottom:'40px'}} position="static">
                    <Box display='flex' flexGrow={1}>
                        <Typography  margin={'8px'} sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'500',color:'black' }}>4. Permanent Address</Typography>
                    </Box>
                </AppBar>  

               {/*  <Box  sx={{ backgroundColor:' #d2e3fb',minWidth:'100%',paddingLeft:'10px' }}>
                    <Typography sx={{fontWeight:'bold',  fontSize: {lg: 18, md: 16,sm: 14,xs: 14} }} gutterBottom>
                        Permanent Address
                    </Typography>
                </Box> */}
            </Grid>


            <Grid item xs={6} sm={3}>
                    <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                           4.1 Post Box
                        </Typography>
                        <form   noValidate autoComplete="off">
                            <TextField   fullWidth   value={street}  inputProps={{ maxLength: 200 ,style:{textTransform: "uppercase",fontSize:'14px'}}} required   id="standard-basic"   variant="standard" 
                              onChange={(e) => { 
                                setStreet(e.target.value); }}
                         />
                        </form>  
            </Grid>
            <Grid item xs={6} sm={3}>            
            </Grid>
            <Grid item xs={6} sm={3}>
                <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                    4.2 Street
                </Typography>
                <form   noValidate autoComplete="off">
                     <TextField   fullWidth   value={aprtment}  inputProps={{ maxLength: 200 ,style:{textTransform: "uppercase",fontSize:'14px'}}} required   id="standard-basic"   variant="standard" 
                         onChange={(e) => { 
                            setAprtment(e.target.value); 
                          }} 
                     />
                </form>  
            </Grid>
             



            <Grid item xs={6} sm={3}>            
            </Grid>
            <Grid item xs={6} sm={3}>
                      <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                           4.3 City
                        </Typography>
                        <form   noValidate autoComplete="off">
                            <TextField   fullWidth   value={city}  inputProps={{ maxLength: 200 ,style:{textTransform: "uppercase",fontSize:'14px'}}} required   id="standard-basic"   variant="standard" 
                              onChange={(e) => { 
                                setCity(e.target.value); }}
                         />
                        </form>  
            </Grid>
            <Grid item xs={6} sm={3}>            
            </Grid>
            <Grid item xs={6} sm={3}>
                <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                   4.4 Postal Code
                </Typography>
                <FormControl fullWidth variant="standard" sx={{  minWidth: 120 }}>
                               
                                <Select  onChange={handlePostalChange} labelId="postalSelectLabel" id="postalSelect" value={postalCode}>
                                    {options.map(({code, description}, index) => (
                                        <MenuItem key={index} value={code}>
                                        {description} ({code}) 
                                        </MenuItem>
                                    ))}
                                </Select>
                  </FormControl>
            </Grid>



            <Grid marginTop={'10px'} item xs={12}>
               <AppBar elevation={0}  style={{ background: '#D2DCF0' }}  sx={{marginTop:'1px',marginBottom:'40px'}} position="static">
                    <Box display='flex' flexGrow={1}>
                        <Typography  margin={'8px'} sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'500',color:'black' }}>5. Permanent Residence District Details</Typography>
                    </Box>
                </AppBar>  

               {/*  <Box  sx={{ backgroundColor:' #d2e3fb',minWidth:'100%',paddingLeft:'10px' }}>
                    <Typography sx={{fontWeight:'bold',  fontSize: {lg: 18, md: 16,sm: 14,xs: 14} }} >
                      Permanent Residence District Details
                    </Typography>
                </Box> */}
            </Grid>

            <Grid  item xs={12}>                
                    <Typography sx={{fontWeight:'bold',color:'red',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} gutterBottom>
                       *(Read the condition in '6.2 Determination of permanant residence' of the gazette notification carefully and fill this accordingly) 
                    </Typography>              
            </Grid>



            <Grid marginTop={'10px'} item xs={12}>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{fontWeight:'bold',  fontSize: {lg: 15, md: 14,sm: 12,xs: 12} }} >
                      5.1 District
                    </Typography>
                </Grid>   
                <Grid item xs={12} sm={6}>
                <FormControl sx={{   minWidth: '100%' }} variant="standard"  >
                          
                            <Select  onChange={handleDistrictChange} labelId="countrySelectLabel" id="countrySelect" value={district}>
                                {districtList.map(({code, name}, index) => (
                                    <MenuItem key={index} value={code}>
                                       {name}
                                    </MenuItem>
                                ))}
                             </Select>
                        </FormControl>
                </Grid>   
            </Grid>

            <Grid  item xs={12}>                
                    <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} gutterBottom>
                      If your Name is not listed in the electoral registers, please enter the following details;
                    </Typography>              
            </Grid>




            <Grid item xs={6} sm={3}>
                       <Typography sx={{ fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                           5.2 Father / Mother / Guardian NIC Number
                        </Typography>
                        <form   noValidate autoComplete="off">
                            <TextField   fullWidth   value={guardianNicNo}  inputProps={{ maxLength: 12 ,style:{textTransform: "uppercase",fontSize:'14px'}}}   id="standard-basic"   variant="standard" 
                            onChange={(e) => { 
                                setGuardianNicNo(e.target.value); 
                            }} 
                       />
                        </form>  
            </Grid>
            <Grid item xs={6} sm={3}>            
            </Grid>
            <Grid item xs={6} sm={5}>
                       <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                           5.3 Number of years you have been living in the above district
                        </Typography>
                    <form   noValidate autoComplete="off">
                       <TextField    value={addressYearsLive} inputProps={{ type: 'number',min: 0,max:35 }}   id="standard-basic"  variant="standard" 
                          onChange={(e) => { 
                             if(e.target.value ){
                                if(Number(e.target.value) > 35){
                                    setShowError(true);
                                    setErrorMsg('Maximum allowed years is 35');
                                }

                            }
                            setAddressYearsLive(Number(e.target.value)); 
                           }}  
                           onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}                    
                        />
                    </form>  
            </Grid>

            <Grid item xs={6} sm={1}>            
            </Grid>


            <Grid marginTop={'10px'} item xs={12}>
            <AppBar elevation={0}  style={{ background: '#D2DCF0' }}  sx={{marginTop:'1px',marginBottom:'40px'}} position="static">
                    <Box display='flex' flexGrow={1}>
                        <Typography  margin={'8px'} sx={{fontSize: {lg: 18, md: 16,sm: 14,xs: 14},fontWeight:'500',color:'black' }}>6. Contact Details</Typography>
                    </Box>
                </AppBar>  

               {/*  <Box  sx={{ backgroundColor:' #d2e3fb',minWidth:'100%',paddingLeft:'10px' }}>
                    <Typography sx={{fontWeight:'bold',  fontSize: {lg: 18, md: 16,sm: 14,xs: 14} }} >
                      Contact Details
                    </Typography>
                </Box> */}
            </Grid>



            <Grid item xs={6} sm={3}>
                       <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                             6.1 Mobile Number
                        </Typography>
                        <form   noValidate autoComplete="off">
                        <Tooltip TransitionComponent={Zoom} title={'ex. 071XXXXXXX'}>
                            <TextField inputProps={{ maxLength: '10'}} value={mobileNo}    id="standard-basic"   variant="standard" 
                                 InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <WifiCalling3Icon />
                                      </InputAdornment>
                                    ),
                                  }}
                                 onChange={(e) => { 
                                    setMobileNo(e.target.value); 
                                   }}  
                                   onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}     
                                 />
                           </Tooltip>      
                        </form>  
            </Grid>
            <Grid item xs={6} sm={3}>            
            </Grid>
            <Grid item xs={6} sm={3}>
                       <Typography sx={{fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                           6.2 Land Phone Number
                        </Typography>
                    <form   noValidate autoComplete="off">
                    <Tooltip TransitionComponent={Zoom} title={'ex. 0112XXXXXX'}>
                         <TextField  inputProps={{ maxLength: '10'}}  value={landNo}  id="standard-basic" variant="standard"                                
                                    onChange={(e) => { 
                                        setLandNo(e.target.value); 
                                    }} 

                                    InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <WifiCalling3Icon />
                                          </InputAdornment>
                                        ),
                                      }}

                                    onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }}     
                           />
                           </Tooltip>
                    </form>  
            </Grid>

            <Grid item xs={6} sm={3}>
                           
            </Grid>
            
            
            <Grid  item xs={12}>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{fontWeight:'bold',  fontSize: {lg: 16, md: 14,sm: 12,xs: 12} }} >
                       6.3 Email Address
                    </Typography>
                </Grid>   
                <Grid item xs={12} sm={6}>
                <form   noValidate autoComplete="off">
                     <TextField  fullWidth value={emailAddress}    id="standard-basic"   variant="standard" 
                             onChange={(e) => { 
                                setEmailAddress(e.target.value); 
                            }} 
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <EmailIcon />
                                  </InputAdornment>
                                ),
                              }}
                         />
                </form>  
                </Grid>   
            </Grid>


             

            <Grid container   
                    marginBottom={'15px'}
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    marginTop={'20px'}
                    justifyContent="center" >
                    <BlueColorButton     sx={(theme) => ({
                         minWidth: 200, 
                        [theme.breakpoints.down("sm")]: {
                            minWidth: 100,                           
                            ".MuiButton-startIcon": { m: 0 }
                        }
                        

                        })}  variant="contained" onClick={navigateFromPersonalInfo} endIcon={<ArrowForwardIosOutlined />}>
                        Continue
                    </BlueColorButton> 
            </Grid>
        </Grid>
    </div>

   </>



    
         

    
  )
}


